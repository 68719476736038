// eslint-disable-prototype-builtins

import styles from './information-card.module.scss';
import { useContext, useState } from 'react';
import {
  KpiApiContext,
  ModalContext,
  ModalTypes,
  IRightDrawerTypes,
  RightDrawerContext,
  MetaDataApiContext,
  ModulesContext,
} from '@flexo/providers';

import { useTranslation } from 'react-i18next';
import { IconWrapper } from '@flexo/general';
import { v4 as uuidv4 } from 'uuid';
import { navigateToCommunity } from '../resolvers/community-resolver';

const ENV = (import.meta as any).env;

export function InformationCard(props: any) {
  const {
    InformationCard__Icon,
    InformationCard__Value,
    InformationCard__Label,
    InformationCard__Label__Disabled,
    InformationCard__Detail,
  } = styles;

  const { kpiApiStore, setKpiApiStore } = useContext(KpiApiContext);
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { setRightDrawerStore } = useContext(RightDrawerContext);

  const { t } = useTranslation();

  const { setModalStore } = useContext(ModalContext);

  const onClickLabel = () => {
    switch (props?.env || '') {
      case 'web':
        if (props?.descriptionCallback === 'communityEnergy') {
          navigateToCommunity(
            metaDataApiStore?.selectedEntityId,
            setMetaDataApiStore,
            setModulesStore,
            'energy'
          );
        } else if (props?.descriptionCallback === 'communityEconomics') {
          navigateToCommunity(
            metaDataApiStore?.selectedEntityId,
            setMetaDataApiStore,
            setModulesStore,
            'economics'
          );
        } else if (props?.descriptionCallback === 'communityTotalEstimatedBenefits') {
          setRightDrawerStore({
              type: IRightDrawerTypes.SetDrawer,
              payload: {
                value: {
                  ...props,
                  type: 'COMMUNITY_TOTAL_ESTIMATED_BENEFITS',
                } as any,
              },
            });
          
        } else if (props?.descriptionCallback === 'SiteTotalEstimatedBenefits') {
          setRightDrawerStore({
              type: IRightDrawerTypes.SetDrawer,
              payload: {
                value: {
                  ...props,
                  type: 'SITE_TOTAL_ESTIMATED_BENEFITS',
                } as any,
              },
            });
          
        } else {
          setRightDrawerStore({
            type: IRightDrawerTypes.SetDrawer,
            payload: {
              value: {
                ...props,
              } as any,
            },
          });
        }

        break;
      default:
        if (!props.details.title) return;
        setModalStore({
          type: ModalTypes.OpenModal,
          payload: {
            value: {
              type: 'CARD',
              visible: true,
              show: true,
              props: { ...props },
              content: 'CARD',
            } as any,
          },
        });
        break;
    }
  };

  const { site, community } = kpiApiStore;

  let overviewKpiApiData = kpiApiStore?.site?.['1day']?.[0]?.kpi as any;

  if (metaDataApiStore?.entityType?.includes('community')) {
    overviewKpiApiData = community?.['1day']?.[0]?.kpi as any;
  }

  const getCardValue = () => {
    let cardValue = overviewKpiApiData?.find(
      (el) => el.name === props['totalValue']
    );

    if (props['totalValue'] === 'sites_count') {
      cardValue = {
        value: metaDataApiStore?.sortedEntities?.communities[0]?.members.reduce(
          (prev, next) => prev + (next?.sites?.length || 0),
          0
        ),
        unit: '',
      };
    }


    return (
      <>
        <span
          key={uuidv4()}
          className="featuredData"
          style={{ fontWeight: 300 }}
        >
          {cardValue?.value === null ? '-.-' : cardValue?.value}
          {/* {/[.,]/.test(overviewKpiApiData[props['totalValue']])
            ? ''
            : ',00'}{' '} */}
        </span>
        <span key={uuidv4()} className="heading3L" style={{ fontWeight: 200 }}>
          {' ' + cardValue?.unit}
        </span>
      </>
    );
  };

  const getCardApiValue = (key) => {
    const cardValue = overviewKpiApiData?.find((el) => el.name === key);
    console.log('cardValue', cardValue);
    return cardValue;
  };

  function getSiteRanking() {
    const site = (metaDataApiStore?.entities as any)?.sites?.find((_site: any) => _site.siteId === kpiApiStore?.communityRanking?.economicsRanking?.[0]?.site?.[0]?.siteId) || null;
      return site?.siteName || ''
  }


  
  const getCardDetail = () => {
    const cardValue = overviewKpiApiData?.find(
      (el) => el.name === props['descriptionValue']
    );

    switch (props.descriptionType) {
      case 'totalSitesCard':
        if (
          !(metaDataApiStore as any)?.sortedEntities ||
          (metaDataApiStore as any)?.entities?.length === 0
        ) {
          return <></>;
        }

        // eslint-disable-next-line
        let community = (
          metaDataApiStore as any
        )?.sortedEntities?.communities.find(
          (community) =>
            community.id === metaDataApiStore?.selectedEntityId ||
            community.communityId === metaDataApiStore?.selectedEntityId
        );

        if (!community) {
          community = (metaDataApiStore as any)?.sortedEntities?.communities[0];
        }

        // eslint-disable-next-line
        const siteTypeCounts = {
          consumer: 0,
          producer: 0,
          prosumer: 0,
          prosumage: 0,
        };

        community.members.forEach((member) => {
          member.sites.forEach((site) => {
            if (Array.isArray(site.siteType)) {
              site.siteType.forEach((type) => {
                if (
                  type.siteType &&
                  // eslint-disable-next-line
                  (siteTypeCounts as any).hasOwnProperty(type.siteType)
                ) {
                  siteTypeCounts[type.siteType]++;
                }
              });
            } else if (typeof site.siteType === 'string') {
              // eslint-disable-next-line
              if (siteTypeCounts.hasOwnProperty(site.siteType)) {
                siteTypeCounts[site.siteType]++;
              }
            }
          });
        });

        for (const key in siteTypeCounts) {
          if (siteTypeCounts[key] === 0) {
            siteTypeCounts[key] = null;
          }
        }

        // eslint-disable-next-line
        const keys = props.description.map((el) =>
          el.key.split('.').slice(-1).pop()
        );

        return (
          <>
            {keys
              .filter((_el: any) => siteTypeCounts[_el] !== null)
              .map((siteType: string, _i: number, array: any) => {
                const label = props.description.find((desc: any) =>
                  desc.key.includes(siteType)
                );

                return (
                  <span key={uuidv4()} className="detail">
                    {t(label.key)} {siteTypeCounts[siteType]}{' '}
                    {_i < array.length - 1 ? '|' : ''}
                  </span>
                );
              })}
          </>
        );
      case 'label+value|label+value':
        return (
          <div>
            {props.description.map((el, _i, array) => (
              <span key={uuidv4()} className="paragraph">
                {t(el.key)}{' '}
                {getCardApiValue(el?.value)?.value +
                  getCardApiValue(el?.value)?.unit}{' '}
                {_i < array.length - 1 ? '|' : ''}
              </span>
            ))}
          </div>
        );
      case 'label':
        return (
          <span key={uuidv4()} className="paragraph">
            {t(props.description)}
          </span>
        );
      case 'keyValue':
        // eslint-disable-next-line no-case-declarations

        // eslint-disable-next-line no-case-declarations
        const result = props.description?.map((el) => {
          return (
            <div>
              <div>
                <strong>{t(el.key)} </strong>
                {getCardApiValue(el?.value)?.value +
                  getCardApiValue(el?.value)?.unit}
              </div>
            </div>
          );
        });

        return result;
      case 'value+label':
        return (
          <div>
            <span key={uuidv4()} className="paragraph">
              {' '}
              <strong>{cardValue?.value + cardValue?.unit}</strong>
            </span>
            <span key={uuidv4()} className="paragraph">
              {t(props.description)}
            </span>
          </div>
        );
      case 'label+value':
        if (typeof props.description === 'string') {
          return (
            <div>
              <span key={uuidv4()} className="paragraph">
                {t(props.description)}
              </span>
              <span key={uuidv4()} className="paragraph">
                {' '}
                <strong>{cardValue?.value}</strong>
              </span>
            </div>
          );
        } else if (Array.isArray(props.description)) {
          return (
            <div key={uuidv4()}>
              {props.description.map((el, _i, array) => (
                <div key={uuidv4()}>
                  <span className="paragraph">
                    {t(el.key)}{' '}
                    {getCardApiValue(el?.value)?.value +
                      getCardApiValue(el?.value)?.unit}
                  </span>
                  {_i < array.length - 1 && <br />}
                </div>
              ))}
            </div>
          );
        }
        break;

      case '{{trees}}':
        return (
          <div>
            <span>
              {t(props.description.line_1)}
              <br />
              {t(props.description.line_2, { trees: cardValue?.value || 0 })}
            </span>
          </div>
        );
        break;
      case 'trees':
        return (
          <div>
            <span>
              {t(props.description.line_1)}
              <br />
              {t(props.description.line_2)}
              <strong style={{ fontWeight: 'bolder' }}>
                {` ${cardValue?.value}  ${t('widgets.pollutionCard.trees')} `}
              </strong>
              {t(props.description.line_3)}
            </span>
          </div>
        );
      case 'ranking[label+value]':
        return (
          <div>
            <span key={uuidv4()} className="paragraph">
            <strong> {t(props.description)} </strong>
            </span>
            <span key={uuidv4()} className="paragraph">
              {

                getSiteRanking()
              
                
              }
            </span>
        </div>
        );
        break;
    }
  };

  return (
    <div
      className={` ${
        props.gradientBorder && styles.InformationCard__GradientBorder
      }`}
      style={{ height: '100%' }}
      key={Math.floor(Math.random() * 100)}
    >
      <div
        className={`${styles.InformationCard} ${
          ENV.VITE_APP_IS_WEB === 'true' && styles.InformationCard__Web
        }`}
      >
        {props.icon && (
          <span key={uuidv4()} className={InformationCard__Icon}>
            {<IconWrapper iconName={props.icon} />}{' '}
          </span>
        )}{' '}
        { props?.totalValue && <span
          className={`${InformationCard__Value} `}
          style={{ fontWeight: 300 }}
        >
          {' '}
          {getCardValue()}
        </span>}
        <span
          key={uuidv4()}
          className={`${
            props?.detail === false
              ? InformationCard__Label__Disabled
              : InformationCard__Label
          } heading4`}
          onClick={() => (props?.detail === false ? undefined : onClickLabel())}
        >
          {props.id === 'pollutionCard' ? (
            <span>
              CO<sub>2</sub>
            </span>
          ) : (
            t(`widgets.${props.id}.title`)
          )}
          <IconWrapper
            className={'InformationCard__Chevron'}
            iconName="chevron-right"
          />
        </span>
        <span className={`${InformationCard__Detail} detail`}>
          {getCardDetail()}
        </span>
      </div>
    </div>
  );
}

export default InformationCard;
