import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import _en from '../locales/en.json';
import _it from '../locales/it.json';
import _es from '../locales/es.json';

// Kwick's
import kwick_en from '../locales/kwick/en.json';
import kwick_it from '../locales/kwick/it.json';
import plenitude_en from '../locales/plenitude/en.json';
import plenitude_it from '../locales/plenitude/it.json';
import solectivo_en from '../locales/solectivo/en.json';
import solectivo_es from '../locales/solectivo/es.json';

const ENV = (import.meta as any).env;

const resources: Record<string, any> = {
  _en: { common: _en },
  _it: { common: _it },
  _es: { common: _es },
  kwick_en: { common: kwick_en },
  kwick_it: { common: kwick_it },
  plenitude_en: { common: plenitude_en },
  plenitude_it: { common: plenitude_it },
  solectivo_en: { common: solectivo_en },
  solectivo_es: { common: solectivo_es },
  admin_en: { common: _en },
  admin_it: { common: _it },
  admin_es: { common: _es },
};



i18n
  .use(initReactI18next)
  .init({
  compatibilityJSON: 'v3',
  ns: ['common'],
  defaultNS: 'common',
  resources,
  debug: true,
  lng: `${ENV.VITE_APP_NAME}_${ENV.VITE_APP_LANGUAGE}`,
  fallbackLng: `${ENV.VITE_APP_NAME}_en`,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense:false,
 }
});


export const TranslationsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
