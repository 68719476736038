import React, { useState, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  UserContext,
  CalendarContext,
  ThemeContext,
  DataStoreTypes,
  globalSetDataStore,
  MetaDataApiContext,
  AlertContext,
  AlertTypes,
  UserTypes,
} from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';
import { MetaDataApiTypes } from "@flexo/providers";;

const ENV = (import.meta as any).env;

let round = 0;

const firebaseService = new FirebaseService();

export const CommunitiesOverviewResolver = () => {

  const { setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { setAlertStore } = useContext(AlertContext);
  const { setUserStore } = useContext(UserContext);

  async function init() {
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      
      
      setUserStore({
        type: UserTypes.SetUserLogOut,
        payload: {
          value: true,
        }
      })

      return;
    }


    const [overview] = await Promise.all([
      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_STAGING_URL}/hive/api/catalog/v1_2/organization/${ENV.VITE_APP_ORG_ID}/overview`,
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {

            setAlertStore({
              type: AlertTypes.SetAlert,
              payload: {
                value: {
                  type: 'ALERT_UNEXPECTED_ERROR'
                },
              }
            })

            return null;
          }
        })
        .catch((err: any) => {

          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR'
              },
            }
          })

          return null;
        }),
    ]);

    // globalSetDataStore({
    //   type: DataStoreTypes.SetCommonData,
    //   payload: {
    //     value: {
    //       communitiesOverview: overview
    //     },
    //   },
    // });

    setMetaDataApiStore({
      type: MetaDataApiTypes.SetCommunitiesOverview,
      payload: {
        value: overview,
      },
    })

  }

  useEffect(() => {
    if (round === 0) {
      init();
      round++;
    }
  }, []);

  return <></>;
};
