import { useEffect, useState, useRef, useContext } from 'react';
import { Button, Textfield } from '@flexo/atoms';
import styles from './forgotten-password.module.scss';
import { useNavigate } from 'react-router-dom';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import { Storage } from '@ionic/storage';
import axios from 'axios';
import { AlertContext, AlertTypes, ModulesContext, ModulesTypes } from '@flexo/providers';
import { Player } from '@lottiefiles/react-lottie-player';

// Initialize Ionic Storage
const storage = new Storage();
storage.create();

export enum ForgottenPasswordStage {
  EnterEmail = 1,
  EnterRecoveryCode = 2,
  ResetPassword = 3,
}

export interface PasswordValidation {
  lengthValid: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  passwordsMatch: boolean;
}

export interface InputErrors {
  email: boolean;
  code: boolean;
  newPassword: boolean;
  confirmPassword: boolean;
}

export function ForgottenPassword({ containedLabelTextfield }: any) {
  const {
    ForgottenPassword__Header,
    ForgottenPassword__Header__Title,
    ForgottenPassword__Header__Subtitle,
    ForgottenPassword__Inputs,
    ForgottenPassword__Footer,
  } = styles;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [recoveryCode, setRecoveryCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentStage, setCurrentStage] = useState<ForgottenPasswordStage>(
    ForgottenPasswordStage.EnterEmail
  );
  const { setModulesStore } = useContext(ModulesContext);
  const loader = '/loaderLottie.json';
  const [loading, setLoading] = useState(false); // New loading state

  const [inputType, setInputType] = useState({
    newPassword: 'password',
    confirmPassword: 'password',
  });
  const [icon, setIcon] = useState({
    newPassword: 'visibility',
    confirmPassword: 'visibility',
  });
const {alertStore, setAlertStore} = useContext(AlertContext);
  const [passwordValidation, setPasswordValidation] =
    useState<PasswordValidation>({
      lengthValid: false,
      hasNumber: false,
      hasSpecialChar: false,
      hasUpperCase: false,
      hasLowerCase: false,
      passwordsMatch: false,
    });

  const [errors, setErrors] = useState<InputErrors>({
    email: false,
    code: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'recoveryCode':
        setRecoveryCode(value);
        break;
      case 'newPassword':
        setNewPassword(value);
        validatePassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        setPasswordValidation((prev) => ({
          ...prev,
          passwordsMatch: newPassword === value,
        }));
        break;
    }
  };

  const validatePassword = (password: string) => {
    const lengthValid = password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);

    setPasswordValidation({
      lengthValid,
      hasNumber,
      hasSpecialChar,
      hasUpperCase,
      hasLowerCase,
      passwordsMatch: password === confirmPassword,
    });
  };

  const requestRecoveryCode = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        'https://gateway-staging.flexo.energy/auth/v1/password-forgot',
        null,
        {
          params: {
            email: email,
            tenant_id: 'internal-foj18',
          },
        }
      );

      if (response.status === 200) {
        setCurrentStage(ForgottenPasswordStage.EnterRecoveryCode);
      } else {
        console.error('Failed to request recovery code:', response.data);
      }
    } catch (error) {
      console.error('Error requesting recovery code:', error);
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_UNEXPECTED_ERROR',
          } as any,
        },
      });
    } finally {
      setLoading(false); // Stop loading after request completes
    }
  };

  const verifyRecoveryCode = async () => {
    setLoading(true); // Start loading
    try {
      setCurrentStage(ForgottenPasswordStage.ResetPassword);
    } finally {
      setLoading(false); // Stop loading after action completes
    }
  };

  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      console.error('Passwords do not match');
      return;
    }

    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        'https://gateway-staging.flexo.energy/auth/v1/password-change-confirm',
        null,
        {
          params: {
            email: email,
            reset_code: recoveryCode,
            new_password: newPassword,
            tenant_id: 'internal-foj18',
          },
        }
      );

      if (response.status === 200) {
        setModulesStore({
          type: ModulesTypes.ActivateModuleByName,
          payload: {
            value: 'login',
          },
        });
      } else {
        console.warn({
          status: response.status,
          message: response.statusText,
        });
      }
    } catch (error) {
      console.warn(error);
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_UNEXPECTED_ERROR',
          } as any,
        },
      });
    } finally {
      setLoading(false); // Stop loading after request completes
    }
  };

  const isEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleBackClick = () => {
    if (currentStage > ForgottenPasswordStage.EnterEmail) {
      setCurrentStage(currentStage - 1);
    } else {
      setModulesStore({
        type: ModulesTypes.ActivateModuleByName,
        payload: {
          value: 'login',
        },
      });
    }
  };
  const recoveryCodeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      currentStage === ForgottenPasswordStage.EnterRecoveryCode &&
      recoveryCodeRef.current
    ) {
      recoveryCodeRef.current.focus();
    }
  }, [currentStage]);
  const togglePasswordVisibility = (name: string) => {
    setInputType((prevState) => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : 'password',
    }));
    setIcon((prevIcon) => ({
      ...prevIcon,
      [name]:
        prevIcon[name] === 'visibility' ? 'hide-visibility' : 'visibility',
    }));
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name !== 'email' || !value.length) return;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !isEmail(value),
    }));
  };

  const stageConfig = {
    [ForgottenPasswordStage.EnterEmail]: {
      buttonLabel: t('pages.forgotten_password.sendButton'),
      action: requestRecoveryCode,
      isDisabled: () => !email || !isEmail(email),
      inputComponent: (
        <Textfield
          name="email"
          value={email}
          placeholder={
            t('pages.forgotten_password.email_placeholder') as string
          }
          label={t('pages.forgotten_password.email') as string}
          onChange={handleInputChange}
          type="email"
          containedLabel={true}
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          onBlur={handleBlur}
          error={errors.email}
          errorDescription={errors.email ? 'Inserisci una mail valida' : ''}
        />
      ),
    },
    [ForgottenPasswordStage.EnterRecoveryCode]: {
      buttonLabel: t('pages.forgotten_password.verifyButton'),
      action: verifyRecoveryCode,
      isDisabled: () => !recoveryCode,
      inputComponent: (
        <Textfield
          ref={recoveryCodeRef} // Attach the ref here
          name="recoveryCode"
          value={recoveryCode}
          placeholder={
            t('pages.forgotten_password.recoveryCode_placeholder') as string
          }
          label={t('pages.forgotten_password.recoveryCode') as string}
          onChange={handleInputChange}
          type="text"
          containedLabel={true}
          onBlur={handleBlur}
          error={errors.code}
          errorDescription={errors.code ? 'Incorrect recovery code' : ''}
        />
      ),
    },
    [ForgottenPasswordStage.ResetPassword]: {
      buttonLabel: t('pages.forgotten_password.resetButton'),
      action: resetPassword,
      isDisabled: () =>
        !newPassword ||
        !confirmPassword ||
        Object.values(passwordValidation).some((v) => v === false),
      inputComponent: (
        <>
          <Textfield
            name="newPassword"
            value={newPassword}
            placeholder={
              t('pages.forgotten_password.newPassword_placeholder') as string
            }
            label={t('pages.forgotten_password.newPassword') as string}
            onChange={handleInputChange}
            type={inputType.newPassword}
            containedLabel={true}
            icon={icon.newPassword}
            onIconClick={() => togglePasswordVisibility('newPassword')}
            onBlur={handleBlur}
            error={errors.newPassword}
            errorDescription={
              errors.newPassword ? 'Please review the password criteria' : ''
            }
          />
          <div>
            {Object.entries(passwordValidation)
              .filter(([key]) => key !== 'passwordsMatch')
              .map(([key, value]) => (
                <span
                  key={key}
                  className={`paragraph ${
                    value ? 'valid' : 'invalid'
                  } styles.ForgottenPassword__Conditions`}
                  style={{ display: 'flex' }}
                >
                  <span style={{ marginTop: '3px', marginRight: '8px' }}>
                    <IconWrapper
                      iconName={value ? 'check_encircled' : 'cross_encircled'}
                    />
                  </span>
                  {t(`pages.forgotten_password.validation.${key}`)}
                </span>
              ))}
          </div>
          <Textfield
            name="confirmPassword"
            value={confirmPassword}
            placeholder={
              t(
                'pages.forgotten_password.confirmPassword_placeholder'
              ) as string
            }
            label={t('pages.forgotten_password.confirmPassword') as string}
            onChange={handleInputChange}
            type={inputType.confirmPassword}
            containedLabel={true}
            icon={icon.confirmPassword}
            onIconClick={() => togglePasswordVisibility('confirmPassword')}
            onBlur={handleBlur}
            error={errors.confirmPassword}
            errorDescription={
              errors.confirmPassword ? 'Please review the password criteria' : ''
            }
          />
        </>
      ),
    },
  };

  const currentStageConfig = stageConfig[currentStage];

  //TODO MELI use regex from helpers instead of hardcoding it

  return (
    <div className={styles.ForgottenPassword}>
      <div className={ForgottenPassword__Header}>
        <button
          className={`${styles.ForgottenPassword__Header__Back} heading3`}
          onClick={handleBackClick}
        >
          <IconWrapper iconName="chevron-left" />
          {t('pages.forgotten_password.backButton')}
        </button>
        <span className={`${ForgottenPassword__Header__Title} heading2`}>
          {t('pages.forgotten_password.title')}
        </span>
        <span className={`${ForgottenPassword__Header__Subtitle} heading5`}>
          {t('pages.forgotten_password.text')}
        </span>
        <div className={ForgottenPassword__Inputs}>
          {currentStageConfig.inputComponent}
        </div>
      </div>
      <div className={ForgottenPassword__Footer}>
        <Button
          onClick={currentStageConfig.action}
          variant="primary"
          color="secondary"
          label={
            loading ? (
              <Player autoplay loop src={loader} style={{ height: 20 }} />
            ) : (
              currentStageConfig.buttonLabel
            )
          }
          disabled={currentStageConfig.isDisabled() || loading} // Disable button during loading
        />
      </div>
    </div>
  );
}

export default ForgottenPassword;
