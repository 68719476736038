import { v4 as uuidv4 } from 'uuid';

import styles from './member-sites-list.module.scss';
import { useContext, useRef, useState } from 'react';
import { DataContext, MetaDataApiContext, ModulesContext, SiteViewContext, ThemeContext } from '@flexo/providers';
import { useTranslation } from 'react-i18next';
import { IconWrapper, capitalizeFirstLetter } from '@flexo/general';
import { navigateToSite } from '@flexo/atoms';


export function MemberSitesList(props: any) {
  const {
    Sites__List__Wrapper,
    Sites__List__Item,
    Sites__List__Wrapper__Flat,
    Tall,
    Sites__List__Item__Name,
    Sites__List__Item__Pod,
    Sites__List__Item__Detail,
    Sites__List__Item__Label,
  } = styles;
  const { t } = useTranslation();
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext)
  const { modulesStore, setModulesStore } = useContext(ModulesContext);

  return (
    <div
      key={`member_sites_list_${uuidv4()}`}
      className={`${['sm', 'xs', 'xxs'].includes(modulesStore.breakpoint) ? Sites__List__Wrapper__Flat : Sites__List__Wrapper} ${modulesStore.breakpoint === 'sm' && Tall}`}
    >

      {
        ((metaDataApiStore?.selectedEntity as any)?.sites || [])
          .map((site: any) => {
            return (

              
              <div key={`${site.id}_${uuidv4()}`} className={ `${Sites__List__Item}` }>
                {/* { JSON.stringify( site )} */}
                <p
                  onClick={ () => navigateToSite(site, setMetaDataApiStore, setModulesStore, 'economics') }
                  className={`heading4 ${Sites__List__Item__Name}`}
                >
                  {capitalizeFirstLetter(site.siteName)}
                  <IconWrapper iconName='chevron-right' />
                </p>
                <p className={ Sites__List__Item__Pod }>{ site.siteExternalId }</p>
                <p className={ Sites__List__Item__Detail }>{ site.startDate}</p>
                <p className={ Sites__List__Item__Label }>Joining date</p>
                <p className={ Sites__List__Item__Detail } >{ capitalizeFirstLetter(site.type) }</p>
                <p className={ Sites__List__Item__Label } >Site</p>
              </div>
            )
          } )
      }

    </div>
  )
  

}

export default MemberSitesList;
