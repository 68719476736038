import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { KpiApiContext, MetaDataApiContext, ThemeContext } from '@flexo/providers';

import { v4 as uuidv4 } from 'uuid';

import styles from './horizontal-chart.module.scss';
import HorizontalChart from './horizontal-chart';

export default function HorizontalChartWrapper(props: any) {

  const { t } = useTranslation();
  const { ThemeStore } = useContext(ThemeContext)
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const { kpiApiStore } = useContext(KpiApiContext);

  const getItemsList = () => {

    const sitesList = (metaDataApiStore?.entities as any)?.sites || [];

    if (props?.context === 'energy') {
      return kpiApiStore?.communityRanking?.consumptionRanking?.[0]?.site
        .map((_site: any) => ({
          ..._site,
          ...sitesList.find(( entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
        }) )
    } else if (props?.context === 'economics') {
      return kpiApiStore?.communityRanking?.economicsRanking?.[0]?.site
      .map((_site: any) => ({
        ..._site,
        ...sitesList.find(( entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
      }) )
    }

    return []
  }

  function getKpiValue( item: any ) {
    const kpi = item?.kpi?.find((datum: any) => props?.rankingKpi === datum.name);

    const value = kpi?.value || 0;
    const unit = kpi?.unit || '';
    return props?.context === 'economics' ? `${unit} ${value}` : `${value} ${unit}`;
  }
  

  return (
    <div id="HorizontalChart__Wrapper" className={styles.HorizontalChart__Wrapper}>
      <div className={styles.HorizontalChart__Title}>
        {t(props.title)}
      </div>
      <div className={styles.HorizontalChart__Buttons__Wrapper}>
        {
          props.buttons.map((button: any, index: number) => {
              return (
                <div key={`HorizontalChart__${ index }__button__${ button }`} className={styles.HorizontalChart__Button}>
                  {t(button)}
                  <div className={ styles.HorizontalChart__Button__Underline } style={{ backgroundColor: `${ ThemeStore?.colors[ props.colors[ index ].color ]}${props.colors[ index ].colorShade}` }}></div>
                </div>
              )

            
          })
        }
      </div>

      {
        
        (getItemsList() || []).map((item: any, index: number) => {
          return <div key={uuidv4()} className={ styles.HorizontalChart__Wrapper__Line }>
            <div className={styles.HorizontalChart__Wrapper__Line__Label} >
              {
                item?.pod
              }
              &nbsp;
              { props?.context === 'economics' && <span className="font-flexo-light">{item?.siteName}</span>  }
            </div>
            <div className={ styles.HorizontalChart__Wrapper__Line__Rank }>
              <div>
                { getKpiValue( item ) }
              <br />
                { t(props?.rankingKpiName)}
              </div>
            <HorizontalChart data={{
              kpi: item?.kpi,
              ...props
            }} />
            </div>
            
          </div>
        })
      }

    </div>
  )

}