//Sets the selected site in the metadata api store and navigates to the site context
import React, { useState, useContext, useEffect } from 'react';

import {
  UserContext,
  ModulesContext,
  MetaDataApiContext,
  ModulesTypes,
} from '@flexo/providers';
import { FirebaseService } from '@flexo/general';
import { use } from 'i18next';
import { EntitiesData, MetaDataApiTypes } from '@flexo/providers';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const navigateToSite = (
  site: any,
  setMetaDataApiStore: any,
  setModulesStore: any,
  context: string | null = null
) => {
  let url = 'household';
  let entity = 'household';

  if (context === 'energy') {
    url = `site-energy`;
    entity = `site-energy`;
  }

  if (context === 'economics') {
    url = `site-economics`;
    entity = `site-economics`;
  }

  const SITE_ID =
    typeof site === 'string'
      ? site
      : site?.id || site?.siteId || site?.flexoSiteId;

  setModulesStore({
    type:
      entity === 'household'
        ? ModulesTypes.ActivateHouseholdIdModule
        : ModulesTypes.NavigateToSiteContext,
    payload:
      entity === 'household'
        ? {
            value: SITE_ID,
          }
        : {
            value: {
              entity: entity,
              id: SITE_ID,
            },
          },
  });

  setMetaDataApiStore({
    type: MetaDataApiTypes.SetSelectedEntity,
    payload: {
      value: {
        selectedEntity: typeof site === 'string' ? null : site,
        id: SITE_ID,
        entityType: entity,
      },
    },
  });
};

export const SiteMetaResolver = () => {
  const { userStore } = useContext(UserContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);

  const [currentSeletedSite, setCurrentSeletedSite] = useState(null);

  function setSelectedSite() {
    if (typeof metaDataApiStore?.selectedEntityId === 'string') {
      return;
    }

    if ((!metaDataApiStore?.entities as any)?.sites?.[0]?.siteId) {
      return;
    }

    if (
      currentSeletedSite !==
      (metaDataApiStore?.entities as any)?.sites?.[0]?.siteId
    ) {
      setCurrentSeletedSite(
        (metaDataApiStore?.entities as any)?.sites?.[0]?.siteId
      );

      setMetaDataApiStore({
        type: MetaDataApiTypes.SetSelectedEntity,
        payload: {
          value: {
            selectedEntity: (metaDataApiStore?.entities as any)?.sites?.[0],
            id: (metaDataApiStore?.entities as any)?.sites?.[0]?.siteId,
            entityType: 'household',
          },
        },
      });
    }
  }

  useEffect(() => {
    setSelectedSite();
  }, [metaDataApiStore?.entities as any]);

  return <></>;
};
