import React, { useState, useContext, useEffect } from 'react';


import { UserContext, MetaDataApiContext, FiltersStoreTypes, globalSetFiltersStore, AlertContext, AlertTypes, UserTypes } from '@flexo/providers';
import { httpService } from '@flexo/general';
import { MetaDataApiTypes } from "@flexo/providers";;

const ENV = (import.meta as any).env;

let round = 0;
let currentSelectedEntityId: string|null = null;

export const MembersResolver = () => {
  const { userStore, setUserStore } = useContext(UserContext);
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext);
  const {setAlertStore} = useContext(AlertContext);

  async function init() {


    const idToken = await userStore.firebaseService.getIdToken();


    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      setUserStore({
        type: UserTypes.SetUserLogOut,
        payload: {
          value: true,
        }
      })

      return;
    }

    const COMMUNITY_ID = metaDataApiStore?.selectedEntity?.communityId || (metaDataApiStore?.entityType === 'community' ? metaDataApiStore?.selectedEntityId : null);

    if (!COMMUNITY_ID) {
      return;
    }

    const [members] = await Promise.all([
      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_STAGING_URL}/hive/api/catalog/v1_2/community/${COMMUNITY_ID}/members`,
          // /hive/api/catalog/v1_2/community/{communityId}/members
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            console.warn({
              status: res.status,
              message: res.statusText,
            });
            return null;
          }
        })
        .catch((err: any) => {
          console.warn(err);

          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR'
              },
            }
          })

          return null;
        }),
    ]);

    try {
      
      setMetaDataApiStore({
        type: MetaDataApiTypes.SetMembers,
        payload: {
          value: members,
        },
      });
      
      
    } catch (err: any) {
      console.warn(err);
    }



    // try {

      
    // globalSetFiltersStore({
    //   type: FiltersStoreTypes.SetFilteredMembers,
    //   payload: {
    //     value: members
    //   }
    // })
    // } catch (err: any) {
    //   console.warn(err);
    // }

  }

  useEffect(() => {

    if (currentSelectedEntityId && currentSelectedEntityId !== metaDataApiStore.selectedEntityId) {
      currentSelectedEntityId = metaDataApiStore.selectedEntityId;
      init();
    }

    if (round === 0 ) {
      currentSelectedEntityId = metaDataApiStore.selectedEntityId;
      init();
      round++;
    } 
  }, [ metaDataApiStore.selectedEntityId, metaDataApiStore?.selectedEntity?.communityId, metaDataApiStore?.entityType ]);



  return <></>
};
