


import { useTranslation } from "react-i18next"
import styles from './../alert.module.scss'
import { IconWrapper } from "@flexo/general";

export default function BannerPasswordresetSuccess() {

  const { t } = useTranslation();
  const alertContent = t('banners.password-reset-success', { returnObjects: true }) as any;

  return (
    <div id="banner-password-reset-success" className={ styles.Banner__Password__Reset__Success}>
      <div className={styles.Banner__Title__Positive} >
        { alertContent.title }
      </div>
      <div className={styles.Banner__Content} >
        {alertContent.description} 
      </div>
    </div>
  )
}