import { useContext, useRef, useState } from 'react';
import {
  CalendarContext,
  CommunityViewContext,
  ThemeContext,
} from '@flexo/providers';
import { formatString } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import { DonutGraph } from '@flexo/atoms';
import styles from './community-stats.module.scss';
import { CommunityStatsSkeleton } from './community-stats-skeleton';
import { get } from 'http';
import {
  KpiApiContext,
  MetaDataApiContext,
  SiteViewContext,
} from '@flexo/providers';
export interface CommunityStatsData {
  totalAmount: string;
  totalAmounttype: string;
  colors: string[];
  keys: string[];
  title: string;
  percentage: number;
}
export interface CommunityStatsProps {
  from_network: CommunityStatsData;
  to_network: CommunityStatsData;
  shared: CommunityStatsData;
  dataInTimeSpan: boolean;
}
export const communityPlaceholderData = [{ label: '', value: 100 }];

export function CommunityStats(props) {
  const { CommunityStats__Stat } = styles;
  const [selected, setSelected] = useState<string | null>(null); // [1
  const { ThemeStore } = useContext(ThemeContext);
  const { kpiApiStore, setKpiApiStore } = useContext(KpiApiContext);

  const { site, community } = kpiApiStore;

  const holdTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { t } = useTranslation();
  const getProps = () => {
    const _props: any = { ...props };
    delete _props.dataInTimeSpan;
    return _props;
  };
  const overviewKpiApiData = community['1day'][0]?.kpi as any;

  if (!overviewKpiApiData)
    return <CommunityStatsSkeleton props={getProps()} />;

  const getCardValue = (key) => {
    const cardValue = overviewKpiApiData?.find((el) => el.name === key);
    return cardValue;
  };

  const formatGraphData = (item) => {
    const result = item.map((el) => {
      return {
        id: el?.id,
        label: el?.label,
        value: getCardValue(el?.value)?.value || 10,
        formattedValue:
          getCardValue(el?.value)?.value + getCardValue(el?.value)?.unit,
      };
    });

    return result;
  };

  const getColors = (item) => {
    const color = item.map((el) => ThemeStore.colors[el.color] + el.colorShade);
    return color;
  };

  const startHold = (valueLabel) => {
    setSelected(valueLabel.values[0].id);

    // Set a timeout for the desired duration (e.g., 2 seconds)
    holdTimeout.current = setTimeout(() => {
      // Do nothing if held for the full duration (or perform another action if needed)
    }, 2000);
  };

  const endHold = () => {
    clearTimeout(holdTimeout.current as any); // clear the timeout if user releases before the duration
    setSelected(null);
  };

  return (
    <div
      className={`${styles.CommunityStats} ${
        props.gradientBorder && styles.CommunityStats__GradientBorder
      }`}
    >
      <div className={styles.CommunityStats}>
        {props.data.map((item, index) => {
          return (
            <div
              className={CommunityStats__Stat}
              key={item.title}
              onTouchStart={() => startHold(item)}
              onTouchEnd={endHold}
            >
              <DonutGraph
                width={105}
                height={105}
                graphData={formatGraphData(item.graphData)}
                colors={getColors(item.graphData)}
                // totalAmount={getTotalAmount(item) as any}
                // totalAmounttype={overviewUnit}
                totalAmount={getCardValue(item.totalAmount)?.value}
                totalAmounttype={getCardValue(item.totalAmount)?.unit}
                keys={['sites', 'community']}
                selectedId={selected === item.title ? selected : null}
                setSelectedId={setSelected}
                animate={false}
              />
              <div className={styles.CommunityStats__Stat__Information}>
                <div
                  className={`heading5 ${styles.CommunityStats__Stat__Title}`}
                >
                  {t(`widgets.communityStats.${item.title}`)}
                </div>
                <div
                  className={`detail ${styles.CommunityStats__Stat__Description}`}
                >
                  {t(`widgets.communityStats.me`, {
                    // percentage: getPercentageValue(item),
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CommunityStats;
