import { useContext, useState } from 'react';
import { Button, Textfield } from '@flexo/atoms';
import {
  FirebaseService,
  IconWrapper,
  REGEX,
  httpService,
} from '@flexo/general';
import styles from './forgot-password.module.scss';
import {
  AlertContext,
  AlertTypes,
  DataContext,
  ModulesContext,
  ModulesTypes,
  UserContext,
} from '@flexo/providers';
import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'react-i18next';

const ENV = (import.meta as any).env;
const firebaseService = new FirebaseService();

// let _authData = {
//   email: '',
//   recovery_code: '',
// }

let _page = 1;

let _sendEmailError = false;
let _recoveryCodeRequestError = false;

/* eslint-disable-next-line */
export interface ForgotPasswordProps {
  logInData: any;
  setLogInFlow: any;
  bgColor?: string;
}

export function ForgotPassword({ setLogInFlow }: ForgotPasswordProps) {
  const {
    LogIn__Inputs,
    LogIn__Footer,
    LogIn__Footer__End,
    LogIn__Footer__Button,
    LogIn__Footer__Return__Wrapper,
  } = styles;

  const { setModulesStore } = useContext(ModulesContext);

  const [authData, setAuthData] = useState({
    email: '',
    recovery_code: '',
  });
  const [logInClicked, setLogInClicked] = useState(false);

  const [sendEmailError, setSendEmailError] = useState(_sendEmailError);
  const [sendCodeRequest, setSentSendCodeRequest] = useState(false);

  const [recoveryCodeRequestError, setRecoveryCodeRequestError] = useState(
    _recoveryCodeRequestError
  );
  const [recoveryCodeSent, setRecoveryCodeSent] = useState(false);

  const { setAlertStore } = useContext(AlertContext);
  const [logInError, setLogInError] = useState(false);
  const [networkStatus, setNetworkStatus] = useState(false);
  const { t } = useTranslation();

  const [inputType, setInputType] = useState({
    email: 'text',
    password: 'password',
    confirmPassword: 'password',
  });

  const [page, setPage] = useState(_page);

  const {
    title,
    subtitle,
    inputs,
    buttonLabel,
    return_label,
    onButtonClick,
    passwordConditions,
  } = t('pages.forgot_password.data', { returnObjects: true }) as any;

  const {
    title: recoveryTitle,
    subtitle: recoverySubtitle,
    subtitle_addon: recoverySubtitleAddon,
    inputs: recoveryInputs,
    buttonLabel: recoveryButtonLabel,
  } = t('pages.recovery_code_data.data', {
    returnObjects: true,
    email: authData.email,
  }) as any;

  const loader = '/loaderLottie.json';

  async function askForRecoveryCode() {
    setSentSendCodeRequest(true);

    const response = await httpService
      .post({
        url: `${ENV.VITE_APP_BASE_STAGING_URL}/auth/v1_1/password-forgot`,
        data: {
          email: authData.email,
        },
      })
      .then((resp: any) => ({
        status: resp.status,
        statusText: resp?.statusText || 'Unknown status text',
        message: resp?.data?.message || 'Unknown message',
      }))
      .catch((error) => {
        console.warn(error);
        return {
          status: error?.response?.status || 500,
          statusText: error?.response?.statusText || 'Unknown error',
        };
      });

    setSentSendCodeRequest(false);

    if (response.status > 200) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_UNEXPECTED_ERROR',
          },
        },
      });
      if (response.status === 403) {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_NOT_ALLOWED',
            },
          },
        });
      } else {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR',
            },
          },
        });
      }

      _sendEmailError = true;
      setSendEmailError(_sendEmailError);
    } else {
      _sendEmailError = false;
      setSendEmailError(_sendEmailError);
      _page = 2;
      setPage(_page);
    }
  }

  const handleInputChange = (event: any) => {
    _sendEmailError = false;
    setSendEmailError(_sendEmailError);
    _recoveryCodeRequestError = false;
    setRecoveryCodeRequestError(_recoveryCodeRequestError);

    // _authData = { ..._authData, [event.target.name]: event.target.value };
    // setAuthData(_authData);

    setAuthData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const togglePasswordVisibility = (name: string) => {
    setInputType((prevState) => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : 'password',
    }));
  };

  function navigateToResetPassword() {
    setModulesStore({
      type: ModulesTypes.ActivateResetPasswordModule,
      payload: {
        value: authData,
      },
    });
  }

  return (
    <div className={styles.LogIn}>
      <div className={styles.LogIn__Logo}>
        <IconWrapper iconName="logo" />
      </div>

      {page === 1 && (
        <div className={styles.Login__Content}>
          <div className={styles.LogIn__Header__Title}>{title}</div>

          <div className={styles.LogIn__Header__Subtitle}>{subtitle}</div>

          <form
            className={LogIn__Inputs}
            onSubmit={(ev: any) => ev.preventDefault()}
          >
            {inputs.map((input: any) => (
              <div key={input.name}>
                <Textfield
                  containedLabel={false}
                  name={input.name}
                  value={authData[input.name]}
                  placeholder={input.placeholder}
                  label={input.label}
                  onChange={(event) => handleInputChange(event)}
                  onPaste={(e) => false}
                  onCopy={(e) => false}
                  // type={input.type}
                  description={input.description}
                  type={inputType[input.name]}
                  icon={input.icon}
                  onIconClick={togglePasswordVisibility}
                  // onDescriptionClick={() => {
                  //   navigate('/forgotten-password');
                  // }}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                  autoComplete="off"
                  underline={sendEmailError === true ? 'visible' : 'hidden'}
                  //FIX ME --> this is commented out for testing purposes on plenitude
                />
              </div>
            ))}
          </form>
          <div className={LogIn__Footer}>
            <div className={LogIn__Footer__Return__Wrapper}>
              {return_label.label}&nbsp;
              <span
                onClick={() =>
                  setModulesStore({ type: ModulesTypes.ActivateLoginModule })
                }
              >
                {return_label.buttonLabel}
              </span>
            </div>
            <Button
              className={LogIn__Footer__Button}
              onClick={() => askForRecoveryCode()}
              label={
                sendCodeRequest === true ? (
                  <Player
                    autoplay
                    loop
                    src={loader}
                    style={{ height: 20, transform: 'scale(0.7)' }}
                  />
                ) : (
                  buttonLabel
                )
              }
              variant="primary"
              color="secondary"
              disabled={
                !REGEX.email.test(authData.email) || sendCodeRequest === true
              }
            />
          </div>
        </div>
      )}

      {page === 2 && (
        <div className={styles.Login__Content}>
          <div className={styles.LogIn__Header__Title}>{recoveryTitle}</div>

          <div className={styles.LogIn__Header__Subtitle}>
            {recoverySubtitle}{' '}
            <span className={`color-warning-500`}>{recoverySubtitleAddon}</span>
          </div>

          <form className={LogIn__Inputs}>
            {recoveryInputs.map((input: any) => (
              <div key={input.name}>
                <Textfield
                  containedLabel={false}
                  name={input.name}
                  value={authData[input.name]}
                  placeholder={input.placeholder}
                  label={input.label}
                  onChange={(event) => handleInputChange(event)}
                  onPaste={(e) => false}
                  onCopy={(e) => false}
                  // type={input.type}
                  description={input.description}
                  type={inputType[input.name]}
                  icon={input.icon}
                  onIconClick={togglePasswordVisibility}
                  // onDescriptionClick={() => {
                  //   navigate('/forgotten-password');
                  // }}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                  autoComplete="off"
                  //FIX ME --> this is commented out for testing purposes on plenitude
                />
              </div>
            ))}
          </form>

          <div className={LogIn__Footer__End}>
            <Button
              className={LogIn__Footer__Button}
              onClick={() => navigateToResetPassword()}
              label={
                logInClicked && !logInError ? (
                  <Player
                    autoplay
                    loop
                    src={loader}
                    style={{ height: 20, transform: 'scale(0.7)' }}
                  />
                ) : (
                  recoveryButtonLabel
                )
              }
              variant="primary"
              color="secondary"
              disabled={!REGEX.verificationCode.test(authData.recovery_code)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
