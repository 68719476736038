import React, {
  ButtonHTMLAttributes,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Popper } from '@mui/material';
import { useOnClickOutside } from '@flexo/hooks';

import styles from './profile-button.module.scss';

import {
  AlertContext,
  AlertTypes,
  IRightDrawerTypes,
  ModulesContext,
  ModulesTypes,
  RightDrawerContext,
  UserContext,
  UserTypes,
} from '@flexo/providers';
import { useTranslation } from 'react-i18next';
import { auth, httpService, IconWrapper } from '@flexo/general';

const ENV = (import.meta as any).env;

export const ProfileButton: React.FC = () => {
  const {
    ProfileButton,
    ProfileButton__Wrapper,
    ProfileButton__Popper,
    ProfileButton__Popper__Item,
    ProfileButton__Popper__Item__LogOut,
    ProfileButton__Popper__Item__DisplayName,
    ProfileButton__Popper__Item__Email,
    ProfileButton__Popper__Options__List,
    ProfileButton__Popper__Item__Option,
    ProfileButton__Popper__Item__Option__Text,
    Clickable,
  } = styles;

  const menuRef = React.useRef(null);

  const [open, setOpen] = useState<boolean>(false);
  const { userStore, setUserStore } = useContext(UserContext);
  const { setAlertStore } = useContext(AlertContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { setRightDrawerStore } = useContext(RightDrawerContext);
  const firebaseService = userStore.firebaseService;
  const { t, i18n } = useTranslation();

  function isChildOf(event) {
    const parentDiv = document.getElementById('profile-button');
    let targetElement = event.target; // L'elemento che è stato cliccato

    // Itera attraverso i genitori dell'elemento target fino a raggiungere il body
    while (targetElement !== document.body) {
      if (targetElement === parentDiv) {
        return true; // Il target è figlio del parentDiv
      }
      targetElement = targetElement.parentNode; // Vai al genitore dell'elemento target
    }

    return false; // Il target non è figlio del parentDiv
  }

  useOnClickOutside(menuRef, (ev: any) => {
    if (open && isChildOf(ev) === false) {
      setOpen(false);
    }
  });

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open);
  };

  const onClickLogOut = async () => {
    setOpen(false);

    const IDtoken = await firebaseService.getIdToken();

    if (!IDtoken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      setUserStore({
        type: UserTypes.SetUserLogOut,
        payload: {
          value: true,
        },
      });

      return;
    }

    const signOutResponse = await httpService
      .post({
        url: `${ENV.VITE_APP_BASE_STAGING_URL}/auth/v1_1/signout`,
        idToken: IDtoken,
        data: {},
      })
      .then((resp: any) => ({
        status: resp.status,
        statusText: resp?.statusText || 'Unknown status text',
        message: resp?.data?.message || 'Unknown message',
      }))
      .catch((error) => {
        console.warn(error);
        return {
          status: error?.response?.status || 500,
          statusText: error?.response?.statusText || 'Unknown error',
        };
      });

    if (signOutResponse.status > 200) {

      if (signOutResponse.status === 403) {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_NOT_ALLOWED'
            },
          },
        })
      } else {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR'
            },
          },
        })
      }

      

      return;
    }

    await firebaseService.signOut();

    setUserStore({
      type: UserTypes.ResetUserStore,
      payload: null as any,
    });

    setAlertStore({
      type: AlertTypes.SetAlert,
      payload: {
        value: {
          type: 'BANNER_LOGOUT_SUCCESS',
        },
      },
    });

    await new Promise((resolve) => setTimeout(resolve, 3000));

    setUserStore({
      type: UserTypes.SetUserLogOut,
      payload: {
        value: true,
      },
    });

    return;
  };

  const onClickProfile = () => {
    setRightDrawerStore({
      type: IRightDrawerTypes.SetDrawer,
      payload: {
        value: {
          type: 'CREDENTIALS_UPDATE_FORM',
        },
      },
    });
  };

  return (
    <div
      key={uuidv4()}
      className={ProfileButton__Wrapper}
      ref={menuRef}
      id="profile-button"
    >
      <Button className={ProfileButton} type="button" onClick={handleClick}>
        <IconWrapper iconName="user_outline" />
      </Button>
      {open && (
        <div className={ProfileButton__Popper}>
          <ul key={uuidv4()}>
            <li
              key={`ProfileButton__Popper__Item_${uuidv4()}`}
              className={`${ProfileButton__Popper__Item} ${Clickable}`}
              onClick={() => onClickProfile()}
            >
              <p className={`${ProfileButton__Popper__Item__DisplayName}`}>
                {auth?.currentUser?.displayName ||
                  userStore?.user?.auth?.currentUser?.uid ||
                  'Unavailable display name'}
              </p>
              <p className={`${ProfileButton__Popper__Item__Email}`}>
                {userStore?.user?.auth?.currentUser?.email ||
                  userStore?.user?.tokenContent?.firebaseUserResult?.email ||
                  'Unavailable display email'}
              </p>
            </li>
          </ul>
          <ul key={uuidv4()} className={ProfileButton__Popper__Options__List}>
            <li
              key={`ProfileButton__Popper__Item_${uuidv4()}`}
              className={ProfileButton__Popper__Item}
            >
              <p className={`${ProfileButton__Popper__Item__Option}`}>
                {t('dropdowns.profile.account_management.title')}
              </p>
              <p className={`${ProfileButton__Popper__Item__Option__Text}`}>
                {t('dropdowns.profile.account_management.text')}
              </p>
            </li>
            <li
              key={`ProfileButton__Popper__Item_${uuidv4()}`}
              className={ProfileButton__Popper__Item}
            >
              <p className={`${ProfileButton__Popper__Item__Option}`}>
                {t('dropdowns.profile.help_centre.title')}
              </p>
              <p className={`${ProfileButton__Popper__Item__Option__Text}`}>
                {t('dropdowns.profile.help_centre.text')}
              </p>
            </li>
            <li
              key={`ProfileButton__Popper__Item_${uuidv4()}`}
              className={ProfileButton__Popper__Item}
            >
              <p className={`${ProfileButton__Popper__Item__Option}`}>
                {t('dropdowns.profile.billing.title')}
              </p>
              <p className={`${ProfileButton__Popper__Item__Option__Text}`}>
                {t('dropdowns.profile.billing.text')}
              </p>
            </li>
            <li
              key={`ProfileButton__Popper__Item_${uuidv4()}`}
              className={ProfileButton__Popper__Item}
            >
              <p className={`${ProfileButton__Popper__Item__Option}`}>
                {t('dropdowns.profile.language.title')}
              </p>
              {/* <p className={ `${ProfileButton__Popper__Item__Option__Text}` }>{ t( `dropdowns.profile.language.text.${ || 'default' }` )  }</p> */}
            </li>
          </ul>
          <div
            key={`ProfileButton__Popper__Item_${uuidv4()}`}
            className={`${ProfileButton__Popper__Item__LogOut}`}
            onClick={() => onClickLogOut()}
          >
            {t('dropdowns.profile.logout')}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileButton;
