/* eslint no-sequences: "off" */

import React, { useEffect } from 'react';
import { useContext, useMemo, useRef, useState } from 'react';
import { BarRounded, BarStack, Line, LinePath } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { Haptics } from '@capacitor/haptics';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { CalendarContext, SiteViewContext } from '@flexo/providers';
// import styles from './stackbar-graph.module.scss';
import { GraphHeader } from '@flexo/atoms';
import { formatDate } from '../helpers';
import { IconWrapper } from '@flexo/general';
import styles from './combined-graph.module.scss';
import { add, max } from 'date-fns';
import { da, el } from 'date-fns/locale';

export const BarChartStyles = {
  titleCol: {
    padding: 0,
    marginLeft: '1em',
  },
  titleRow: {
    padding: 0,
    marginTop: '0em',
    marginLeft: '1em',
  },
};

export type IBarStack = {
  // width?: number;
  height?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
  dataInTimeSpan?: boolean;
  _data?: Array<any>;
  color?: string;
  unit?: string;
  title?: string | null;
  buttons: Array<string>;
  colorVariables?: any;
  view: 'site' | 'community';
};

export const CombinedGraph: React.FC<IBarStack> = ({
  height = 300,
  events = false,
  margin = { top: 35, right: 0, bottom: 0, left: 45 },
  _data = [],
  color = 'primary',
  title = null,
  buttons,
  colorVariables = [
    "#61769F",
    "#F9DA74",
    "#61769F"
  ],
  dataInTimeSpan = true,
  view,
}: IBarStack) => {
  const AnimatedBar = animated(BarRounded);

  const { t } = useTranslation();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const innerHeight = height - margin.top - margin.bottom;

  const batteryChargeData = _data.map((item) => {
    return {
      date: item.date,
      percentage: item.soc,
    };
  });
  const allAboveZero = batteryChargeData.every((item) => item.percentage !== 0);

  const formattedData = _data.map((item) => {
    return {
      date: item.date,
      'widgets.batteryCombinedGraph.battery_charge':
        item['widgets.batteryCombinedGraph.battery_charge'],
      'widgets.batteryCombinedGraph.battery_discharge':
        item['widgets.batteryCombinedGraph.battery_discharge'],
    };
  });

  const ref: any = useRef(null);
  const [layoutWidth, setLayoutWidth] = useState(0);
  const [data, setData]: any = useState(formattedData);
  const [toggle, setToggle]: any = useState(false);
  const [selectedBar, setSelectedBar] = useState<string | null>(null);
  const [allowToggle, setAllowToggle]: any = useState(false);
  const [visibleButtons, setVisibleButtons]: any = useState([true, true]);
  const [isVibrating, setIsVibrating] = useState(false);
  const { siteViewStore } = useContext(SiteViewContext);
  const { calendarStore } = useContext(CalendarContext);
  const { detailUnit, energyData } = siteViewStore;
  const [chartWidth, setChartWidth] = useState(window.innerWidth - 24);

  const { currentlySelectedDateType } = calendarStore;

  // const { detailUnit } = siteViewStore;

  const keys = () =>
    data.length > 0
      ? (Object.keys(formattedData[0]).filter(
          (d) => d !== 'date' && d !== 'power_peak_value'
        ) as [])
      : [];

  const energyTotals = keys().reduce(
    (prev: any, next: string) => [
      ...prev,
      ...data.map((item: any) => item[next]),
    ],
    []
  );

  // accessors
  const getDate = (d: any) => d.date;

  // bounds
  const xMax = (layoutWidth || chartWidth) - margin.left - margin.right;
  const yMax = height - margin.top - 100;
  // scales
  const dateScale = scaleBand<string>({
    domain: data.map(getDate),
    padding: 0.2,
    range: [0, xMax - 20],
  });

  const dateScaleBattery = scaleBand<string>({
    domain: batteryChargeData.map(getDate),
    padding: 0.2,
    range: [0, xMax - 20],
  });

  const maxValue = Math.max(
    ...data.map(
      (obj) => parseFloat(obj[buttons[0]]) + parseFloat(obj[buttons[1]])
    )
  );
  const energyScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [-maxValue, maxValue],
    nice: true,
  });
  const percentageScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 100],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: keys(),
    range: [colorVariables[0], colorVariables[1], colorVariables[2]],
  });

  const { scale } = useSpring({
    from: { scale: 0 },
    to: { scale: toggle ? 0 : 1 },
  });
  function toggleData(key: string, index: number) {
    setToggle(true);

    if (selectedBar !== null) {
      setSelectedBar(null);
      setVisibleButtons(buttons?.map(() => true));
      setData(formattedData);
      setTimeout(() => setToggle(false), 500);
      return;
    }

    // Calculate the new state of the current button
    const newButtonState = !visibleButtons[index];

    // If the new state is 'false' and there would be less than 1 button left as 'true', then return early
    if (!newButtonState && visibleButtons.filter((val) => val).length <= 1) {
      setTimeout(() => setToggle(false), 500);
      return;
    }

    let filteredData = formattedData;

    if (!newButtonState) {
      filteredData = data.map((timestamp: any) => ({ ...timestamp, [key]: 0 }));
    } else {
      filteredData = data.map((datum: any, datum_i: number) => ({
        ...datum,
        [key]: formattedData[datum_i][key],
      }));
    }

    setVisibleButtons((prev: Array<boolean>) =>
      prev.map((value: boolean, _i: number) =>
        _i === index ? newButtonState : value
      )
    );

    setData(filteredData);
    setTimeout(() => setToggle(false), 500);
  }

  // If you don't want to use a Portal, simply replace `TooltipInPortal` below with
  // `Tooltip` or `TooltipWithBounds` and remove `containerRef`
  const { TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  const handleMouseOver = async (event: any) => {
    const coords: any = localPoint(
      event?.target?.ownerSVGElement || event.target,
      event
    );
    let bar: any = null;

    try {
      const overedElement = (document as any).elementFromPoint(
        event.changedTouches[0].clientX,
        event.changedTouches[0].clientY
      );
      const parent = event.target.closest('.peakChart');

      const pseudoOveredElement = (document as any).elementFromPoint(
        event.changedTouches[0].clientX,
        parent.getBoundingClientRect().y + yMax + margin.top - 1
      );

      const highlightedElement =
        pseudoOveredElement?.classList?.value?.includes('visx-bar-rounded')
          ? pseudoOveredElement
          : overedElement;

      if (highlightedElement?.attributes?.['data-bar']) {
        bar = JSON.parse(highlightedElement.attributes['data-bar'].value);
      }

      if (bar) {
        const getStateOfChargeData = () => {
          return batteryChargeData.find((d) => d.date === bar.timeStamp);
        };
        selectBar(bar);
        showTooltip({
          tooltipLeft: coords.x,
          tooltipTop: parent.getBoundingClientRect().y - bar.height - 20,
          tooltipData: getStateOfChargeData(),
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  function getGreyScaledColor(color: string) {
    let _color = color;

    if (color.includes('#')) {
      const red = parseInt(color.substr(1, 2), 16);
      const green = parseInt(color.substr(3, 2), 16);
      const blue = parseInt(color.substr(5, 2), 16);
      // Calculate the grayscale value
      const grayscale = Math.round(0.299 * red + 0.587 * green + 0.114 * blue);
      // Convert the grayscale value back to a hex string
      const grayscaleHex = grayscale.toString(16).padStart(2, '0');
      // Return the grayscale hex string with '#' prefix
      _color = '#' + grayscaleHex + grayscaleHex + grayscaleHex;
    }
    return _color;
  }

  function getBarColor(key: any, index: any) {
    const rangeIndex = keys().findIndex((_key) => _key === key);
    if (selectedBar && selectedBar !== data[index].date) {
      return getGreyScaledColor(colorScale.range()[rangeIndex]);
    }
    return colorScale.range()[rangeIndex];
  }

  function selectBar(bar: any) {
    const barDate = bar?.bar?.data?.date || bar?.timeStamp;
    const isShowingPeak =
      (bar?.bar?.data?.['power_peak'] || bar?.power_peak) !== 0 &&
      (bar?.bar?.data?.['power_peak'] || bar?.power_peak) !== undefined;

    if (selectedBar === bar?.bar?.data?.date) {
      setSelectedBar(null);
      setVisibleButtons([true, true]);
    } else {
      if (isShowingPeak) {
        setVisibleButtons([false, true]);
        if (isVibrating === false) {
          setIsVibrating(true);
          Haptics.vibrate({ duration: 10 });
          setTimeout(() => setIsVibrating(false), 500);
        }
      } else {
        // setVisibleButtons([true, false]);
      }

      setSelectedBar(barDate || null);
    }
  }

  // useEffect(() => {
  //   setTimeout(async () => {
  //     setAllowToggle(true);
  //   }, 500);
  // }, []);

  const getSelectedBarData = () => {
    if (!selectedBar) return null;
    const filtered = _data.filter((d) => {
      return d.date === selectedBar;
    });

    return filtered[0];
  };

  const getDisplayTime = () => {
    if (data.length === 12) return 'year';
    else if (data.length === 6) return 'month';
    else if (data.length === 30 || data.length === 31) return 'month';
    else return 'day';
  };

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        const newWidth = ref.current.offsetWidth;
        setChartWidth(newWidth);
      }
    };

    // Attach event listener
    window.addEventListener('resize', updateWidth);

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [ref]);

  useEffect(() => {
    setData(formattedData);
  }, [energyData]);

  // const getDate = (d) => new Date(d.date);
  const getPercentage = (d) => d.percentage;

  return chartWidth < 10 ? null : (
    <div
      style={{
        position: 'relative',
        height: '350px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      ref={ref}
    >
      <div style={{ alignSelf: 'flex-start' }}>
        <GraphHeader
          visibleButtons={visibleButtons}
          colorScale={colorScale}
          toggleData={toggleData}
          buttons={buttons as string[]}
          title={t(title || '')}
          selectedBar={getSelectedBarData()}
          unit={detailUnit}
        />
      </div>
      {data.length === 0 || dataInTimeSpan === false ? (
        <div
          style={{ width: chartWidth, height }}
          className={styles.Data__Unavailable}
        >
          <IconWrapper iconName="graph_loading" />
          <svg>
            {' '}
            <text
              x="50%"
              y="50%"
              alignmentBaseline="middle"
              textAnchor="middle"
              className="detail"
              style={{ marginTop: '1em', color: '#687078' }}
            >
              {siteViewStore.loading ? t('loading') : t('errors.no_data')}
            </text>{' '}
          </svg>
        </div>
      ) : (
        <svg
          className="peakChart"
          width={chartWidth}
          height={height}
          style={{
            marginLeft: '0',
            marginTop: '.5em',
            marginRight: '1em',
          }}
          onTouchStart={(event: any) => (
            Haptics.vibrate({ duration: 10 }), handleMouseOver(event)
          )}
          onTouchMove={(event: any) => handleMouseOver(event)}
          onMouseLeave={hideTooltip}
          onTouchEnd={() => (
            hideTooltip(),
            setSelectedBar(null),
            // setVisibleButtons([true, true]),
            Haptics.vibrate({ duration: 10 })
          )}
        >
          {' '}
          <AxisRight
            top={margin.top}
            scale={percentageScale}
            left={xMax + margin.left - 20}
            numTicks={3}
            hideTicks={true}
            stroke= "#F7F7F7"
            strokeWidth={1}
            tickClassName={`${styles.BarStack__Axis} detail`}
            label={'%'}
            labelClassName={`${styles['rotate']} ${styles['rotate__360']}`}
            labelProps={{
              x: -5,
              y: -25,
              fill: '#838B93',
              fontSize: 12,
              fontWeight: 600,
            }}
            tickLabelProps={() => ({
              fontSize: 12,
              textAnchor: 'end',
              fill: '#838B93',
              fontWeight: 300,
              x: 15
            })}
          />
          <AxisLeft
            top={margin.top}
            scale={energyScale}
            left={margin.left}
            numTicks={4}
            hideTicks={true}
            stroke= "#F7F7F7"
            strokeWidth={1}
            tickClassName={`${styles.BarStack__Axis} detail`}
            label={detailUnit}
            labelClassName={`${styles['rotate']} ${styles['rotate__360']}`}
            labelProps={{
              x: -25,
              y: -25,
              fill: '#838B93',
              fontSize: 12,
              fontWeight: 600,
            }}
            tickLabelProps={() => ({
              fontSize: 12,
              textAnchor: 'end',
              fill: '#838B93',
              fontWeight: 300,
            })}
          />
          <Group top={energyScale(0)} left={margin.left}>
            <BarStack<any, string>
              data={data}
              keys={keys()}
              x={getDate}
              xScale={dateScale}
              yScale={energyScale}
              color={(key: any, index) => getBarColor(key, index)}
            >
              {(barStacks: Array<any>) =>
                barStacks.map((barStack: any, stack_i: number) =>
                  barStack.bars.map((bar: any, bar_i: number) => {
                    const barClone = {
                      ...bar,
                      timeStamp: bar.bar.data.date,
                      power_peak: bar?.bar?.data?.power_peak || 0,
                      key: bar.key,
                      value: Number(bar.bar.data[bar.key]),
                      ...(bar?.bar?.data?.power_peak > 0
                        ? {
                            power_peak_value: bar?.bar?.data?.power_peak_value,
                          }
                        : {}),
                    };

                    let barY = bar.y;
                    let barH = bar.height;

                    if (stack_i % 2 === 0) {
                      const community_h = bar.height;
                      barY = yMax - community_h;
                      barH = community_h;
                    }

                    const barValue = bar.bar.data[bar.key];
                    const isPositive = barValue >= 0;
                    const barHeight = Math.abs(bar.height);

                    // Calculate the baseline (y-coordinate for the zero line of the y-axis).
                    const yBase = energyScale(0) - margin.top - margin.top / 2;

                    // Calculate the y position and height based on whether the value is positive or negative.
                    const yPosition = isPositive ? yBase - barHeight : yBase;

                    // Define the animation for the y attribute.
                    const yAnimation = scale.to((s) => {
                      return isPositive
                        ? yPosition - (1 - s) * barHeight
                        : yPosition;
                    });

                    // Define the animation for the height attribute.
                    const heightAnimation = scale.to((s) => s * barHeight);
                    return (
                      <AnimatedBar
                        className={`bar bar-stack-${
                          barStack.index
                        } bar-index-${Math.random()}`}
                        data-x={bar.x}
                        // radius={barStack.index < 1 ? 0 : 2}
                        radius={0}
                        stroke={'white'}
                        data-y={barHeight}
                        data-bar={JSON.stringify(barClone)}
                        topLeft={true}
                        topRight={true}
                        key={`bar-stack-${barStack.index}-${bar.index}`}
                        x={bar.x}
                        width={bar.width}
                        y={yAnimation}
                        height={heightAnimation}
                        fill={bar.height === 0 ? 'transparent' : bar.color}
                        onClick={(event: any) => selectBar(bar)}
                      />
                    );
                  })
                )
              }
            </BarStack>
            {/* <Group top={margin.top} >
            
              <Line
                from={{   x: energyScale(0) }}
                to={{   x: percentageScale(0) }}
                stroke="grey"
                strokeWidth={2}
              />
            </Group> */}
            {/* <Group left={margin.left} top={margin.top}> */}
            {/* Render the LinePath for the battery charge */}

            {currentlySelectedDateType === 'day' && allAboveZero && (
              <Group top={-margin.top * 1.5}>
                <LinePath
                  data={batteryChargeData}
                  x={(d) => dateScaleBattery(getDate(d)) as any} // getDate should return the date in a format that dateScaleBattery can interpret
                  y={(d) => percentageScale(getPercentage(d))}
                  stroke={colorVariables[2]}
                  strokeWidth={2}
                />
              </Group>
            )}
          </Group>
          {/* </Group> */}
          <AxisBottom
            numTicks={5}
            left={margin.left}
            top={yMax + margin.top}
            scale={dateScale}
            tickClassName={`${styles.BarStack__Axis} detail `}
            // tickFormat={(date: any) => formatDate(date, getDisplayTime())}
            tickFormat={(date: any) =>
              formatDate(date, currentlySelectedDateType)
            }
            stroke= "#F7F7F7"
            strokeWidth={1}
            hideTicks={true}
            tickLabelProps={() => ({
              fontSize: 12,
              textAnchor: 'middle',
              fill: '#838B93',
              fontWeight: 300,
            })}
          />{' '}
          {tooltipData && currentlySelectedDateType === 'day' && (
            <g>
              <circle
                cx={tooltipLeft}
                r={4}
                fill={colorVariables[2]}
                stroke={colorVariables[2]}
                strokeWidth={2}
                pointerEvents="none"
                cy={
                  percentageScale((tooltipData as any).percentage) +
                  margin.top -
                  5
                }
              />
            </g>
          )}
        </svg>
      )}
    </div>
  );
};

export default React.memo(CombinedGraph);
