import { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styles from './member-details-parameters.module.scss';

import { useTranslation } from 'react-i18next';
import { MetaDataApiContext } from '@flexo/providers'
import { capitalizeFirstLetter } from '@flexo/general';




export function MemberDetailsParameters(props: any) {

  const { t } = useTranslation();

  const { title, fields, shownFields } = props;

  const { metaDataApiStore } = useContext(MetaDataApiContext)
  
  const {
    Details__Wrapper__Compact,
    Details__Paper,
    Details__Member__Name,
    Details__Member__Content,
    Details__Member__Content__Name,
  } = styles


  function getMemberName(member: any) {
    return `${member?.memberFirstName} ${member?.memberLastName}` || null
  }

  return (
    <div
      key={uuidv4()}
      className={`
      ${Details__Wrapper__Compact}
      `}
    >
      <div className={`${ Details__Paper}`}>
        <p className={ Details__Member__Name}>{ capitalizeFirstLetter( getMemberName(metaDataApiStore?.selectedEntity as any) as string )  }</p>
        <div>
          
        {
          shownFields.map(field => (
            <div className={'flex flex-column'} key={`${field}_${uuidv4()}`}>
              <span className={Details__Member__Content}>
                { ![ 'phone', 'mail', 'postcode' ].includes( field ) ? capitalizeFirstLetter((metaDataApiStore?.selectedEntity as any)?.[field] || '' ) : (metaDataApiStore?.selectedEntity as any)?.[field]  }
              </span>
              <span className={Details__Member__Content__Name}>{ t( fields[field] )}</span>
            </div>
          ) )
        }
      </div>
      </div>
          
      </div>
  )
}

export default MemberDetailsParameters;
