import React, { useContext } from 'react';
import { ParentSize } from '@visx/responsive';
import styles from './graph-container.module.scss';
import {
  BarStackChartWithPowerPeak,
  CombinedGraph,
  NewAreaChart,
  Stackbar,
} from '@flexo/molecules';
import {
  CommunityViewContext,
  KpiApiContext,
  MetaDataApiContext,
  SiteViewContext,
  ThemeContext,
} from '@flexo/providers';
import { Shimmer } from '@flexo/atoms';

const ENV = (import.meta as any).env;

const GraphContainer = React.memo((props: any) => {
  const { ThemeStore } = useContext(ThemeContext);
  const { kpiApiStore, setKpiApiStore } = useContext(KpiApiContext);

  const { site, community } = kpiApiStore;
  const { metaDataApiStore } = useContext(MetaDataApiContext);

  let detailKpiDataSite = site['1hour'] as any;

  if (metaDataApiStore?.entityType?.includes('community')) {
    detailKpiDataSite = community['1hour'] as any;
  }

  const transformData = (data, graphKeys) => {
    return data?.map((entry) => {
      const kpiMap = {};
      entry?.kpi?.forEach((kpiItem) => {
        graphKeys?.forEach((key) => {
          if (kpiItem.name.includes(key)) {
            const formattedKey = `widgets.${props.subtype}.${key}`;
            kpiMap[formattedKey] = kpiItem.value;
          }
        });
      });
      return {
        date: entry.timestamp,
        ...kpiMap,
      };
    });
  };

  const transformedData = transformData(
    detailKpiDataSite ?? detailKpiDataSite,
    props?.graphKeys
  );

  let { colors } = props;
  const { id, type, buttons, dataInTimeSpan = true, graphKeys } = props;
  const getColors = () => {
    try {
      if (typeof props.colors[0] === 'string') {
        if (props.colors?.[0].startsWith('#')) {
          return props.colors;
        }

        return props.colors.map((color) => ThemeStore?.colors[color]);
      }

      if (typeof props.colors[0] === 'object') {
        return props.colors.map(
          (color) =>
            ThemeStore?.colors[color?.color] + (color?.colorShade || '')
        );
      }
    } catch (err) {
      return [...Array(buttons?.length || 0)].map(
        (_el, i) => `#000000${(i + 1) * 10}`
      );
    }
  };
  colors = getColors();

  const graphButtons = graphKeys?.map((key) => {
    return `widgets.${props.subtype}.${key}`;
  });

  const getGraph = () => {

    switch (props.subtype) {
      case 'consumptionStackBar':
        return (
          <Stackbar
            _data={transformedData}
            keys={graphButtons}
            combinedKeys={props?.combinedKeys || null}
            colors={colors}
            title={props.title}
          />
        );
      case 'savingsStackbar':
      case 'savingsStackBar':
        return (
          <Stackbar
            _data={transformedData}
            keys={graphButtons}
            combinedKeys={props?.combinedKeys || null}
            colors={colors}
            title={props.title}
          />
        );
      // case 'batteryGraph':
      //   return (
      //     <CombinedGraph
      //       _data={getGraphData()}
      //       title={props.title}
      //       buttons={buttons}
      //       unit={'W'}
      //       color="primary"
      //       colorVariables={colors}
      //       dataInTimeSpan={dataInTimeSpan}
      //       view={props.view}
      //     />
      //   );

      case 'areachart':
      case 'areaChart':
        return (
          <NewAreaChart
            colors={colors}
            title={props.title}
            data={transformedData}
            keys={graphButtons} // view={props.view}
          />
        );
      default:
        return (
          <Stackbar
            _data={transformedData}
            keys={graphButtons}
            combinedKeys={props?.combinedKeys || null}
            colors={colors}
            title={props.title}
          />
        );
        // return (
        //   <BarStackChartWithPowerPeak
        //     _data={transformedData}
        //     title={props.title}
        //     buttons={graphButtons}
        //     unit={
        //       props.id === 'savings' || props.id === 'savingsStackbarCommunity'
        //         ? '€'
        //         : 'kWh'
        //     }
        //     color="primary"
        //     colorVariables={colors}
        //     dataInTimeSpan={dataInTimeSpan}
        //     view={props.view}
        //   />
        // );
    }
  };

  const renderContent = () => {
    return <div className={styles.Graph}> {getGraph()}</div>;
  };
  return (
    <div
      className={`${styles.GraphContainer} ${props.gradientBorder && styles.GraphContainer__GradientBorder
        } graph-container`}
      style={{ position: 'relative', height: '100%' }}
    >
      <div
        className={
          ENV.VITE_APP_NAME === 'admin'
            ? styles.GraphContainer__Web
            : styles.GraphContainer
        }
      >
        {renderContent()}
      </div>
      {/* {props.view === 'site' && siteViewStore.loading && <Shimmer />}
      {props.view === 'community' && communityViewStore.loading && <Shimmer />} */}
    </div>
  );
});
export default GraphContainer;