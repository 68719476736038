


import { useTranslation } from "react-i18next"
import styles from './../alert.module.scss'
import { IconWrapper } from "@flexo/general";

export default function AlertSessionExpired() {

  const { t } = useTranslation();
  const alertContent = t('alerts.session_expired', { returnObjects: true }) as any;

  return (
    <div id="alert-session-espired" className={ styles.Alert__Session_Expired}>
      <div className={styles.Alert__Title} >
        <IconWrapper iconName="alert" />
        { alertContent.title }
      </div>
      <div className={styles.Alert__Content} >
        {alertContent.description} 
      </div>
    </div>
  )
}