import React, { useContext, useEffect, useRef } from 'react';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import { useTranslation } from 'react-i18next';
import { MetaDataApiContext } from '@flexo/providers';

import styles from './map.module.scss';

export function EsriMapComponent(props) {
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  let { sites } = (metaDataApiStore?.entities as any) || null;

  if (props?.context === 'site' && sites ) {
    sites = sites.filter((site: any) => site.siteId === metaDataApiStore?.selectedEntityId);
  }

  const { t } = useTranslation();
  const mapDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {

    if (!sites) return;

    if (mapDiv.current) {
      const webmap = new ArcGISMap({
        basemap: 'streets-navigation-vector',
      });

      const graphicsLayer = new GraphicsLayer();
      webmap.add(graphicsLayer);

      const simpleMarkerSymbol = {
        type: 'simple-marker',
        color: [0, 158, 98],
        size: 15,
        outline: {
          color: [255, 255, 255], // White
          width: 2,
        },
      };

      const simplePopUp = (site: any) => {

        const siteID = site?.siteId || site?.id || site?.externalId || site?.siteName

        const context = metaDataApiStore?.entityType?.includes('energy')
          ? 'site-energy'
          : metaDataApiStore?.entityType?.includes('economics')
            ? 'site-economics'
            : 'site-economics'
        

        const entityUrl = `${context}/${siteID}`
       
        return (
          {
            type: "custom",
            title: `<b class="font-size-0_9">${site?.name || site?.siteName}</b>`,
            content: function () {
              const div = document.createElement("div");
              div.className = "font-size-0_8";
        
              const idElement = document.createElement("b");
              idElement.className = "font-size-1";
              idElement.textContent = `${t('widgets.map.device_id')}: ${siteID}`;
              div.appendChild(idElement);
        
              const typeElement = document.createElement("div");
              typeElement.className = "font-size-1";
              typeElement.textContent = `${t('widgets.map.site_type')}: ${site?.siteType?.[0]?.flexoSiteType || site?.tipe || 'Unknown Site Type'}`;
              div.appendChild(typeElement);
        
              const linkElement = document.createElement("a");
              linkElement.href = entityUrl;
              linkElement.target = "_self";
              linkElement.textContent = t('widgets.map.go_to_entity');
              div.appendChild(linkElement);
        
              return div;
            },
            location: {
              longitude: site.siteLongitude,
              latitude: site.siteLatitude,
            }, // Puoi specificare una posizione personalizzata per il pop-up
            alignment: "top-center",
            collapseEnabled: false,
            
          }
        )

      };

      sites.forEach((site) => {
        const point = {
          type: 'point',
          longitude: site.siteLongitude,
          latitude: site.siteLatitude,
        };

        const pointGraphic = new Graphic({
          geometry: point,
          symbol: simpleMarkerSymbol,
          // eslint-disable-next-line 
          ...(site.hasOwnProperty('id') || site.hasOwnProperty('siteName') ? { popupTemplate: simplePopUp(site) } : {}),
        } as any);

        graphicsLayer.add(pointGraphic);
      });

      const view = new MapView({
        container: mapDiv.current,
        map: webmap,
        center: [0, 0],
        zoom: 13,
      });

      view.when(() => {
        if (webmap.layers.includes(graphicsLayer)) {
          view.goTo(graphicsLayer.graphics.toArray(), { animate: false });
        } else {
          console.error('Graphics layer is not part of the map');
        }
      }).catch((error) => {
        console.error('Error initializing MapView:', error);
      });

      return () => {
        if (view) {
          view.destroy();
        }
      };
    }
  }, [sites, t]);

  return (
    <div className={styles.Map__Wrapper}>
      <div ref={mapDiv} id="mapViewNode" style={{ width: '100%', height: 216 }} />
    </div>
  );
}
