import React, { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type IAlertContent =
  'ALERT_SCREEN_SIZE' |
  'ALERT_WRONG_LOGIN' |
  'ALERT_WRONG_PARAMS' |
  'ALERT_WRONG_REQUEST_DATA' |
  'ALERT_NO_DATA' |
  'ALERT_ACCOUNT_DELETION' |
  'ALERT_UNEXPECTED_ERROR' |
  'ALERT_NOT_ALLOWED' |
  'ALERT_SESSION_EXPIRED' |
  'ALERT_BANNER_CONFIRM_CHANGES' |
  'BANNER_PASSWORD_RESET_SUCCESS' |
  'BANNER_CONFIRM_CHANGES' |
  'BANNER_CONFIRM_NEW_EMAIL' |
  'BANNER_CONFIRM_CREDENTIALS_CHANGES' |
  'BANNER_CHECK_EMAIL' |
  'BANNER_CREDENTIALS_UPDATED' | 
  'BANNER_LOGOUT_SUCCESS' |
  'BANNER_REGISTRATION_SUCCESS' |
  'BANNER_CONFIRM_CREDENTIALS_CHANGES_SUCCESS' 

export interface IAlert {
  visible?: boolean;
  show?: boolean;
  type?: string | IAlertContent;
  message?: string;
  callback?: () => void;
  
}

export interface AlertProvider {
  alert: IAlert;
}

export type AlertStore = AlertProvider;

export enum AlertTypes {
  SetAlert = 'SET_ALERT',
  ResetAlert = 'RESET_ALERT',
  HideAlert = 'HIDE_ALERT',
  SetInput = 'SET_INPUT',
  SetFooter = 'SET_FOOTER',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [AlertTypes.SetAlert]: {
    value: IAlert;
  };
  [AlertTypes.ResetAlert];
  [AlertTypes.HideAlert];

  [AlertTypes.SetInput]: {
    value: any;
  };
  [AlertTypes.SetFooter]: {
    value: IAlert;
  };
};

export interface ContextValues {
  alertStore: AlertStore;
  setAlertStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
