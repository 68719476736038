
import { widgetsData } from './widgets.constants';

export default [
  // login
  {
    name: 'login',
    active: { default: true },
    visible: { default: true },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },
    layouts: {
      default: {
        lg: [
          {
            i: 'WebLogin',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'WebLogin',
          type: 'web-login',
        },
      ],
    },
  },

  // forgot password
  {
    name: 'forgot-password',
    active: { default: true },
    visible: { default: true },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'ForgotPassword',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'ForgotPassword',
          type: 'forgot-password',
        },
      ],
    },
  },

  // register
  {
    name: 'register',
    active: { default: true },
    visible: { default: false },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'Register',
            x: 0,
            y: 12,
            w: 12,
            h: 8,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'Register',
          type: 'register',
        },
      ],
    },
  },

  // account creation
  {
    name: 'account-creation',
    active: { default: false },
    visible: { default: false },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'AccountCreation',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'AccountCreation',
          type: 'accountCreation-web',
        },
      ],
    },
  },
  // password resetn
  {
    name: 'password-reset',
    active: { default: true },
    visible: { default: false },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'PasswordReset',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'PasswordReset',
          type: 'PasswordReset-web',
        },
      ],
    },
  },
  // register by invite
  {
    name: 'register-by-invite',
    active: { default: true },
    visible: { default: false },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'RegisterByInvite',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'RegisterByInvite',
          type: 'RegisterByInvite',
        },
      ],
    },
  },

  // Authenticated modules

  {
    name: 'communities',
    needsAuth: true,
    active: { default: true },
    visible: { default: false },
    sideBar: { default: false },
    pullToRefresh: { default: false },

    apis: {
      default: [
        'CommunitiesResolver',
        'CommunitiesOverviewResolver',
        'EntitiesResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communitiesTable',
            x: 0,
            y: 11,
            w: 12,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
      enduser: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communitiesTable',
            x: 0,
            y: 11,
            w: 12,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'communities',
            navigation: false,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
        },
        {
          type: 'communitiesTable',
          id: 'communitiesTable',
        },
      ],
      enduser: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'communities',
            navigation: false,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
        },
      ],
    },
  },

  // community

  {
    name: 'community',
    needsAuth: true,
    active: { default: true },
    visible: { default: false },
    sideBar: { default: false },
    pullToRefresh: { default: false },

    apis: {
      default: [
        'CommunityResolver',
        'MembersResolver',
        'EntitiesResolver',
        'KpiCommunityResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communityBenefitsCard',
            x: 0,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'communityEnergyFlowsCard',
            x: 4,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'membersTable',
            x: 0,
            y: 42,
            w: 9,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'CommunityDetailsParameters',
            x: 10,
            y: 42,
            w: 3,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community',
            breadCrumbs: true,
            navigation: true,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
        },

        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'communityBenefitsCard',
          id: 'communityBenefitsCard',
          totalValue: 'total_benefits',
          icon: 'euro',
          descriptionCallback: 'communityEconomics',
          descriptionType: 'label',
          description: 'widgets.savingsCard.description.default',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'communityEnergyFlowsCard',
          id: 'communityEnergyFlowsCard',
          icon: 'euro',
          descriptionCallback: 'communityEnergy',
          descriptionType: 'label+value',
          description: [
            {
              key: 'widgets.communityEnergyFlowsCard.description.default.consumption',
              value: 'consumption',
            },
            {
              key: 'widgets.communityEnergyFlowsCard.description.default.production',
              value: 'production',
            },
            {
              key: 'widgets.communityEnergyFlowsCard.description.default.shared',
              value: 'total_shared',
            },
          ],
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
        },

        {
          type: 'membersTable',
          id: 'membersTable',
        },
        {
          type: 'CommunityDetailsParameters',
          id: 'CommunityDetailsParameters',
          data: widgetsData.communityCommunityDetailsParameters,
        },
      ],
    },
  },

  // member

  {
    name: 'member',
    needsAuth: true,
    active: { default: true },
    visible: { default: false },
    sideBar: { default: false },
    pullToRefresh: { default: false },

    apis: {
      default: ['MemberResolver', 'MembersResolver', 'EntitiesResolver'],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'paymentsTable',
            x: 0,
            y: 12,
            w: 9,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'MemberDetailsParameters',
            x: 10,
            y: 12,
            w: 3,
            h: 60,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'memberSitesList',
            x: 10,
            y: 72,
            w: 3,
            h: 82,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'members',
            breadCrumbs: true,
            navigation: true,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
        },
        {
          type: 'paymentsTable',
          id: 'paymentsTable',
        },
        {
          type: 'MemberDetailsParameters',
          id: 'MemberDetailsParameters',
          data: {
            title: 'widgets.memberDetailsParameters.title',
            fields: {
              communityId: 'widgets.memberDetailsParameters.communityId',
              externalId: 'widgets.memberDetailsParameters.externalId',
              communityCode: 'widgets.memberDetailsParameters.communityCode',
              communityName: 'widgets.memberDetailsParameters.communityName',
              communityType: 'widgets.memberDetailsParameters.communityType',
              startDate: 'widgets.memberDetailsParameters.startDate',
              memberId: 'widgets.memberDetailsParameters.memberId',
              clientId: 'widgets.memberDetailsParameters.clientId',
              name: 'widgets.memberDetailsParameters.name',
              address: 'widgets.memberDetailsParameters.address',
              city: 'widgets.memberDetailsParameters.city',
              postcode: 'widgets.memberDetailsParameters.postcode',
              phone: 'widgets.memberDetailsParameters.phone',
              email: 'widgets.memberDetailsParameters.email',
            },
            shownFields: [
              'memberId',
              'address',
              'city',
              'postcode',
              'phone',
              'email',
            ],
          },
        },

        {
          type: 'memberSitesList',
          id: 'memberSitesList',
        },
      ],
    },
  },

  {
    name: 'site-economics',
    active: { default: true },
    visible: { default: false },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },

    needsAuth: true,
    apis: {
      default: ['EntitiesResolver', 'KpiSiteResolver'],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'savingsCard',
            x: 0,
            y: 13, // 4
            w: 12,
            h: 27, // 8
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              minHeight: '100%',
              height: '100%',
            },
          },
          {
            i: 'savingsStackBar',
            x: 0,
            y: 42,
            w: 12,
            h: 38, // Reduced height to better fit the layout
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              minHeight: '100%',
              height: '100%',
            },
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'site-economics',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'savingsCard',
          id: 'savingsCard',
          totalValue: 'total_benefits',
          icon: 'savings',
          descriptionType: 'label',
          descriptionCallback: 'SiteTotalEstimatedBenefits',
          description: 'widgets.savingsCard.description.default',
        },

        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'savingsStackBar',
          id: 'savingsStackBar',
          graphKeys: ['revenues'],
          colors: ['primary'],
          title: 'widgets.savingsStackBar.title',
        },
      ],
    },
  },
  // site energy
  {
    name: 'site-energy',
    active: { default: true },
    visible: { default: false },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },
    needsAuth: true,

    apis: {
      default: ['EntitiesResolver', 'KpiSiteResolver'],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'selfSufficiencyCard',
            x: 0,
            y: 13,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              paddingRight: 8,
            },
          },
          {
            i: 'sharedEnergyCard',
            x: 4,
            y: 13,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',

              paddingRight: 8,
              paddingLeft: 8,
            },
          },
          {
            i: 'pollutionCard',
            x: 9,
            y: 13,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',

              paddingLeft: 8,
            },
          },
          {
            i: 'consumptionStackBar',
            x: 0,
            y: 42, // Start after pollutionCard with spacing
            w: 6,
            h: 53, // Further reduced height for better fit
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',

              paddingRight: 8,
            },
          },
          {
            i: 'productionStackBar',
            x: 7,
            y: 42, // Start after consumptionStackBar with spacing
            w: 6,
            h: 53, // Reduced height to match
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              paddingLeft: 8,
            },
          },
          {
            i: 'selfConsumptionStackBar',
            x: 0,
            y: 95, // Start after consumptionStackBar with spacing
            w: 12,
            h: 53, // Reduced height to match
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
            },
          },
        ],
      },
      administrator: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',

                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',

                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                backgroundColor: 'var( --hive-pureWhite )',
                height: '100%',
                paddingLeft: 8,

              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42, // Start after pollutionCard with spacing
              w: 12,
              h: 53, // Further reduced height for better fit
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',

                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42, // Start after pollutionCard with spacing
              w: 12,
              h: 53, // Further reduced height for better fit
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },

            {
              i: 'siteStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important'
              },
            },
            {
              i: 'siteStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                backgroundColor: 'var( --hive-pureWhite )',
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important'
              },
            },

            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',

                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',

                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',

                paddingLeft: 8,
              },
            },

            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92, // Start after pollutionCard with spacing
              w: 6,
              h: 53, // Further reduced height for better fit
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92, // Start after pollutionCard with spacing
              w: 6,
              h: 53, // Further reduced height for better fit
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',

                paddingLeft: 8,
              },
            },

            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 156, // Start after pollutionCard with spacing
              w: 12,
              h: 53, // Further reduced height for better fit
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 13,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',

                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 13,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,

              style: {
                height: '100%',

                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 13,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,

              style: {
                height: '100%',

                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42, // Start after pollutionCard with spacing
              w: 6,
              h: 53, // Further reduced height for better fit
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42, // Start after pollutionCard with spacing
              w: 6,
              h: 53, // Further reduced height for better fit
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',

                paddingLeft: 8,
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 106, // Start after pollutionCard with spacing
              w: 6,
              h: 53, // Further reduced height for better fit
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 6,
              y: 106, // Start after pollutionCard with spacing
              w: 6,
              h: 53, // Further reduced height for better fit
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'site-energy',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'selfSufficiencyCard',
          id: 'selfSufficiencyCard',
          totalValue: 'self_sufficiency_rate',
          icon: 'self_sufficiency',
          descriptionType: 'label',
          description: 'widgets.selfSufficiencyCard.description.prosumer',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'sharedEnergyCard',
          id: 'sharedEnergyCard',
          totalValue: 'total_shared',
          icon: 'shared_energy',
          descriptionType: 'label+value|label+value',
          description: [
            {
              key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
              value: 'consumption',
            },
            {
              key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
              value: 'production',
            },
          ],
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'pollutionCard',
          id: 'pollutionCard',
          totalValue: 'co2_offset_kgs',
          trees: 'co2_offset_trees',
          icon: 'pollution',
          descriptionValue: 'co2_offset_kgs',
          descriptionType: '{{trees}}',
          description: {
            line_1: 'widgets.pollutionCard.description.prosumer.line_1',
            line_2: 'widgets.pollutionCard.description.prosumer.line_2',
          },
        },

        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          context: 'site'
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'consumptionStackBar',
          id: 'consumptionStackBar',
          graphKeys: ['self_consumption_direct', 'from_battery', 'from_shared', 'from_grid'],
          colors: [
            { color: 'self-consumption', colorShade: '' },
            { color: 'battery', colorShade: '' },
            { color: 'consumption', colorShade: '' },
            { color: 'consumption', colorShade: '60' },
          ],
          title: 'widgets.consumptionStackBar.title',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          env: 'web',
          subtype: 'productionStackBar',
          id: 'productionStackBar',
          graphKeys: [
            'self_consumption_direct',
            'production_to_battery',
            'to_shared',
            'to_grid',
          ],
          colors: [
            { color: 'self-consumption', colorShade: '' },
            { color: 'battery', colorShade: '' },
            { color: 'production', colorShade: '' },
            { color: 'production', colorShade: '60' },
          ],
          title: 'widgets.productionStackBar.title',
        },
        {
          env: 'web',
          id: 'areaChartSelfConsumptionSite',
          title: 'widgets.areaChartSelfConsumptionSite.title',
          type: 'areachart',
          subtype: 'areachart',
          gradientBorder: false,
          colors: ['consumption', 'production', 'self-consumption'],
          graphKeys: [
            'consumption',
            'production',
            'self_consumption',
          ],
        },
      ],
      administrator: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.consumer.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.sharedEnergyCard.description.consumer.key_2',
                value: 'production',
              },
            ],
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_kgs',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            graphKeys: ['from_shared', 'from_grid'],
            combinedKeys: ['from_shared', 'from_grid'],
            colors: [
              { color: 'consumption', colorShade: '' },
              { color: 'consumption', colorShade: '60' },
            ],
            title: 'widgets.consumptionStackBar.title',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'production',
              },
            ],
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_kgs',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site'
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            graphKeys: [
              'to_shared',
              'to_grid',
            ],
            combinedKeys: [
              'to_shared',
              'to_grid',
            ],
            colors: [
              { color: 'production', colorShade: '' },
              { color: 'production', colorShade: '60' },
            ],
            title: 'widgets.productionStackBar.title',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
          },


        {
          env: 'web',
          type: 'statsOverview',
          subtype: 'statsOverview',
          id: 'siteStatsOverviewConsumption',
          totalValue: 'consumption',
          color: 'consumption',
          view: 'site',
          graphDataKeys: [
            {
              key: 'from_grid',
              label: 'widgets.siteStatsOverviewConsumption.from_grid',
            },
            {
              key: 'self_consumption_direct',
              label: 'widgets.siteStatsOverviewConsumption.self_consumption_direct',
            },
          ],

          graphColors: ['consumption', 'self-consumption' ],
          title: 'widgets.siteStatsOverviewConsumption.title',

          values: [
            {
              label: 'widgets.siteStatsOverviewConsumption.self_consumption_direct',
              totalPercentage: 'self_consumption_direct',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: 'self-consumption'
                },
              ],
            },
            {
              label: 'widgets.siteStatsOverviewConsumption.from_grid',
              totalPercentage: 'from_grid',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: '#F0F0F0'
                },
                {
                  value: 'from_grid',
                  color: 'consumption'
                }
              ],
            },
          ],
        },
        {
          env: 'web',
          type: 'statsOverview',
          subtype: 'statsOverview',
          id: 'siteStatsOverviewProduction',
          totalValue: 'consumption',
          color: 'consumption',
          view: 'site',
          graphDataKeys: [
            {
              key: 'to_grid',
              label: 'widgets.siteStatsOverviewProduction.to_grid',
            },
            {
              key: 'self_consumption_direct',
              label: 'widgets.siteStatsOverviewProduction.self_consumption_direct',
            }
          ],

          graphColors: ['production', 'self-consumption' ],
          title: 'widgets.siteStatsOverviewProduction.title',

          values: [
            {
              label: 'widgets.siteStatsOverviewProduction.self_consumption_direct',
              totalPercentage: 'self_consumption_direct',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: 'self-consumption'
                },
              ],
            },
            {
              label: 'widgets.siteStatsOverviewProduction.to_grid',
              totalPercentage: 'to_grid',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: '#F0F0F0'
                },
                {
                  value: 'to_grid',
                  color: 'production'
                }
              ],
            },
          ],
        },

          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'production',
              },
            ],
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_kgs',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
          },

          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            graphKeys: ['self_consumption_direct', 'from_shared', 'from_grid'],
            combinedKeys: ['from_shared', 'from_grid'],
            colors: [
              { color: 'self-consumption', colorShade: '' },
              { color: 'consumption', colorShade: '' },
              { color: 'consumption', colorShade: '60' },
            ],
            title: 'widgets.consumptionStackBar.title',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            graphKeys: [
              'self_consumption_direct',
              'to_shared',
              'to_grid',
            ],
            combinedKeys: [
              'to_shared',
              'to_grid',
            ],
            colors: [
              { color: 'self-consumption', colorShade: '' },
              { color: 'production', colorShade: '' },
              { color: 'production', colorShade: '60' },
            ],
            title: 'widgets.productionStackBar.title',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: [
              'consumption',
              'production',
              'self_consumption',
            ],
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            icon: 'self_sufficiency',
            descriptionType: 'label',
            description: 'widgets.selfSufficiencyCard.description.prosumage',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumage.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumage.key_2',
                value: 'production',
              },
            ],
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_kgs',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumage.line_1',
              line_2: 'widgets.pollutionCard.description.prosumage.line_2',
            },
          },

          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            graphKeys: ['self_consumption_direct', 'from_battery', 'from_shared', 'from_grid'],
            colors: [
              { color: 'self-consumption', colorShade: '' },
              { color: 'battery', colorShade: '' },
              { color: 'consumption', colorShade: '' },
              { color: 'consumption', colorShade: '60' },
            ],
            title: 'widgets.consumptionStackBar.title',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            graphKeys: [
              'self_consumption_direct',
              'production_to_battery',
              'to_shared',
              'to_grid'
            ],
            colors: [
              { color: 'self-consumption', colorShade: '' },
              { color: 'battery', colorShade: '' },
              { color: 'production', colorShade: '' },
              { color: 'production', colorShade: '60' },
            ],
            title: 'widgets.productionStackBar.title',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'site',
            buttons: [
              'widgets.batteryChart.self_consumption',
              'widgets.batteryChart.soc',
            ], //FIX ME

            colors: [
              { color: 'self-consumption', colorShade: '' },
              { color: 'battery', colorShade: '' },
            ],

            db_fields: ['battery_net_flow', 'soc_battery'],
          },
          {
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumption.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'site',

            graphKeys: [
              'consumption',
              'production',
              'self_consumption',
              
            ], //FIX ME

            colors: [
              'consumption',
              'production',
              'self-consumption',
            ],
          },
        ],
      },
    },
  },

  // community economics

  {
    name: 'community-economics',
    active: { default: true },
    visible: { default: false },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },

    needsAuth: true,
    apis: {
      default: [
        'EntitiesResolver',
        'KpiCommunityResolver',
        'CommunityResolver',
        'CommunityRankingResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communitySavingsCard',
            x: 0,
            y: 13, // 4
            w: 6,
            h: 27, // 8
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
              paddingRight: 8,
            },
          },
          {
            i: 'communityEarningsCard',
            x: 7,
            y: 13, // 4
            w: 6,
            h: 27, // 8
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
              paddingLeft: 8
            },
          },
          {
            i: 'savingsStackBar',
            x: 0,
            y: 42,
            w: 12,
            h: 52, // Reduced height to better fit the layout
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              minWidth: '98.7%',
            },
          },

          {
            i: 'horizontalChart',
            x: 0,
            y: 100, // Start after pollutionCard with spacing
            w: 12,
            h: 53, // Further reduced height for better fit
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
            },
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community-economics',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'communitySavingsCard',
          id: 'communitySavingsCard',
          totalValue: 'total_benefits',
          icon: 'savings',
          descriptionType: 'label',
          description: 'widgets.communitySavingsCard.description.default',
          descriptionCallback: 'communityTotalEstimatedBenefits'
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communityEarningsCard',
          id: 'communityEarningsCard',
          totalValue: 'revenues',
          icon: 'house',
          descriptionType: 'ranking[label+value]',
          description: 'widgets.communityEarningsCard.description.default',
          style: {
            paddingLeft: 8,
          },
        },
        {
          env: 'web',
          type: 'graphs',
          gradientBorder: false,
          subtype: 'savingsStackBar',
          id: 'savingsStackBar',
          graphKeys: ['revenues'],
          colors: ['primary'],
          title: 'widgets.savingsStackBar.title',
        },

        
        {
          type: 'horizontalChart',
          gradientBorder: false,
          subtype: 'horizontalChart',
          id: 'horizontalChart',
          context: 'economics',
          rankingKpi: 'revenues',
          rankingKpiName: 'widgets.horizontalChart.economics.revenues',
          graphKeys: [
            'revenues'
          ],
          buttons: [
            'widgets.horizontalChart.economics.benefits'
          ],
          colors: [
            { color: 'economics', colorShade: '' }
          ],
          title: 'widgets.horizontalChart.economics.title',
        }
      ],
    },
  },

  // Community energy
  {
    name: 'community-energy',
    active: { default: true },
    visible: { default: false },
    footer: { default: false },
    header: { default: false },
    datePicker: { default: false },
    pullToRefresh: { default: false },
    needsAuth: true,
    apis: {
      default: [
        'EntitiesResolver',
        'KpiCommunityResolver',
        'CommunityResolver',
        'CommunityRankingResolver'
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communityStatsOverviewConsumption',
            x: 0,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
              marginLeft: -16,
              paddingLeft: 16,
              minWidth: '33.3%!important'
            },
          },
          {
            i: 'communityStatsOverviewProduction',
            x: 4,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              backgroundColor: 'var( --hive-pureWhite )',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 11,
            w: 4,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
            style: {
              backgroundColor: 'var( --hive-pureWhite )',
              height: '100%',
              marginRight: -16,
              minWidth: '33.3%!important'
            },
          },
          {
            i: 'totalSitesCard',
            x: 0,
            y: 63,
            w: 3,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'communitySharedEnergyCard',
            x: 3,
            y: 63,
            w: 3,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'communitySelfSufficiencyCard',
            x: 6,
            y: 63,
            w: 3,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'communityPollutionCard',
            x: 9,
            y: 63,
            w: 3,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'consumptionStackBar',
            x: 0,
            y: 92, // Start after pollutionCard with spacing
            w: 6,
            h: 53, // Further reduced height for better fit
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'productionStackBar',
            x: 7,
            y: 92, // Start after pollutionCard with spacing
            w: 6,
            h: 48, // Further reduced height for better fit
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'batteryChart',
            x: 0,
            y: 156, // Start after pollutionCard with spacing
            w: 6,
            h: 47, // Further reduced height for better fit
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },

          {
            i: 'areaChartSelfConsumption',
            x: 6,
            y: 156, // Start after pollutionCard with spacing
            w: 6,
            h: 47, // Further reduced height for better fit
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          
          {
            i: 'horizontalChart',
            x: 0,
            y: 204, // Start after pollutionCard with spacing
            w: 12,
            h: 53, // Further reduced height for better fit
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
            },
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community-energy',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
        },
        {
          env: 'web',
          type: 'statsOverview',
          subtype: 'statsOverview',
          id: 'communityStatsOverviewConsumption',
          totalValue: 'consumption',
          color: 'consumption',
          view: 'community',
          graphDataKeys: [
            {
              key: 'from_grid',
              label: 'widgets.communityStatsOverviewConsumption.from_grid',
            },
            {
              key: 'self_consumption_direct',
              label: 'widgets.communityStatsOverviewConsumption.self_consumption_direct',
            },
            {
              key: 'self_consumption_battery',
              label: 'widgets.communityStatsOverviewConsumption.self_consumption_battery',
            },
            {
              key: 'self_consumption_wallbox',
              label: 'widgets.communityStatsOverviewConsumption.self_consumption_wallbox',
            }
          ],

          graphColors: ['consumption', 'self-consumption', 'battery', '#EBC1DC'],
          title: 'widgets.communityStatsOverviewConsumption.title',

          values: [
            {
              label: 'widgets.communityStatsOverviewConsumption.self_consumption_direct',
              totalPercentage: 'self_consumption_direct',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: 'self-consumption'
                },
              ],
            },
            {
              label: 'widgets.communityStatsOverviewConsumption.self_consumption_battery',
              totalPercentage: 'self_consumption_battery',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: '#F0F0F0',
                },
                {
                  value: 'self_consumption_battery',
                  color: 'battery'
                }
              ],
            },
            {
              label: 'widgets.communityStatsOverviewConsumption.from_grid',
              totalPercentage: 'from_grid',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: '#F0F0F0'
                },
                {
                  value: 'self_consumption_battery',
                  color: "#F0F0F0"
                },
                {
                  value: 'from_grid',
                  color: 'consumption'
                }
              ],
            },
          ],
        },
        {
          env: 'web',
          type: 'statsOverview',
          subtype: 'statsOverview',
          id: 'communityStatsOverviewProduction',
          totalValue: 'consumption',
          color: 'consumption',
          view: 'community',
          graphDataKeys: [
            {
              key: 'to_grid',
              label: 'widgets.communityStatsOverviewProduction.to_grid',
            },
            {
              key: 'self_consumption_direct',
              label: 'widgets.communityStatsOverviewProduction.self_consumption_direct',
            },
            {
              key: 'production_to_battery',
              label: 'widgets.communityStatsOverviewProduction.production_to_battery',
            },
            {
              key: 'production_to_wallbox',
              label: 'widgets.communityStatsOverviewProduction.production_to_wallbox',
            }
          ],

          graphColors: ['production', 'self-consumption', 'battery', '#EBC1DC'],
          title: 'widgets.communityStatsOverviewProduction.title',

          values: [
            {
              label: 'widgets.communityStatsOverviewProduction.self_consumption_direct',
              totalPercentage: 'self_consumption_direct',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: 'self-consumption'
                },
              ],
            },
            {
              label: 'widgets.communityStatsOverviewProduction.production_to_battery',
              totalPercentage: 'production_to_battery',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: '#F0F0F0',
                },
                {
                  value: 'production_to_battery',
                  color: 'battery'
                }
              ],
            },
            {
              label: 'widgets.communityStatsOverviewProduction.to_grid',
              totalPercentage: 'to_grid',
              progress: [
                {
                  value: 'self_consumption_direct',
                  color: '#F0F0F0'
                },
                {
                  value: 'production_to_battery',
                  color: "#F0F0F0"
                },
                {
                  value: 'to_grid',
                  color: 'production'
                }
              ],
            },
          ],
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'totalSitesCard',
          id: 'totalSitesCard',
          totalValue: 'sites_count',
          icon: 'sites',
          descriptionType: 'totalSitesCard',
          description: [
            {
              key: 'widgets.totalSitesCard.description.default.consumer',
              value: 'consumer',
            },
            {
              key: 'widgets.totalSitesCard.description.default.producer',
              value: 'producer',
            },
            {
              key: 'widgets.totalSitesCard.description.default.prosumage',
              value: 'prosumage',
            },
            {
              key: 'widgets.totalSitesCard.description.default.prosumer',
              value: 'prosumer',
            },
          ],
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communitySelfSufficiencyCard',
          id: 'communitySelfSufficiencyCard',
          totalValue: 'self_sufficiency_rate',
          icon: 'self_sufficiency',
          descriptionType: 'label',
          description:
            'widgets.communitySelfSufficiencyCard.description.default',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communitySharedEnergyCard',
          id: 'communitySharedEnergyCard',
          totalValue: 'total_shared',
          icon: 'shared_energy',
          descriptionType: 'label',
          description: 'widgets.communitySharedEnergyCard.description.default',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communityPollutionCard',
          id: 'communityPollutionCard',
          totalValue: 'co2_offset_kgs',
          trees: 'co2_offset_trees',
          icon: 'pollution',
          descriptionValue: 'co2_offset_kgs',
          descriptionType: '{{trees}}',
          description: {
            line_1: 'widgets.pollutionCard.description.prosumer.line_1',
            line_2: 'widgets.pollutionCard.description.prosumer.line_2',
          },
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'consumptionStackBar',
          id: 'consumptionStackBar',
          graphKeys: ['self_consumption_direct', 'from_battery', 'from_shared', 'from_grid'],
          combinedKeys: [ 'from_shared', 'from_grid' ],
          colors: [
            { color: 'self-consumption', colorShade: '' },
            { color: 'battery', colorShade: '' },
            { color: 'consumption', colorShade: '' },
            { color: 'consumption', colorShade: '60' },
          ],
          title: 'widgets.consumptionStackBar.title',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          env: 'web',
          subtype: 'productionStackBar',
          id: 'productionStackBar',
          graphKeys: [
            'self_consumption_direct',
            'production_to_battery',
            'to_shared',
            'to_grid',
          ],
          combinedKeys: [ 
            'to_shared',
            'to_grid', ],
          colors: [
            { color: 'self-consumption', colorShade: '' },
            { color: 'battery', colorShade: '' },
            { color: 'production', colorShade: '' },
            { color: 'production', colorShade: '60' },
          ],
          title: 'widgets.productionStackBar.title',
        },

        {
          id: 'batteryChart',
          title: 'widgets.batteryChart.title',
          type: 'batteryChart',
          gradientBorder: false,
          context: 'community',
          buttons: [
            'widgets.batteryChart.self_consumption',
          ], //FIX ME

          colors: [
            { color: 'self-consumption', colorShade: '' },
          ],

          db_fields: ['battery_net_flow'],
        },

        {
          env: 'web',
          id: 'areaChartSelfConsumption',
          title: 'widgets.areaChartSelfConsumptionCommunity.title',
          type: 'areaChart',
          subtype: 'areaChart',
          gradientBorder: false,
          view: 'community',
          graphKeys: [
            'consumption',
            'production',
            'self_consumption',
            
          ], //FIX ME

          colors: [
            'consumption',
            'production',
            'self-consumption',
          ],
        },
        {
          type: 'horizontalChart',
          gradientBorder: false,
          subtype: 'horizontalChart',
          id: 'horizontalChart',
          context: 'energy',
          rankingKpi: 'consumption',
          rankingKpiName: 'widgets.horizontalChart.energy.consumption',
          graphKeys: [
            'self_consumption_direct', 'self_consumption_battery', 'from_shared', 'from_grid'
          ],
          buttons: [
            'widgets.horizontalChart.energy.self_consumption_direct',
            'widgets.horizontalChart.energy.self_consumption_battery',
            'widgets.horizontalChart.energy.from_shared',
            'widgets.horizontalChart.energy.from_grid',
          ],
          colors: [
            { color: 'self-consumption', colorShade: '' },
            { color: 'battery', colorShade: '' },
            { color: 'consumption', colorShade: '' },
            { color: 'consumption', colorShade: '60' }
          ],
          title: 'widgets.horizontalChart.energy.title',
        },
      ],
    },
  },
];

/**
 * {
            type: 'statsOverview',
            subtype: 'statsOverview',
            id: 'statsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            graphDataKeys: [
              {
                key: 'grid_withdrawn',
                label: 'widgets.statsOverview.consumption.from_network',
              },
              {
                key: 'self_consumption_direct',
                label: 'widgets.statsOverview.consumption.self_consumption',
              },
            ],

            graphColors: ['consumption', 'self-consumption'],
            title: 'widgets.statsOverview.consumption.title',

            values: [
              {
                label: 'widgets.statsOverview.consumption.self_consumption',
                totalPercentage: 'self_consumption_rate',
                progress: [
                  {
                    value: 'self_consumption_rate',
                    color: 'self-consumption',
                  },
                ],
              },
              {
                label: 'widgets.statsOverview.consumption.from_network',
                totalPercentage: 'consumption_from_grid_rate',
                progress: [
                  {
                    value: 'self_consumption_rate',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'consumption_from_grid_rate',
                    color: 'consumption',
                  },
                ],
              },
            ],
          },
 */