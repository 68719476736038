import { useContext, useEffect, useState, useRef } from 'react';
import { Button, PageHeader, Textfield } from '@flexo/atoms';
import styles from './register.module.scss';
import { ModulesContext, ModulesTypes, UserContext } from '@flexo/providers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Browser } from '@capacitor/browser';
import { Keyboard } from '@ionic-native/keyboard';
import axios from 'axios';
import { IconWrapper } from '@flexo/general';

const ENV = (import.meta as any).env;
const PRIVACY_POLICY_URL = ENV.VITE_PRIVACY_POLICY_URL;
const TERMS_URL = ENV.VITE_TERMS_CONDITIONS_URL;

export enum RegisterStage {
  EnterEmailAndCode = 1,
  SetPassword = 2,
}

export function RegisterOOBcode(props) {
  const {
    Register__Header,
    Register__Header__Title,
    Register__Header__Subtitle,
    Register__Inputs,
    Register__Footer,
    Register__Footer__Register,
    Register__Footer__Register__Button,
    Register__Inputs__PasswordConditions,
    Register__Footer__Submit,
    Register__Privacy,
    Register__Privacy__Checkbox,
    Register__Privacy__Copy,
  } = styles;
  const { containedLabel } = props;

  const { t } = useTranslation();

  const PRIVACY_AND_TERMS = t('pages.register.privacy_and_terms', {
    returnObjects: true,
  });

  const [currentStage, setCurrentStage] = useState<RegisterStage>(
    RegisterStage.EnterEmailAndCode
  );
  const [registerData, setRegisterData] = useState({
    email: '',
    OOBcode: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({
    email: false,
    OOBcode: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [passwordValidation, setPasswordValidation] = useState({
    lengthValid: false,
    hasNumber: false,
    hasSpecialChar: false,
    hasUpperCase: false,
    hasLowerCase: false,
    passwordsMatch: false,
  });
  const [inputType, setInputType] = useState({
    newPassword: 'password',
    confirmPassword: 'password',
  });

  const [icon, setIcon] = useState({
    newPassword: 'visibility',
    confirmPassword: 'visibility',
  });

  const { setModulesStore } = useContext(ModulesContext);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setRegisterData({ ...registerData, [name]: value });
    if (name === 'newPassword') {
      validatePassword(value);
    }
    if (name === 'confirmPassword') {
      setPasswordValidation((prev) => ({
        ...prev,
        passwordsMatch: registerData.newPassword === value,
      }));
    }
  };

  const isEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    const lengthValid = password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);

    setPasswordValidation({
      lengthValid,
      hasNumber,
      hasSpecialChar,
      hasUpperCase,
      hasLowerCase,
      passwordsMatch: password === registerData.confirmPassword,
    });
  };
  const togglePasswordVisibility = (name: string) => {
    setInputType((prevState) => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : 'password',
    }));
    setIcon((prevIcon) => ({
      ...prevIcon,
      [name]:
        prevIcon[name] === 'visibility' ? 'hide-visibility' : 'visibility',
    }));
  };

  const onNext = () => {
    if (currentStage === RegisterStage.EnterEmailAndCode) {
      setCurrentStage(RegisterStage.SetPassword);
    }
  };

  const completeRegistration = async () => {
    try {
      const response = await axios.post(
        'https://gateway-staging.flexo.energy/auth/v1/register',
        null,
        {
          params: {
            email: registerData.email,
            activation_code: registerData.OOBcode,
            password: registerData.newPassword,
            tenant_id: 'internal-foj18',
          },
        }
      );

      if (response.status === 200) {
        setModulesStore({
          type: ModulesTypes.ActivateLoginModule,
        });
      } else {
        console.error('Failed to register:', response.data);
      }
    } catch (error) {
      console.error('Error registering:', error);
    }
  };

  const handleBlur = (event: any) => {
    const { name, value } = event.target;
    if (name === 'email') {
      !isEmail(value)
        ? setErrors({ ...errors, email: true })
        : setErrors({ ...errors, email: false });
      setErrors({ ...errors, email: !isEmail(value) });
    }
  };

  const openLink = async (url: string) => {
    Browser.open({ url });
  };

  useEffect(() => {
    Keyboard.disableScroll(true);
  }, []);

  return (
    <div className={styles.Register}>
      <div className={Register__Header}>
        <PageHeader
          title={t('pages.register.title')}
          subtitle={t('pages.register.subtitle')}
        />
      </div>

      <div className={styles.Register__Content}>
        {currentStage === RegisterStage.EnterEmailAndCode && (
          <>
            <div className={Register__Inputs}>
              <div className={styles.Register_Inputs_Input}>
                <Textfield
                  name="email"
                  label={t('pages.register.user_email') as string}
                  value={registerData.email}
                  placeholder={t('pages.register.user_email_placeholder') as string}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  containedLabel={containedLabel}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                  description={
                    t('pages.register.user_email_description') as string
                  }
                />
              </div>
              <div className={styles.Register_Inputs_Input}>
                <Textfield
                  name="OOBcode"
                  label={t('pages.register.oob_code') as string}
                  value={registerData.OOBcode}
                  placeholder={
                    t('pages.register.oob_code_placeholder') as string
                  }
                  onChange={handleInputChange}
                  containedLabel={containedLabel}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                  description={
                    t('pages.register.oob_code_description') as string
                  }
                />
              </div>
            </div>
            <div className={Register__Privacy}>
              <input
                type="checkbox"
                onChange={() => setTermsAccepted(!termsAccepted)}
                className={Register__Privacy__Checkbox}
                checked={termsAccepted}
              />
              <span className={`${styles.Register__Privacy__Copy} detail`}>
                {PRIVACY_AND_TERMS[0]}{' '}
                <span
                  style={{ fontSize: '1.05em' }}
                  className={'color-primary-500'}
                  onClick={() => openLink(TERMS_URL)}
                >
                  {PRIVACY_AND_TERMS[1]}
                </span>{' '}
                {PRIVACY_AND_TERMS[2]}{' '}
                <span
                  style={{ fontSize: '1.05em' }}
                  className={'color-primary-500'}
                  onClick={() => openLink(PRIVACY_POLICY_URL)}
                >
                  {PRIVACY_AND_TERMS[3]}
                </span>
              </span>
            </div>
          </>
        )}

        {currentStage === RegisterStage.SetPassword && (
          <div className={Register__Inputs}>
            <div className={styles.Register_Inputs_Input}>
              <Textfield
                name="email"
                label={t('pages.register.user_email') as string}
                value={registerData.email}
                placeholder={t('pages.register.user_email_placeholder') as string}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={errors.email}
                containedLabel={containedLabel}
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </div>
            <div className={styles.Register_Inputs_Input}>
              <Textfield
                name="newPassword"
                label={t('pages.register.password') as string}
                value={registerData.newPassword}
                placeholder={t('pages.register.password_placeholder') as string}
                onChange={handleInputChange}
                type={inputType.newPassword} // Updated to use inputType state
                containedLabel={containedLabel}
                icon={icon.newPassword} // Add icon for visibility toggle
                onIconClick={() => togglePasswordVisibility('newPassword')} // Toggle visibility
                error={errors.newPassword}
                errorDescription={
                  errors.newPassword
                    ? 'Please review the password criteria'
                    : ''
                }
              />
            </div>
            <div className={Register__Inputs__PasswordConditions}>
              <div>
                {Object.entries(passwordValidation)
                  .filter(([key]) => key !== 'passwordsMatch')
                  .map(([key, value]) => (
                    <span
                      key={key}
                      className={`paragraph ${
                        value ? 'valid' : 'invalid'
                      } styles.ForgottenPassword__Conditions`}
                      style={{ display: 'flex' }}
                    >
                      <span style={{ marginTop: '3px', marginRight: '8px' }}>
                        <IconWrapper
                          iconName={
                            value ? 'check_encircled' : 'cross_encircled'
                          }
                        />
                      </span>
                      {t(`pages.forgotten_password.validation.${key}`)}
                    </span>
                  ))}
              </div>
            </div>{' '}
            <div className={styles.Register_Inputs_Input}>
              <Textfield
                name="confirmPassword"
                label={t('pages.register.confirm_password') as string}
                value={registerData.confirmPassword}
                placeholder={t('pages.register.confirm_password_placeholder') as string}
                onChange={handleInputChange}
                type={inputType.confirmPassword} // Updated to use inputType state
                containedLabel={containedLabel}
                icon={icon.confirmPassword} // Add icon for visibility toggle
                onIconClick={() => togglePasswordVisibility('confirmPassword')} // Toggle visibility
                error={errors.confirmPassword}
                errorDescription={
                  errors.confirmPassword
                    ? 'Please review the password criteria'
                    : ''
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className={Register__Footer}>
        <Button
          onClick={
            currentStage === RegisterStage.EnterEmailAndCode
              ? onNext
              : completeRegistration
          }
          label={
            currentStage === RegisterStage.EnterEmailAndCode
              ? t('pages.register.next')
              : t('pages.register.submit')
          }
          variant="primary"
          color="secondary"
          disabled={
            (currentStage === RegisterStage.EnterEmailAndCode &&
              (!registerData.email ||
                !registerData.OOBcode ||
                !termsAccepted ||
                !isEmail(registerData.email))) ||
            (currentStage === RegisterStage.SetPassword &&
              (!registerData.newPassword ||
                !registerData.confirmPassword ||
                Object.values(passwordValidation).some((v) => !v)))
          }
        />
        {currentStage === RegisterStage.EnterEmailAndCode && (
          <span className={Register__Footer__Register}>
            {t('pages.register.already_registered')}
            <button
              className={Register__Footer__Register__Button}
              onClick={() =>
                setModulesStore({ type: ModulesTypes.ActivateLoginModule })
              }
            >
              {t('pages.register.go_to_login')}
            </button>
          </span>
        )}
      </div>
    </div>
  );
}

export default RegisterOOBcode;
