import React, { useContext, useEffect, useState } from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { CalendarContext, KpiApiContext, ThemeContext } from '@flexo/providers';
import { formatDate } from '../helpers';
import styles from './battery-chart.module.scss';

function CombinedBatteryChart(props: any) {
  const { t } = useTranslation();
  const { ThemeStore } = useContext(ThemeContext);
  const { kpiApiStore } = useContext(KpiApiContext);
  const { calendarStore } = useContext(CalendarContext);

  const [unit, setUnit] = useState('');
  const [chartData, setChartData] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState<string | null>(null);
  
    const rawData = kpiApiStore?.[props?.context]?.['1hour'] || [];

  
  // Handle mouse hover to set active bar
  const handleMouseMove = (index) => {
      setActiveIndex(`${index}`); // Only set active index if no bar is selected


  };

  const handleMouseLeave = () => {
    setActiveIndex(null); // Reset active index on mouse leave
  };

  // Click handler to set the selected bar
  const handleBarClick = (index) => {
    setActiveIndex(`${index}`); // Only update if a new bar is selected
    
  };

  useEffect(() => {

    // Crea i dati per Recharts
    const _chartData = rawData.map((item: any) => {
      const formattedData: any = {
        timestamp: formatDate(item.timestamp, calendarStore.currentlySelectedDateType),
      };

      props?.db_fields.forEach((field, index) => {
        const kpiValue = item?.kpi.find((datum: any) => datum.name === field);
        if (kpiValue) {
          formattedData[field] = kpiValue.value;
        }
      });

      return formattedData;
    });

    setChartData(_chartData);

    // Imposta l'unità di misura
    if (props?.db_fields?.length > 0) {
      const firstField = props?.db_fields[0];
      const firstItem = rawData?.[0];
      if (firstItem) {
        const kpiValue = firstItem.kpi.find((datum: any) => datum.name === firstField);
        setUnit(kpiValue?.unit || '');
      }
    }
  }, [kpiApiStore?.[props?.context]?.['1hour'], props.db_fields, calendarStore.currentlySelectedDateType]);

  return (
    <div
      id="CombinedBatteryChart__Wrapper"
      className={styles.CombinedBatteryChart__Wrapper}
    >
      <div className={styles.CombinedBatteryChart__Title}>{t(props.title)}</div>
      <div className={styles.CombinedBatteryChart__Buttons__Wrapper}>
        {props.buttons.map((button: any, index: number) => {
          if (
            chartData.length > 0
          ) {

            if (index > 0 && (calendarStore.currentlySelectedDateType !== 'day')) {
              return ''
            }

            return (
              <div
                key={`BatteryChart__${index}__button__${button}`}
                className={styles.CombinedBatteryChart__Button}
              >
                {t(button)}
                {((activeIndex && chartData?.[activeIndex]?.[props?.db_fields?.[index]]) &&
                  (
                  index === 0
                    ? ` ${chartData[activeIndex][props.db_fields[index]]} ${unit} `
                    : ` ${chartData[activeIndex][props.db_fields[index]]}`
                ))
                  
                }
                <div
                  className={styles.CombinedBatteryChart__Button__Underline}
                  style={{
                    backgroundColor: `${
                      ThemeStore?.colors[props.colors[index].color]
                    }${props.colors[index].colorShade}`,
                  }}
                ></div>
              </div>
            );
          } else {
            return null;
          }
        })}
        
        <div
          key={`BatteryChart__TimeStamp__Button`}
          className={styles.CombinedBatteryChart__Button}
        >
          {(activeIndex && rawData?.[activeIndex]?.timestamp) && formatDate(rawData?.[activeIndex]?.timestamp, calendarStore.currentlySelectedDateType)}

        </div>

      </div>

      {chartData.length > 0 && (
        <div className={styles.CombinedBatteryChart__Chart__Wrapper}>
          <div
            className={`${styles.CombinedBatteryChart__Chart__Unit} ${styles.CombinedBatteryChart__Chart__Unit__Left}`}
          >
            {unit}
          </div>
          <ResponsiveContainer width="100%" height={236}>
            <ComposedChart
              data={chartData}
              margin={{ top: 20, right: 20, left: 0, bottom: 0 }}
              onMouseLeave={handleMouseLeave}
            >
              {/* <CartesianGrid stroke="#f5f5f5" vertical={false} /> */}
              <XAxis dataKey="timestamp" tick={{ fontSize: 10, fontFamily: 'Flexo light' }} axisLine={false}
            tickLine={false}/>
              <YAxis
                yAxisId="left"
                orientation="left"
                tick={{ fontSize: 12, fontFamily: 'Flexo light' }}
                axisLine={false}
                tickLine={false}
                domain={['auto', 'auto']}
              />
              {calendarStore.currentlySelectedDateType === 'day' && props?.context === 'site' && (
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  tick={{ fontSize: 12, fontFamily: 'Flexo light' }}
                  domain={[0, 100]}
                  axisLine={false}
            tickLine={false}
                />
              )}
              {/* <Tooltip /> */}
              <Bar
                yAxisId="left"
                dataKey={ props.db_fields[0] }
                fill={`${ThemeStore?.colors[props.colors[0].color]}${props.colors[0].colorShade}`}
                barSize={20}
                onClick={(_, i) => handleBarClick(i )} // Click handler for bar
                onMouseMove={(_, i) => handleMouseMove(i )} // Handle mouse hover
              />
              {calendarStore.currentlySelectedDateType === 'day' && props?.context === 'site' && (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey={ props.db_fields[1] }
                  stroke={`${ThemeStore?.colors[props.colors[1].color]}${props.colors[1].colorShade}`}
                  strokeWidth={2}
                  dot={false}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          <div
            className={`${styles.CombinedBatteryChart__Chart__Unit} ${styles.CombinedBatteryChart__Chart__Unit__Right}`}
          >
            {calendarStore.currentlySelectedDateType === 'day' && props?.context === 'site' ? '%' : ''}
          </div>
        </div>
      )}
    </div>
  );
}

export default CombinedBatteryChart;
