/* eslint no-sequences: "off" */

import React, { useEffect } from 'react';
import { useContext, useMemo, useRef, useState } from 'react';
import { BarRounded, BarStack } from '@visx/shape';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { useSpring, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { Haptics } from '@capacitor/haptics';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import {
  CalendarContext,
  CommunityViewContext,
  KpiApiContext,
  SiteViewContext,
  ThemeContext,
} from '@flexo/providers';
import styles from './stackbar-graph.module.scss';
import { GraphHeader } from '@flexo/atoms';
import { formatDate } from '../helpers';
import { IconWrapper } from '@flexo/general';
import { color } from 'html2canvas/dist/types/css/types/color';

const ENV = (import.meta as any).env;

export const BarChartStyles = {
  titleCol: {
    padding: 0,
    marginLeft: '1em',
  },
  titleRow: {
    padding: 0,
    marginTop: '0em',
    marginLeft: '1em',
  },
};

export type IBarStack = {
  width?: number;
  height?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
  dataInTimeSpan?: boolean;
  _data?: Array<any>;
  color?: string;
  unit?: string;
  title?: string | null;
  buttons: Array<string>;
  colorVariables?: any;
  view?: 'site' | 'community';
  env?: string;
};

const BarStackChartWithPowerPeak: React.FC<IBarStack> = ({
  width = 440,
  height = 300,
  events = false,
  margin = { top: 35, right: 0, bottom: 0, left: 45 },
  _data = [],
  title = null,
  buttons,
  colorVariables,
  view,
  unit,
  env,
  dataInTimeSpan = true,
}: IBarStack) => {
  const AnimatedBar = animated(BarRounded);

  const { t } = useTranslation();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const ref: any = useRef(null);
  const [layoutWidth, setLayoutWidth] = useState(0);
  const [data, setData]: any = useState(_data);
  const [toggle, setToggle]: any = useState(false);
  const [selectedBar, setSelectedBar] = useState<string | null>(null);
  const [allowToggle, setAllowToggle]: any = useState(false);
  const [visibleButtons, setVisibleButtons]: any = useState([true, true, true]);
  const [isVibrating, setIsVibrating] = useState(false);
  const { siteViewStore } = useContext(SiteViewContext);
  const { communityViewStore } = useContext(CommunityViewContext);
  const { ThemeStore } = useContext(ThemeContext);

  const { calendarStore } = useContext(CalendarContext);
  const [chartWidth, setChartWidth] = useState(window.innerWidth);

  const { kpiApiStore, setKpiApiStore } = useContext(KpiApiContext);

  const { site } = kpiApiStore;
  const detailKpiDataSite = site['1hour'] as any;

  // const { detailUnit } = siteViewStore;

  const keys = () =>
    _data?.length > 0 && data.length > 0
      ? (Object.keys(_data[0]).filter(
          (d) => d !== 'date' && d !== 'power_peak_value'
      ) as [])
        .sort((a, b) => {
          return buttons.indexOf(a) - buttons.indexOf(b);
        })
      : [];

  const energyTotals = keys().reduce(
    (prev: any, next: string) => [
      ...prev,
      ...data.map((item: any) => item[next]),
    ],
    []
  );

  // accessors
  const getDate = (d: any) => d.date;

  // bounds
  const xMax = Math.max(
    0,
    (layoutWidth || chartWidth) - margin.left - margin.right
  );
  const yMax = height - margin.top - 100;
  // scales
  const dateScale = scaleBand<string>({
    domain: data.map(getDate),
    padding: 0.2,
    range: [0, xMax - margin.right - margin.left],
  });

  const maxValue = Math.max(
    ...data.map((obj) =>
      buttons?.reduce((sum, button) => sum + parseFloat(obj[button] || 0), 0)
    )
  );

  const energyScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, maxValue],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: buttons,
    range: colorVariables,
  });

  const { scale } = useSpring({
    from: { scale: 0 },
    to: { scale: toggle ? 0 : 1 },
  });
  function toggleData(key: string, index: number) {
    setToggle(true);

    if (selectedBar !== null) {
      setSelectedBar(null);
      setVisibleButtons(buttons?.map(() => true));
      setData(_data);
      setTimeout(() => setToggle(false), 500);
      return;
    }

    // Calculate the new state of the current button
    const newButtonState = !visibleButtons[index];

    // If the new state is 'false' and there would be less than 1 button left as 'true', then return early
    if (!newButtonState && visibleButtons.filter((val) => val).length <= 1) {
      setTimeout(() => setToggle(false), 500);
      return;
    }

    let filteredData = _data;

    if (!newButtonState) {
      filteredData = data.map((timestamp: any) => ({ ...timestamp, [key]: 0 }));
    } else {
      filteredData = data.map((datum: any, datum_i: number) => ({
        ...datum,
        [key]: _data[datum_i][key],
      }));
    }

    setVisibleButtons((prev: Array<boolean>) =>
      prev.map((value: boolean, _i: number) =>
        _i === index ? newButtonState : value
      )
    );

    setData(filteredData);
    setTimeout(() => setToggle(false), 500);
  }

  // If you don't want to use a Portal, simply replace `TooltipInPortal` below with
  // `Tooltip` or `TooltipWithBounds` and remove `containerRef`
  const { TooltipInPortal } = useTooltipInPortal({
    // use TooltipWithBounds
    detectBounds: true,
    // when tooltip containers are scrolled, this will correctly update the Tooltip position
    scroll: true,
  });

  const singleTooltip = (event: any, bar: any) => {
    const coords: any = localPoint(event.target.ownerSVGElement, event);
    const parent = event.target.closest('svg');

    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: parent.getBoundingClientRect().y - bar.height - 20,
      tooltipData: {
        ...bar,
        value: bar.bar.data[bar.key],
        ...(bar?.bar?.data?.power_peak > 0
          ? { power_peak_value: bar?.bar?.data?.power_peak_value }
          : {}),
      },
    });

    setTimeout(() => hideTooltip(), 2000);
  };

  const handleMouseOver = async (event: any) => {
    const coords: any = localPoint(
      event?.target?.ownerSVGElement || event.target,
      event
    );
    let bar: any = null;

    try {
      const overedElement = (document as any).elementFromPoint(
        event.changedTouches[0].clientX,
        event.changedTouches[0].clientY
      );
      const parent = event.target.closest('.peakChart');

      // const dot = document.createElement('div');
      // dot.setAttribute('style', `height: 2px;
      // width: 2px;
      // background-color: red;
      // z-index: 9999;
      // top: ${parent.getBoundingClientRect().y + yMax + margin.top - 5}px;
      // left: ${event.changedTouches[0].clientX}px;
      // position: absolute`);

      // dot.setAttribute( 'id', 'dot' );
      // document.body.appendChild(dot);

      // setTimeout( () => document.body.removeChild(dot), 1 )

      const pseudoOveredElement = (document as any).elementFromPoint(
        event.changedTouches[0].clientX,
        parent.getBoundingClientRect().y + yMax + margin.top - 1
      );

      const highlightedElement =
        pseudoOveredElement?.classList?.value?.includes('visx-bar-rounded')
          ? pseudoOveredElement
          : overedElement;

      if (highlightedElement?.attributes?.['data-bar']) {
        bar = JSON.parse(highlightedElement.attributes['data-bar'].value);
      }

      if (bar) {
        selectBar(bar);
        showTooltip({
          tooltipLeft: coords.x,
          tooltipTop: parent.getBoundingClientRect().y - bar.height - 20,
          tooltipData: bar,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  function getGreyScaledColor(color: string) {
    let _color = color;

    if (color?.includes('#')) {
      const red = parseInt(color.substr(1, 2), 16);
      const green = parseInt(color.substr(3, 2), 16);
      const blue = parseInt(color.substr(5, 2), 16);
      // Calculate the grayscale value
      const grayscale = Math.round(0.299 * red + 0.587 * green + 0.114 * blue);
      // Convert the grayscale value back to a hex string
      const grayscaleHex = grayscale.toString(16).padStart(2, '0');
      // Return the grayscale hex string with '#' prefix
      // if (color === '#A6AFB0' || color === '#4F6367') {
      //   _color = '#' + grayscaleHex + grayscaleHex + grayscaleHex + '50';
      // } else
      _color = '#' + grayscaleHex + grayscaleHex + grayscaleHex + '33';
    }
    return _color;
  }

  function getBarColor(key: any, index: any) {
    const rangeIndex = keys().findIndex((_key) => _key === key);
    if (selectedBar && selectedBar !== data[index].date) {
      return getGreyScaledColor((colorScale.range() as any)[rangeIndex]);
    }

    return colorScale.range()[rangeIndex];
  }
  function selectBar(bar: any) {
    const barDate = bar?.bar?.data?.date || bar?.timeStamp;
    const isShowingPeak =
      (bar?.bar?.data?.['power_peak'] || bar?.power_peak) !== 0 &&
      (bar?.bar?.data?.['power_peak'] || bar?.power_peak) !== undefined;

    if (selectedBar === bar?.bar?.data?.date) {
      setSelectedBar(null);
      setVisibleButtons([true, true]);
    } else {
      if (isShowingPeak) {
        setVisibleButtons([false, true]);
        if (isVibrating === false) {
          setIsVibrating(true);
          Haptics.vibrate({ duration: 10 });
          setTimeout(() => setIsVibrating(false), 500);
        }
      } else {
        // setVisibleButtons([true, false]);
      }

      setSelectedBar(barDate || null);
    }
  }

  // useEffect(() => {
  //   setTimeout(async () => {
  //     setAllowToggle(true);
  //   }, 500);
  // }, []);

  const getSelectedBarData = () => {
    if (!selectedBar) return null;
    const filtered = data.filter((d) => {
      return d.date === selectedBar;
    });
    return filtered[0];
  };

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        const newWidth = ref.current.offsetWidth;
        setChartWidth(newWidth);
      }
    };

    // Attach event listener
    window.addEventListener('resize', updateWidth);

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [ref]);

  useEffect(() => {
    setData(_data);
  }, [detailKpiDataSite]);

  // MELINA TEST ME PLEASE :'(

  useEffect(() => {

    const _width = ref?.current?.parentNode?.getBoundingClientRect()?.width || 0;

    try { 
      if (chartWidth !== _width) {
        setChartWidth(_width);
      }
    } catch (err: any) {
      setChartWidth(window.innerWidth);
      console.warn(err);
    }
  }, [ref?.current?.parentNode]);

  return chartWidth < 10 ? null : (
    <div
      style={{ position: 'relative', height: (env === 'web' ? '100%' : 350), width: chartWidth - 12 }}
      ref={ref}
    >
      <GraphHeader
        visibleButtons={visibleButtons}
        colorScale={colorScale}
        toggleData={toggleData}
        buttons={buttons}
        title={t(title || 'Title')}
        selectedBar={getSelectedBarData()}
        unit={unit as any}
      />
      {/* {siteViewStore.loading && (
        <div
          style={{ width: chartWidth - 24, height }}
          className={styles.Data__Unavailable}
        >
          <IconWrapper iconName="product_icons" />
          <text
            x="50%"
            y="50%"
            alignmentBaseline="middle"
            textAnchor="middle"
            className="detail"
            style={{ marginTop: '1em' }}
          >
            {t('loading')}
          </text>{' '}
        </div>
      )} */}
      {/* {!siteViewStore.loading &&
      (data.length === 0 || dataInTimeSpan === false) ? (
        <div
          style={{ width: chartWidth, height }}
          className={styles.Data__Unavailable}
        >
          <IconWrapper iconName="graph_loading" />
          <svg>
            {' '}
            <text
              x="50%"
              y="50%"
              alignmentBaseline="middle"
              textAnchor="middle"
              className="detail"
              style={{ marginTop: '1em', color: '#687078' }}
            >
              {view === 'site'
                ? siteViewStore.loading
                  ? t('loading')
                  : t('errors.no_data')
                : view === 'community' && communityViewStore.loading
                ? t('loading')
                : t('errors.no_data')}
            </text>{' '}
          </svg>
        </div>
      ) : ( */}
      {/* {!siteViewStore.loading && ( */}
      <svg
        className="peakChart"
        width={chartWidth - margin.left - margin.right}
        height={height}
        style={{
          marginLeft: '.25em',
          marginTop: '.5em',
        }}
        onTouchStart={(event: any) => (
          Haptics.vibrate({ duration: 10 }), handleMouseOver(event)
        )}
        onTouchMove={(event: any) => handleMouseOver(event)}
        onMouseLeave={hideTooltip}
        onTouchEnd={() => (
          hideTooltip(),
          setSelectedBar(null),
          // setVisibleButtons([true, true]),
          Haptics.vibrate({ duration: 10 })
        )}
      >
        {' '}
        <AxisLeft
          top={margin.top}
          scale={energyScale}
          left={margin.left}
          numTicks={4}
          hideTicks={true}
          strokeWidth={1}
          tickClassName={`${styles.BarStack__Axis} detail`}
          label={unit}
          stroke="#F7F7F7"
          labelClassName={`${styles['rotate']} ${styles['rotate__360']}`}
          labelProps={{
            x: -30,
            y: -25,
            fill: '#838B93',
            fontSize: 12,
            fontWeight: 600,
          }}
          tickLabelProps={() => ({
            fontSize: 12,
            textAnchor: 'end',
            fill: '#838B93',
            fontWeight: 300,
          })}
          tickFormat={(val: any) =>
            new Intl.NumberFormat(ENV?.VITE_APP_LOCALE || 'it-CH', {
              ...(val > 99
                ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                : { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            }).format(val)
          }
        />
        <Group top={margin.top} left={margin.left} width={xMax}>
          <BarStack<any, string>
            data={data}
            keys={keys()}
            x={getDate}
            xScale={dateScale}
            yScale={energyScale}
            color={(key: any, index) => getBarColor(key, index) as any}
          >
            {(barStacks: Array<any>) =>
              barStacks.map((barStack: any, stack_i: number) =>
                barStack.bars.map((bar: any, bar_i: number) => {
                  const barClone = {
                    ...bar,
                    timeStamp: bar.bar.data.date,
                    power_peak: bar?.bar?.data?.power_peak || 0,
                    key: bar.key,
                    value: bar.bar.data[bar.key],
                    ...(bar?.bar?.data?.power_peak > 0
                      ? {
                          power_peak_value: bar?.bar?.data?.power_peak_value,
                        }
                      : {}),
                  };

                  let barY = bar.y;
                  let barH = bar.height;

                  if (stack_i % 2 === 0) {
                    const community_h = bar.height;
                    barY = yMax - community_h;
                    barH = community_h;
                  }

                  const barHeight = yMax - (barY ?? 0);
                  return (
                    <AnimatedBar
                      className={`bar bar-stack-${
                        barStack.index
                      } bar-index-${Math.random()}`}
                      data-x={bar.x}
                      radius={barStack.index < barStack.length - 1 ? 0 : 3} // Adjusted here
                      stroke={'white'}
                      data-y={barHeight}
                      data-bar={JSON.stringify(barClone)}
                      topLeft={true}
                      topRight={true}
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      width={bar.width}
                      y={scale.to(
                        (s) => yMax - (allowToggle ? s : 1) * barHeight
                      )}
                      height={scale.to((s) => (allowToggle ? s : 1) * barH)}
                      fill={bar.height === 0 ? 'transparent' : bar.color}
                      onClick={(event: any) => (
                        selectBar(bar), singleTooltip(event, bar)
                      )}
                    />
                  );
                })
              )
            }
          </BarStack>
        </Group>
        <AxisBottom
          numTicks={5}
          left={margin.left}
          top={yMax + margin.top}
          scale={dateScale}
          tickClassName={`${styles.BarStack__Axis} detail `}
          stroke="#F7F7F7"
          strokeWidth={1}
          // tickFormat={(date: any) => formatDate(date, getDisplayTime())}
          tickFormat={(date: any) => formatDate(date, 'day')}
          hideTicks={true}
          tickLabelProps={() => ({
            fontSize: 12,
            textAnchor: 'middle',
            fill: '#838B93',
            fontWeight: 300,
          })}
        />{' '}
      </svg>
    </div>
  );
};

export default React.memo(BarStackChartWithPowerPeak);
