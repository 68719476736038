
import styles from './table.module.scss';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter, TableSortLabel } from '@mui/material';

import { DataContext, FiltersContext, MetaDataApiContext, ModulesContext } from '@flexo/providers';
import { useContext, useEffect, useState } from 'react';
import { navigateToCommunity } from '@flexo/atoms';
import { IconWrapper, capitalizeFirstLetter } from '@flexo/general';

import { v4 as uuidv4 } from 'uuid';
import parse from 'html-react-parser';



export function CommunitiesTable(props: any) {


  const { t } = useTranslation();
  const { title, no_data_text, rows } = t(`widgets.tables.CommunitiesTable`, { returnObjects: true }) as any;
  const columns = rows

  const { metaDataApiStore, setMetaDataApiStore } = useContext<any>(MetaDataApiContext);
  const { filtersStore } = useContext<any>(FiltersContext);
  const { setModulesStore } = useContext<any>(ModulesContext);
  const [storedRows, setStoredRows] = useState<Array<any>>([]);
  const [rowsOrder, setRowsOrder] = useState(['community_name', 'asc']);

  const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

  function loadDataSources() {

    const dataSource = (filtersStore?.filteredCommunities || metaDataApiStore.communities || []).sort((a, b) => a.communityName > b.communityName ? 1 : -1);

    if (JSON.stringify(dataSource) !== JSON.stringify(storedRows)) {
      setStoredRows(dataSource);
    }
  }

  useEffect(() => {
    loadDataSources()
  }, [
    filtersStore?.filteredCommunities, metaDataApiStore?.communities
  ])

  useEffect(() => {
    loadDataSources()
  }, [])


  function sortRows(columnId: string) {

    try {

      let _midArray = [...storedRows];
      let direction = 'asc';

      if (rowsOrder[0] === columnId && rowsOrder[1] === 'asc') {
        direction = 'desc';
      }

      switch (columnId) {
        case 'community_name':
          _midArray = _midArray.sort((a, b) => ((direction === 'asc' ? a.community_name > b.community_name : a.community_name < b.community_name) ? 1 : -1));
          break;
        case 'sites':
          _midArray = _midArray.sort((a, b) => ((direction === 'asc' ? a.sites > b.sites : a.sites < b.sites) ? 1 : -1));
          break;
        case 'members':
          _midArray = _midArray.sort((a, b) => ((direction === 'asc' ? a.members > b.members : a.members < b.members) ? 1 : -1));
          break;
        case 'start_date':
          _midArray = _midArray.sort((a, b) => {
            const d1 = new Date(a.start_Date)
            const d2 = new Date(b.start_Date)
            return ((direction === 'asc' ? d1 > d2 : d1 < d2) ? 1 : -1)
          });
          break;
      }

      setRowsOrder([columnId, direction]);
      setStoredRows(_midArray);

    } catch (err: any) {
      console.warn(err)
    }
  }




  return (

    storedRows?.length > 0 ?
      <div
        className={`${styles.Table} ${styles.Table__Flex} ${styles.Table__Communities} mb-3`}
        key={Math.floor(Math.random() * 100)}
      >

        <TableContainer component={Paper} className={``}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns && columns.map((column: any, column_i: number) => (
                  <TableCell
                    className={`
                ${column.sortable ? styles.Table__Header__Cell__Sortable : styles.Table__Header__Cell__Static}
              ${(column.sortable && rowsOrder[0] === column.field) ? styles.Table__Header__Cell__Active : styles.Table__Header__Cell}`
                    }
                    key={`communities_table_${column_i}`}>
                    <TableSortLabel
                      active={column.sortable && rowsOrder[0] === column.field}
                      direction={rowsOrder[0] === column.field && rowsOrder[1] === 'asc' ? 'asc' : 'desc'}
                      onClick={() => column.sortable ? sortRows(column.field) : null}

                      className={`${styles.Table__Header__Cell}`}
                      IconComponent={() => (
                        (rowsOrder[0] === column.field)
                          ? rowsOrder[1] === 'asc'
                            ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                            : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                          : <div style={{ height: 20, width: 19 }}></div>
                      )
                      }
                    >
                      <div > {column.title}</div>
                    </TableSortLabel>

                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>


              {(storedRows || []).map((row) => (
                <TableRow
                  key={row?.communityName || Math.floor(Math.random() * 100)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  style={{ height: 100 }}
                  onClick={() => navigateToCommunity(row, setMetaDataApiStore, setModulesStore)}
                  className={`${ styles.Table__Body__Row__Clickable }`}
                >

                  <TableCell
                    className={`${!columns?.[0]?.sortable === true && styles.Table__Cell__Static}`}
                    key={`communities_table_row_${row.communityName}_cell_0__${uuidv4()}`}
                    component="th"
                    scope="row"
                  >
                    <div className={`${styles.Table__Cell__Community__Name}`}>
                      <span>{capitalizeFirstLetter(row.communityName)}</span>
                      <label>{capitalizeFirstLetter(row.communityCode)}</label>
                    </div>
                  </TableCell>

                  <TableCell key={`communities_table_row_${row.communityName}_cell_1`} component="th" scope="row">
                    <div
                      className={`${!columns?.[0]?.sortable === true && styles.Table__Cell__Static}`}
                    >
                      <p className={`${styles.Table__Cell__Community__SitesCount}`} > {row?.sites || 0}</p>
                      <div className={'flex w-100 flex-row'}>
                        {(row?.composition || [])
                          .map((entry: any, key_i: number, array: any) => (
                            <p className={`${styles.Table__Cell__Community__Composition}`} key={key_i + '_composition'}>
                              <strong>{entry.quantity}</strong> {capitalize(entry.type)}{key_i < array.length - 1 && <span style={{ padding: '0px 5px', textAlign: 'center' }}>|</span>}
                            </p>
                          ))}
                      </div>
                    </div>

                  </TableCell>

                  <TableCell
                    className={`${!columns?.[1]?.sortable === true && styles.Table__Cell__Static}`}
                    key={`communities_table_row_${row.communityName}_cell_2`}
                    component="th"
                    scope="row"
                  >
                    <div>
                      <p className={`${styles.Table__Cell__Community__MembersCount}`} > {row?.members || 0}</p>

                    </div>

                  </TableCell>

                  <TableCell
                    className={`${!columns?.[3]?.sortable === true && styles.Table__Cell__Static}`}
                    key={`communities_table_row_${row.communityName}_cell_4`}
                    component="th"
                    scope="row"
                  >
                    <div>
                      <div>
                        <p className={`${styles.Table__Cell__Community__StartDate}`} > {row?.startDate || 0}</p>
                        {/* <p className={ `${styles.Table__Cell__Community__Status}`} > {row?.status || false }</p> */}

                      </div>
                    </div>
                  </TableCell>


                </TableRow>
              ))}

            </TableBody>

            <TableFooter >
              <TableRow>
                <TableCell
                  align="left"
                  className={`${(columns[0].sortable && styles.Table__Footer__Cell__Clickable)}`}
                  
                >

                  <div
                    onClick={() => columns[0].sortable ? sortRows('community_name') : null}
                    className={`${(columns[0].sortable && rowsOrder[0] === 'community_name') ? styles.Table__Footer__Cell__Active : styles.Table__Footer__Cell}`}
                  >
                    <span>
                    { parse( t(`widgets.tables.utils.${(metaDataApiStore?.communitiesOverview?.totalCommunities || 0) === 1 ? 'community' : 'communities'}`, { communities: (metaDataApiStore?.communitiesOverview?.totalCommunities || 0) }) )}
                    </span>
                  
                  {
                    (rowsOrder[0] === 'community_name')
                    ? rowsOrder[1] === 'asc'
                      ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                      : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                      : <div style={{ height: 20, width: 19 }}></div>
                  }
                  </div>
                
                  
                </TableCell>
                <TableCell
                  align="left"
                  className={`${(columns[1].sortable && styles.Table__Footer__Cell__Clickable)}`}

                >
                  <div
                    onClick={() => columns[1].sortable ? sortRows('sites') : null}
                    
                  className={`${(columns[1].sortable && rowsOrder[0] === 'sites') ? styles.Table__Footer__Cell__Active : styles.Table__Footer__Cell}`}
                  >
                    <span>
                    { parse( t(`widgets.tables.utils.${(metaDataApiStore?.communitiesOverview?.totalSites || 0) === 1 ? 'site' : 'sites'}`, { sites: (metaDataApiStore?.communitiesOverview?.totalSites || 0) }) )}
                    </span>
                  {
                    (rowsOrder[0] === 'sites')
                    ? rowsOrder[1] === 'asc'
                      ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                      : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                      : <div style={{ height: 20, width: 19 }}></div>
                  }
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  className={`${(columns[2].sortable && styles.Table__Footer__Cell__Clickable)}`}
                >
                  <div
                    onClick={() => columns[2].sortable ? sortRows('members') : null}
                    className={`${(columns[2].sortable && rowsOrder[0] === 'members') ? styles.Table__Footer__Cell__Active : styles.Table__Footer__Cell}`}
                  >
                    <span>
                      {parse( t(`widgets.tables.utils.${(metaDataApiStore.communitiesOverview?.totalMembers || 0) === 1 ? 'member' : 'members'}`, { members: (metaDataApiStore?.communitiesOverview?.totalMembers || 0) }))}
                    </span>
                  {
                    (rowsOrder[0] === 'members')
                    ? rowsOrder[1] === 'asc'
                      ? <IconWrapper iconName='chevron-up' className={`${styles.Table__TH__Icon}`} />
                      : <IconWrapper iconName='chevron-down' className={`${styles.Table__TH__Icon}`} />
                      : <div style={{ height: 20, width: 19 }}></div>
                  }
                  </div>
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
              </TableFooter>
            
          </Table>
        </TableContainer>

      </div>
      : <div
        className={`${styles.Table} mb-3`}
        key={Math.floor(Math.random() * 100)}
      >
        <div className={`${styles.Table__Title}`}>
          {title}
        </div>
        <div className={`${styles.Table__NO_DATA}`}> {no_data_text}</div>
      </div>

  );
}

export default CommunitiesTable;
