import { useContext, useState } from 'react';
import { Button, PageHeader, Textfield } from '@flexo/atoms';
import { FirebaseService, IconWrapper, REGEX, httpService } from '@flexo/general';
import styles from './log-in.module.scss';
import { AlertContext, AlertTypes, DataContext, DataStoreTypes, ModulesContext, ModulesTypes, UserContext } from '@flexo/providers';
import { Player } from '@lottiefiles/react-lottie-player';
import { UserTypes } from '@flexo/providers';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

const ENV = (import.meta as any).env;
const firebaseService = new FirebaseService();

let _authData = {
  email: '',
  password: '',
  createPassword: '',
}

/* eslint-disable-next-line */
export interface WebLogInProps {
  logInData: any;
  setLogInFlow: any;
  bgColor?: string;
}

export function WebLogIn({ setLogInFlow }: WebLogInProps) {

  const {
    LogIn__Inputs,
    LogIn__Footer,
    LogIn__Footer__Button,
  } = styles;



  const { userStore, setUserStore } = useContext(UserContext);
  const { setModulesStore } = useContext(ModulesContext);

  const [authData, setAuthData] = useState(_authData);

  const [logInClicked, setLogInClicked] = useState(false);
  const { alertStore, setAlertStore } = useContext(AlertContext);
  const [logInError, setLogInError] = useState(false);
  const [networkStatus, setNetworkStatus] = useState(false);
  const { t } = useTranslation();
  const [inputType, setInputType] = useState({
    email: 'text',
    password: 'password',
    confirmPassword: 'password',
  });

  const { title, subtitle, inputs, buttonLabel, footer, onButtonClick, passwordConditions } = t('pages.login.data', { returnObjects: true }) as any;


  const loader = '/loaderLottie.json';

  const handleInputChange = (event: any) => {
    setAuthData({ ...authData, [event.target.name]: event.target.value });
  };

  const signIn = async (event: any) => {
    setLogInClicked(true);
    setLogInError(false);
    try {
      Sentry.captureEvent({
        // event_id: 'fetchData_no_data',
        message: `Performed request through firebase: start`,
        release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
        level: 'info' as any,
      });

      // First, get the user token.
      const response: any = await httpService.post({
        url: `${ENV.VITE_APP_BASE_STAGING_URL}/auth/v1_1/generate-signin-token`,
        data: {
          email: authData.email,
          password: authData.password,
          // tenant_id: ENV.VITE_APP_TENANT,
          // org_id: ENV.VITE_APP_ORG_ID,
        },
      });


      

     

      // Use the token from the response to sign in.
      const firebaseResult = await firebaseService.signInWithToken(
        response?.data?.token
      );

      try {
        const userRole = JSON.parse((firebaseResult.user as any)?.reloadUserInfo?.customAttributes)
        if (userRole?.user_role !== 'administrator') {

          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_NOT_ALLOWED',
              } as any,
            },
          });

          firebaseService.signOut();

          _authData = {
            email: '',
            password: '',
            createPassword: '',
          }
    
          setAuthData(_authData);

          return;

        }
      } catch (error) {
        console.warn(error);
      } 


      setModulesStore({
        type: ModulesTypes.ActivateModuleByName,
        payload: {
          value: ENV.VITE_APP_FIRST_PAGE,
        },
      });

      setUserStore({
        type: (UserTypes as any).SetShowLogin,
        payload: {
          value: false,
        },
      });


      return firebaseResult;
    } catch (error: any) {


      if (error.message === 'ERR_NETWORK') {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR',
            } as any,
          },
        });
      } else {

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_WRONG_LOGIN'
            } as any,
          },
        });

      }

      _authData = {
        email: '',
        password: '',
        createPassword: '',
      }

      setAuthData(_authData);


      setLogInError(true);

      Sentry.captureEvent({
        // event_id: 'fetchData_no_data',
        message: `Performed request through firebase: error`,
        release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
        level: 'warning' as any,
        extra: {
          error,
        },
      });

      console.error(error);
    } finally {
      // Reset logInClicked state after attempt.
      setLogInClicked(false);
    }
  };

  const togglePasswordVisibility = (name: string) => {
    setInputType((prevState) => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : 'password',
    }));
  };

  function handleDescriptionClick(inputName: string) {

    if (inputName === 'password') {
      setModulesStore({
        type: ModulesTypes.ActivateForgotPasswordModule,
      })
    }

    return null;
  }

  return (
    <div className={styles.LogIn}>

      <div className={styles.LogIn__Logo}>
        <IconWrapper iconName="logo_wide" />
      </div>
      <div className={styles.Login__Content}>

        <div className={styles.LogIn__Header__Title}>
          {title}
        </div>

        <form className={LogIn__Inputs}>
          {inputs.map((input: any) => (
            <div key={input.name}>
              <Textfield
                containedLabel={false}
                name={input.name}
                value={authData[input.name]}
                placeholder={input.placeholder}
                label={input.label}
                onChange={(event) => handleInputChange(event)}
                onPaste={(e) => false}
                onCopy={(e) => false}
                // type={input.type}
                description={input.description}
                type={inputType[input.name]}
                icon={input.icon}
                onIconClick={togglePasswordVisibility}
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                autoComplete='off'
                {
                ...(input?.description
                  ? {
                    onDescriptionClick: () => handleDescriptionClick(input.name),
                  }
                  : {})
                }
                {
                ...(
                  input?.descriptionFault && logInError
                    ? {
                      descriptionFault: input.descriptionFault,
                    }
                    : {}
                )
                }
                underline={ logInError === true ? 'visible' : 'hidden'}
              //FIX ME --> this is commented out for testing purposes on plenitude
              />
            </div>
          ))}
        </form>
        <div className={LogIn__Footer}>
          <Button
            className={LogIn__Footer__Button}
            onClick={onButtonClick ? () => setLogInFlow(onButtonClick) : signIn}
            label={
              logInClicked && !logInError ? (
                <Player autoplay loop src={loader} style={{ height: 20, transform: 'scale(0.7)' }} />
              ) : (
                buttonLabel
              )
            }
            variant="primary"
            color="secondary"
            disabled={
              !REGEX.email.test( authData.email ) ||
              authData.password === '' ||
              (logInClicked && !logInError) ||
              networkStatus
            }
          />

        </div>
      </div>
    </div>
  );
}

export default WebLogIn;