import { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface SiteType {
  status: string;
  flexoSiteType: 'producer' | 'consumer' | 'prosumer' | 'prosumage'; //add other types  if needed;
  startDate: string;
  endDate: string;
}

export interface Site {
  status: string;
  siteExternalId: string;
  siteId: string;
  pod: string;
  siteAddress: string;
  communityId: string;
  joinDate: string; //"2024-07-14"
  leaveDate: string; //"2024-07-14"
  siteLatitude: number;
  siteLongitude: number;
  siteName: string;
  siteType: SiteType[];
}

export interface Member {
  memberId: string;
  clientId: string;
  name: string;
  address: string;
  city: string;
  postcode: string;
  phone: string;
  email: string;
  communityId?: string; // Aggiunta di questa proprietà
}

export interface Community {
  status: string;
  communityName: string;
  communityCode: string;
  communityExternalId: string;
  communityStartDate: string;
  communityEndDate: string;
  communityId: string;
}

export interface EV {
  evId: string;
  communityId: string;
  memberId: string;
  // altre proprietà
}

export interface Wallbox {
  wallboxId: string;
  communityId: string;
  memberId: string;
  // altre proprietà
}

export interface EntitiesData {
  sites: Site[];
  members: Member[];
  communities: Community[];
  ev: EV[];
  wallbox: Wallbox[];
}

export interface StructuredData {
  communities: any[];
  unassignedMembers: any[];
  unassignedSites: any[];
  unassignedEVs: any[];
  unassignedWallboxes: any[];
}

export interface IMetaDataApiProvider {
  selectedEntity: Site | Member | Community | EV | Wallbox | null;
  entities: Site[] | EntitiesData;
  sortedEntities: StructuredData | null;
  communities: Community[] | null;
  members: Member[] | null;
  selectedEntityId: string | null;
  selectedEntityName: string | null;
  entityType: string | null;
}

export type metaDataApiStore = IMetaDataApiProvider;

export enum MetaDataApiTypes {
  SetSelectedEntity = 'SET_SELECTED_ENTITY',
  SetEntities = 'SET_ENTITIES',
  SetSortedEntities = 'SET_SORTED_ENTITIES',
  SetCommunities = 'SET_COMMUNITIES',
  SetMembers = 'SET_MEMBERS',
  SetMembersOverview = 'SET_MEMBERS_OVERVIEW',
  SetCommunitiesOverview = 'SET_COMMUNITIES_OVERVIEW',
  SetSelectedEntityID = 'SET_SET_SELECTED_ENTITY_ID',
  SetSelectedEntityName = 'SET_SET_SELECTED_ENTITY_NAME',

  SetSelectedEntityContent = 'SET_SELECTED_ENTITY_CONTENT',
  SetEntityType = 'SET_ENTITY_TYPE',
  SetUsefulData = 'SET_USEFUL_DATA',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [MetaDataApiTypes.SetSelectedEntity]: {
    value: {
      selectedEntity: Site | Member | Community | EV | Wallbox | null;
      id: string;
      entityType: string;
    };
  };
  [MetaDataApiTypes.SetSelectedEntityContent]: {
    value: Site | Member | Community | EV | Wallbox | null;
  };
  [MetaDataApiTypes.SetEntities]: {
    value: Site[] | EntitiesData;
  };

  [MetaDataApiTypes.SetSortedEntities]: {
    value: StructuredData;
  };
  [MetaDataApiTypes.SetCommunities]: {
    value: Community[];
  };
  [MetaDataApiTypes.SetCommunitiesOverview]: {
    value: any;
  };
  [MetaDataApiTypes.SetMembersOverview]: {
    value: any;
  };
  [MetaDataApiTypes.SetSelectedEntityID]: {
    value: string;
  };
  [MetaDataApiTypes.SetSelectedEntityName]: {
    value: string;
  };
  [MetaDataApiTypes.SetMembers]: {
    value: any;
  };
  [MetaDataApiTypes.SetUsefulData]: {
    value: any;
  };
  [MetaDataApiTypes.SetEntityType]: {
    value: string;
  };
};

export interface ContextValues {
  metaDataApiStore: metaDataApiStore;
  setMetaDataApiStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
