/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import Pie, { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import styles from './donut-graph.module.scss';
import { formatString } from '@flexo/general';
import { arc as d3Arc } from 'd3-shape';
import { interpolate as d3Interpolate, select as d3Select } from 'd3';
import { useOnClickOutside } from '@flexo/hooks';

export type PieProps = {
  width: number;
  height: number;
  defaultMargin?: { top: number; right: number; bottom: number; left: number };
  animate?: boolean;
  graphData: any;
  colors: string[];
  totalAmount: any;
  totalAmounttype: string;
  keys?: any;
  selectedId: string | null;
  setSelectedId: any;
  id?: string;
  dataInTimeSpan?: boolean;
};

export function DonutGraph({
  width,
  height,
  animate = true,
  graphData,
  keys,
  colors,
  totalAmount,
  totalAmounttype,
  selectedId,
  setSelectedId,
  dataInTimeSpan = true,
  id,
}: PieProps) {
  const { DonutGraph__TotalAmount, DonutGraph__TotalAmount__Type } = styles;
  const [selected, setSelected] = useState<any | null>(null);

  const pieRef = useRef(null);

  const value = (d) => d.value;
  const orderedColors = [...colors];

  const getSiteDataColor = scaleOrdinal({
    domain: keys,
    range: orderedColors,
  });

  const defaultMargin = { top: 20, right: 0, bottom: 20, left: 0 };

  if (width < 10) return null;

  const innerWidth = width - defaultMargin.left - defaultMargin.right;
  const innerHeight = height - defaultMargin.top - defaultMargin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 1.5;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 7;

  const getfilteredData = () => {
    if (selected) {
      return graphData.filter(({ item }: any) => item === selected.label);
    } else if (selectedId) {
      return graphData.filter((item: any) => item.label === selectedId);
    }
    return graphData;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, react-hooks/rules-of-hooks
  useOnClickOutside(pieRef, (event: any) => {
    if (!selected) return;
    if (event.target.tagName === 'SPAN') return;
    else {
      setSelected(null);
      setSelectedId(null);
    }
  });
  return (
    <div
      style={{
        position: 'relative',
        width: width,
        height: height,
        display: 'flex',
        justifyContent: 'flex-start',
        backgroundColor: 'transparent',
      }}
      ref={pieRef}
    >
      <div
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          top: centerY,
          width: innerWidth,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        }}
      >
        <span className={`${DonutGraph__TotalAmount} heading1L`}>
          {selected !== null ? selected?.data?.formattedValue : totalAmount}
        </span>
        <span className={`${DonutGraph__TotalAmount__Type} detail`}>
          {totalAmounttype}
        </span>
      </div>
      <svg width={width} height={height} overflow="visible">
        <rect rx={14} width={width} height={height} fill="transparent" />
        <Group
          top={centerY + defaultMargin.top}
          left={centerX + defaultMargin.left}
        >
          {/* Visible Pie Chart */}
          <Pie
            data={getfilteredData()}
            pieValue={value}
            outerRadius={radius}
            innerRadius={radius - donutThickness}
            cornerRadius={4}
            padAngle={0.03}
            pieSort={null}
          >
            {(pie) => (
              <AnimatedPie
                {...pie}
                radius={radius}
                donutThickness={donutThickness}
                onClickDatum={(data) => {
                  if (selected !== null) {
                    setSelected(
                      data?.data?.label === selected?.data.label ? null : data
                    );
                  }
                }}
                getKey={(arc) => arc.data?.label} // Make sure this is passed
                getColor={(arc) => getSiteDataColor(arc.data?.label)}
                selected={selected}
                animate={animate}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                setSelected={setSelected}
              />
            )}
          </Pie>

          {/* Transparent Pie Chart with Larger Clickable Area */}
          <Pie
            data={graphData}
            pieValue={value}
            outerRadius={radius + 30}
            innerRadius={radius - donutThickness - 30}
            cornerRadius={3}
            padAngle={0.005}
            pieSort={null}
          >
            {(pie) => (
              <>
                {}
                <AnimatedPie
                  {...pie}
                  animate={animate}
                  selected={selected}
                  radius={radius}
                  donutThickness={30}
                  getColor={() => 'transparent'}
                  getKey={(arc) => arc.data?.label}
                  onClickDatum={(data) => {
                    if (selected === null) return;
                    else
                      setSelected(
                        data?.data?.label === selected?.data.label ? null : data
                      );
                  }}
                  setSelectedId={setSelectedId}
                  setSelected={setSelected}
                  selectedId={selectedId}
                />
              </>
            )}
          </Pie>
        </Group>
      </svg>
    </div>
  );
}

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<Datum>) => string;
  getColor: (d: PieArcDatum<Datum>) => string;
  onClickDatum: (d: PieArcDatum<Datum>) => void;
  delay?: number;
  selected: any;
  radius: number;
  donutThickness: number;
  setSelected: any;
  selectedId: any;
  setSelectedId: any;
};

function ArcComponent({
  arc,
  isSelected,
  radius,
  popOutDistance,
  donutThickness,
  getColor,
  onClickDatum,
  getKey,
  setSelected,
  setSelectedId,
  animate,
  selectedId,
}: any) {
  const arcRef = useRef(null);
  const lastSelectedRef = useRef(isSelected);
  const [currentArc, setCurrentArc] = useState(arc);

  useEffect(() => {
    const arcGenerator = d3Arc()
      .outerRadius(currentOuterRadius)
      .innerRadius(currentInnerRadius)
      .cornerRadius(2);

    const interpolateStartAngle = d3Interpolate(
      currentArc.startAngle,
      arc.startAngle
    );
    const interpolateEndAngle = d3Interpolate(
      currentArc.endAngle,
      arc.endAngle
    );

    // Transition for arc value changes based on calendar selector (using named transition "arcChange")
    d3Select(arcRef.current)
      .transition('arcChange')
      .duration(500)
      .attrTween('d', function () {
        return function (t) {
          const interpolatedArc = {
            ...arc, // this is to ensure we don't miss other properties
            startAngle: interpolateStartAngle(t),
            endAngle: interpolateEndAngle(t),
          };
          return arcGenerator(interpolatedArc);
        };
      })
      .on('end', () => {
        setCurrentArc(arc);
      });
  }, [arc]);

  useEffect(() => {
    if (!isSelected) return;
    if (!animate) {
      // If there's no animation, simply set the arcRef with its dimensions immediately
      if (arcRef.current) {
        const arcGenerator = d3Arc()
          .outerRadius(isSelected ? radius + popOutDistance : radius)
          .innerRadius(
            isSelected
              ? radius - donutThickness + popOutDistance
              : radius - donutThickness
          )
          .cornerRadius(2);

        d3Select(arcRef.current).attr('d', arcGenerator(arc as any) || '');
      }
      return; // Exit the useEffect early
    }
    if (arcRef.current) {
      const initialOuterRadius = lastSelectedRef.current
        ? radius + popOutDistance
        : radius;
      const initialInnerRadius = lastSelectedRef.current
        ? radius - donutThickness + popOutDistance
        : radius - donutThickness;

      const finalOuterRadius = isSelected ? radius + popOutDistance : radius;
      const finalInnerRadius = isSelected
        ? radius - donutThickness + popOutDistance
        : radius - donutThickness;

      d3Select(arcRef.current)
        .transition()
        .duration(300)
        .attrTween('d', function () {
          const interpolateOuter = d3Interpolate(
            initialOuterRadius,
            finalOuterRadius
          );
          const interpolateInner = d3Interpolate(
            initialInnerRadius,
            finalInnerRadius
          );
          return function (t) {
            const arcGenerator = d3Arc()
              .outerRadius(interpolateOuter(t))
              .innerRadius(interpolateInner(t))
              .cornerRadius(2);
            return arcGenerator(arc as any) || '';
          };
        });

      lastSelectedRef.current = isSelected;
    }
  }, [isSelected]);

  const currentOuterRadius = isSelected ? radius + popOutDistance : radius;
  const currentInnerRadius = isSelected
    ? radius - donutThickness + popOutDistance
    : radius - donutThickness;

  const arcGenerator = d3Arc()
    .outerRadius(currentOuterRadius)
    .innerRadius(currentInnerRadius)
    .cornerRadius(2);

  const onPieClick = (arc, event) => {
    if (event.type === 'touchend') {
      event.preventDefault(); // Prevent any default touch behavior

      setTimeout(() => {
        // Your touchend logic
        if (!animate) return;
        else {
          setSelectedId(selectedId === arc.data?.id ? null : arc.data?.id);
          onClickDatum(arc);
        }
      }, 1000); // 1000ms (1 second) delay. Adjust as necessary.

      return;
    }

    // Original click logic for touchstart or other events
    if (!animate) return;
    else {
      if (selectedId !== null) return;

      setSelectedId(selectedId === arc.data?.id ? null : arc.data?.id);
      onClickDatum(arc);
    }
  };

  return (
    <g key={getKey(arc)}>
      <path
        ref={arcRef}
        d={arcGenerator(currentArc as any) || ''}
        fill={getColor(arc)}
        onTouchStart={(event) => onPieClick(arc, event)}
        onTouchEnd={(event) => onPieClick(arc, event)}
        style={{
          pointerEvents: getColor(arc) === 'transparent' ? 'auto' : 'none',
        }}
      />
    </g>
  );
}

function AnimatedPie<Datum>({
  arcs,
  getKey,
  getColor,
  onClickDatum,
  selected,
  radius,
  donutThickness,
  setSelected,
  setSelectedId,
  selectedId,
  animate,
}: AnimatedPieProps<Datum | any>) {
  const popOutDistance = 10;

  useEffect(() => {
    if (selectedId !== null) {
      const matchedArc = arcs.find((arc) => arc.data?.id === selectedId);
      setSelected(matchedArc || null);
    } else {
      setSelected(null);
    }
  }, [selectedId, arcs]);

  return (
    <>
      {arcs.map((arc) => {
        const isSelected = selected && getKey(arc) === getKey(selected);
        return (
          <ArcComponent
            arc={arc}
            isSelected={isSelected}
            radius={radius}
            popOutDistance={popOutDistance}
            donutThickness={donutThickness}
            getColor={getColor}
            onClickDatum={onClickDatum}
            getKey={getKey}
            setSelected={setSelected}
            animate={animate}
            key={getKey(arc)}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
          />
        );
      })}
    </>
  );
}

export default DonutGraph;
