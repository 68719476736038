import MultiProgress from 'react-multi-progress';
import styles from './stats-overview.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  KpiApiContext,
  MetaDataApiContext,
  ThemeContext,
} from '@flexo/providers';
import { DonutGraph } from '@flexo/atoms';
import StatsOverviewSkeleton from './stats-overview-skeleton';
import { capitalizeFirstLetter } from '@flexo/general';
import { useTranslation } from 'react-i18next';

export function StatsOverview(props: any) {
  const {
    StatsOverview__PieContainer,
    StatsOverview__Stats,
    StatsOverview__Stats__Title,
    StatsOverview__Stats__Percentages,
    StatsOverview__Stats__Percentages__Labels,
    StatsOverview__Stats__Percentages__Labels__Label,
  } = styles;
  const [selected, setSelected] = useState<string | null>(null); // [1
  const { title, type, dataInTimeSpan = true } = props;
  const { t } = useTranslation();
  const { ThemeStore } = useContext(ThemeContext);
  const { kpiApiStore } = useContext(KpiApiContext);
  const holdTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  let overviewKpiApiData = kpiApiStore?.site?.['1day']?.[0]?.kpi as any;

  if (props?.view === 'community') {
    overviewKpiApiData = kpiApiStore?.community?.['1day']?.[0]?.kpi as any;
  }


  const findApiValue = (name) => {
    const item = overviewKpiApiData?.find((data) => data.name === name);
    return item ? item.value : 0;
  };
  const findApiUnit = (name) => {
    const item = overviewKpiApiData?.find((data) => data.name === name);
    return item ? item.unit : 0;
  };
  const values = props.values?.map((value) => ({
    label: value.label,
    totalPercentage: findApiValue(value.totalPercentage),
    progress: value?.progress?.map((progress) => ({
      value: findApiValue(progress.value),
      color: ThemeStore.colors[progress.color],
      className: styles.StatsOverview__MultiProgress__Element,
    })),
  }));

  // Define the valid values
  const validValues = new Set([0, '0,00', 0.0, '0']);

  // Check if all values in the JSON object are valid
  // const consumptionCero = Object.values(
  //   overviewDataPercentage.consumption
  // ).every((value) => validValues.has(value as any));
  // const productionCero = Object.values(overviewDataPercentage.production).every(
  //   (value) => validValues.has(value as any)
  // );

  const graphData = props.graphDataKeys.map((data) => ({
    id: data.key,
    label: data.label,
    value: findApiValue(data.key),
    formattedValue: findApiValue(data.key),
  }));
  const startHold = (valueLabel) => {
    if (selected !== null) return;
    else {
      setSelected(valueLabel);
      // Set a timeout for the desired duration (e.g., 2 seconds)
      holdTimeout.current = setTimeout(() => {
        // Do nothing if held for the full duration (or perform another action if needed)
      }, 2000);
    }
  };

  const endHold = () => {
    clearTimeout(holdTimeout.current as any); // clear the timeout if user releases before the duration
    setSelected(null);
  };

  useEffect(() => {
    const handleContextMenu = (event) => event.preventDefault();
    const handleCopy = (event) => event.preventDefault();
    const handlePaste = (event) => event.preventDefault();

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
    };
  }, []);


  // if (!overviewKpiApiData) return <StatsOverviewSkeleton />;

  return (
    <div className={styles.StatsOverview}>
      <div className={StatsOverview__PieContainer}>
        <DonutGraph
          dataInTimeSpan={dataInTimeSpan}
          width={105}
          height={105}
          graphData={graphData}
          colors={props?.graphColors?.map(
            (color) => ThemeStore.colors[color] || 'red'
          )}
          totalAmount={findApiValue(props.totalValue)}
          keys={props.graphDataKeys}
          totalAmounttype={findApiUnit(props.totalValue)}
          selectedId={selected}
          setSelectedId={setSelected}
        />
      </div>{' '}
      <div
        className={StatsOverview__Stats}
        style={{
          marginTop: '.75em',
        }}
      >
        <span className={`${StatsOverview__Stats__Title} heading4`}>
          {capitalizeFirstLetter(t(title))}
        </span>
        {values.map((value: any, index) => (
          <div
            className={StatsOverview__Stats__Percentages}
            key={value.label}
            // onTouchStart={() => startHold(value.label)}
            // onTouchEnd={() => endHold()}
            style={{
              marginTop: '.75em',
              cursor: 'pointer',
            }}
          >
            <div
              className={`${StatsOverview__Stats__Percentages__Labels} paragraph ${
                styles.noSelect
              } ${selected === value.label ? styles.selected : ''}`}
            >
              <span
                className={`${StatsOverview__Stats__Percentages__Labels__Label} ${styles.noSelect}`}
                // onClick={() => getSelected(value.label)}
                // onTouchStart={(e) => {
                //   e.preventDefault();
                // }}
              >
                {t(value.label)}
              </span>
              <span
                className={`${StatsOverview__Stats__Percentages__Labels__Label} heading5L ${
                  selected === value.label ? styles.selected : ''
                }`}
              >
                {value.totalPercentage}%
              </span>
            </div>

            <div>
              {' '}
              <MultiProgress
                transitionTime={0.5}
                elements={value.progress}
                backgroundColor="#F0F0F0"
                className={styles.StatsOverview__MultiProgress}
              />{' '}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StatsOverview;
