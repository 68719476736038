import { useContext, useEffect } from 'react';

import {
  UserContext,
  ModulesTypes,
  ModulesContext,
  MetaDataApiContext,
  AlertTypes,
  AlertContext,
  UserTypes,
} from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';
import { MetaDataApiTypes } from '@flexo/providers';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

let round = 0;

export interface MemberInitProps {
  setMetaDataApiStore: any;
  metaDataApiStore: any;
  setModulesStore: any;
  setAlertStore: any;
  setUserStore: any;
}

export async function MemberInit(props: MemberInitProps) {
  const {
    setMetaDataApiStore = null,
    metaDataApiStore = null,
    setModulesStore = null,
    setAlertStore = null,
    setUserStore = null,
  } = props;

  const idToken = await firebaseService.getIdToken();

  if (!idToken) {
    setAlertStore({
      type: AlertTypes.SetAlert,
      payload: {
        value: {
          type: 'ALERT_SESSION_EXPIRED',
        },
      },
    });

    setUserStore({
      type: UserTypes.SetUserLogOut,
      payload: {
        value: true,
      },
    });

    return;
  }

  const [member] = await Promise.all([
    httpService.get({
      // url: `/hive/api/catalog/v1/catalog/community/${globalDataStore.data?.communityID}/members/${globalDataStore.navigation?.id}`,
      url: `${ENV.VITE_APP_BASE_STAGING_URL}/hive/api/catalog/v1_2/member/${metaDataApiStore.selectedEntityId}`,
      // /hive/api/catalog/v1_2/member/{memberId}
      idToken,
    })
      .then((res: any) => {
        if (res.status === 200) {
          return res.data;
        } else {
          console.warn({
            status: res.status,
            message: res.statusText
          });
          return null
        }
      })

      .catch((err: any) => {
        console.warn(err);

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR'
            },
          }
        })
      })
  ]);

  setMetaDataApiStore({
    type: MetaDataApiTypes.SetSelectedEntity,
    payload: {
      value: {
        selectedEntity: member,
        id: member?.memberId,
        entityType: 'member',
      },
    },
  });
}

export const MemberResolver = () => {
  const { setUserStore } = useContext(UserContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { setMetaDataApiStore, metaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setAlertStore } = useContext(AlertContext);

  useEffect(() => {
    if (round === 0) {
      MemberInit({
        setMetaDataApiStore,
        metaDataApiStore,
        setModulesStore,
        setAlertStore,
        setUserStore,
      });
      round++;
    }
  }, []);

  return <></>;
};

export function navigateToMember(member, setMetaDataApiStore, setModulesStore) {
  round = 0;

  const MEMBER_ID = member.flexoMemberId || member?.memberId;

  setMetaDataApiStore({
    type: MetaDataApiTypes.SetSelectedEntity,
    payload: {
      value: {
        selectedEntity: member,
        id: MEMBER_ID,
        entityType: 'member',
      },
    },
  });

  setModulesStore({
    type: ModulesTypes.ActivateMemberModule,
    payload: {
      value: MEMBER_ID,
    },
  });
}
