import { CalendarContext, ThemeContext } from '@flexo/providers';
import {  useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { formatDate } from '../helpers';

export default function HorizontalChart(props: any) {
  const ref: any = useRef(null);
  
  const { t } = useTranslation()
  const { ThemeStore } = useContext(ThemeContext)
  const { calendarStore } = useContext(CalendarContext)
  const [data, setData] = useState<any>(null);
  const [hoveredBarKey, setHoveredBarKey] = useState<any>(null);

  // Funzione per il tooltip personalizzato
  const CustomTooltip: any = ({ active, payload }) => {
    if (active && payload && payload.length) {
      // Filtra il payload per ottenere solo l'elemento attivo
      const activePayload = payload.find(
        (item) => item.dataKey === hoveredBarKey
      );
      const unit =
        props?.data?.kpi?.find((datum: any) => datum.name === hoveredBarKey)
          ?.unit || '';

      if (activePayload) {
        return (
          <div
            style={{
              backgroundColor: 'var( --hive-greyscale-100 )',
              padding: '10px',
              borderRadius: '8px',
              fontFamily: 'Flexo',
              fontSize: 14,
            }}
          >
            <p
              style={{
                margin: 0,
                borderBottom: `solid 2px ${activePayload.fill}`,
              }}
            >
              <strong className="font-flexo-semibold">
                {t(
                  `widgets.horizontalChart.${props?.data?.context}.${activePayload.dataKey}`
                )}
              </strong>{' '}
              {props?.data?.context === 'economics'
                ? `${unit} ${activePayload.value} `
                : `${activePayload.value} ${unit}`}
            </p>
          </div>
        );
      }
    }

    return null;
  };

  useEffect(() => {
    if (props?.data?.kpi && props?.data?.graphKeys) {
      const _data = props.data.kpi
        .filter((datum: any) => props.data.graphKeys.includes(datum.name))
        .reduce((prev, next) => ({
          ...prev,
          [next.name]: next.value,
        }), {
          name: t(props.data.rankingKpiName)
        });
      
      setData([ _data ]);

    }
  }, [])

  return (
      
    data
      ? <ResponsiveContainer width={'100%'} height={8} ref={ ref }>
        <BarChart
          layout="vertical" // Disposizione orizzontale delle barre
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis
            type="number"
            hide={true}  // Nasconde completamente l'asse X
            axisLine={false} // Assicura che la linea dell'asse X non sia visibile
            tickFormatter={(tick) =>
              formatDate(tick, calendarStore.currentlySelectedDateType)
            }
          
          />  {/* L'asse X rappresenta i valori */}
          <YAxis
            type="category"
            dataKey="name"
            axisLine={false}
            tickLine={false}
            
          />  {/* L'asse Y rappresenta le categorie */}
          <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: 'transparent' }} // Rimuove l'effetto di hover grigio sulla barra
        />
        {/* Legenda rimossa */}
        {props?.data?.graphKeys.map(
          (key: string, index: number, array: any) => (
            <Bar
              id={key}
              dataKey={key}
              stackId={props.data.rankingKpiName}
              radius={[
                index === 0 ? 4 : 0,
                index === array.length - 1 ? 4 : 0,
                index === array.length - 1 ? 4 : 0,
                index === 0 ? 4 : 0,
              ]}
              onMouseEnter={() => setHoveredBarKey(key)}
              onMouseLeave={() => setHoveredBarKey(null)}
              fill={`${ThemeStore?.colors[props?.data?.colors[index].color]}${
                props.data?.colors[index].colorShade
              }`}
            />
          )
        )}
      </BarChart>
    </ResponsiveContainer>
   : 
    <></>
  );
}
