import { useTranslation } from "react-i18next";

import styles from './../alert.module.scss';
import Button from "../../button/button";


export function BannerCheckEmail() {

  const { t } = useTranslation();
  const bannerContent = t('banners.check_email', { returnObjects: true }) as any;

  return (
    <div id="banner-cehck-email" className={styles.Banner__Wrong__Size}>

      <div className={styles.Banner__Title} >
        {bannerContent.title}
      </div>
      <div className={styles.Banner__Content} >
        {bannerContent.description}
        <br /> <br />
        {bannerContent.description_2}
      </div>

      <div className={styles.Banner__Footer__Actions} >
        <Button
          variant="secondary"
          className={`${styles.Banner__Footer__Actions__Button} ${styles.Button__End}`}
          label={bannerContent.buttonLabel}
          onClick={() => console.log('logout')}
        />
      </div>

    </div>
  )
}