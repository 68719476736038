import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CustomHeader from './custom-header/custom-header';
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from 'react-datepicker';
import CustomInput from './custom-input/custom-input';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import './calendar.module.scss';
import {
  CalendarContext,
  CalendarTypes,
  DataContext,
  SiteViewContext,
} from '@flexo/providers';
import { useOnClickOutside } from '@flexo/hooks';
import { useTranslation } from 'react-i18next';
import { es, it } from 'date-fns/locale';

registerLocale('es', es);
registerLocale('it', it);

const ENV = (import.meta as any).env;

export function Calendar() {

  const { dataStore } = useContext(DataContext)
  

  const setDate = (dateType: string) => {

    switch (dateType) {
      case 'day':
        setCalendarStore({
          type: CalendarTypes.SetSelectedDate,
          payload: {
            value: new Date(),
          },
        });
        break;
      case 'month':
        setCalendarStore({
          type: CalendarTypes.SetSelectedDate,
          payload: {
            value: new Date(),
          },
        });
    }
  };

  const MyContainer = ({ className, children }: any) => {
    const { t } = useTranslation();
    const getTagContent = () => {
      switch (calendarDisplay) {
        case 'day':
          return 'widgets.calendar.last.day';
        // case 'week':
        //   return 'widgets.calendar.last.week';
        // case 'month':
        //   return 'widgets.calendar.last.month';
        // case 'year':
        //   return 'widgets.calendar.last.year';
        default:
          return '';
      }
    };


    return (
      <div>
        <CalendarContainer className={className}>
          {/* <div className="CalendarContainer__Footer">
            {calendarDisplay === 'day' && (
              <StateTag
                state={t(getTagContent())}
                filled={false}
                onClick={() => setDate(calendarDisplay)}
              />
            )}
            <div className="CalendarContainer__Footer__Updates detail">
              <div className="CalendarContainer__Footer__Updates__Icon">
                {' '}
                <IconWrapper iconName={'refresh'} />
              </div>
              <div
                className={`CalendarContainer__Footer__Updates__Tag detail `}
              >
                {t('widgets.calendar.last.update')}:
              </div>
              <div className="CalendarContainer__Footer__Updates__Value detailLight">
                5m
              </div>
            </div>
          </div> */}
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };
  const { calendarStore, setCalendarStore } = useContext(CalendarContext);
  const {
    calendarDisplay,
    selectedDate,
    selectedWeek,
    showCalendar,
    customInteraction,
    previousCalendarDisplay,
    currentlySelectedDateType,
    showCustomInteraction,
    prevSelectedDate,
  } = calendarStore;

  const { day, week, month } = showCustomInteraction;
  const { siteViewStore } = useContext(SiteViewContext);

  const calendarContainerRef = useRef(null);
  const calendarRef = useRef(null);
  const [locale, setLocale] = useState(ENV?.VITE_APP_LANGUAGE || 'en');
  const [shownDate, setShownDate] = useState(selectedDate);
  const { i18n } = useTranslation();

  const handleDateChange = (date: any) => {

    setCalendarStore({
      type: CalendarTypes.SetPreviousCalendarDisplay,
      payload: {
        value: calendarDisplay,
      },
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks

    switch (customInteraction) {
      case 'day':
        if (
          DateTime.fromJSDate(date).month === 1 &&
          DateTime.fromJSDate(date).day === 1
        ) {
          setCalendarStore({
            type: CalendarTypes.SetShowCustomInteraction,
            payload: {
              value: {
                ...showCustomInteraction,
                month: true,
              },
            },
          });
        }
        if (month && DateTime.fromJSDate(date).day === 1) {
          setCalendarStore({
            type: CalendarTypes.SetShowCustomInteraction,
            payload: {
              value: {
                ...showCustomInteraction,
                month: false,
                day: true,
              },
            },
          });
        }
        if (day) {
          setCalendarStore({
            type: CalendarTypes.SetCustomInteraction,
            payload: {
              value: undefined,
            },
          });
          setCalendarStore({
            type: CalendarTypes.SetShowCalendar,
            payload: {
              value: true,
            },
          });
          setCalendarStore({
            type: CalendarTypes.SetShowCustomInteraction,
            payload: {
              value: {
                ...showCustomInteraction,
                day: false,
              },
            },
          });
          setCalendarStore({
            type: CalendarTypes.SetShowCalendar,
            payload: {
              value: false,
            },
          });
          setCalendarStore({
            type: CalendarTypes.SetCurrentlySelectedDateType,
            payload: {
              value: 'day',
            },
          });
        }
        break;

      case 'week':
        setCalendarStore({
          type: CalendarTypes.SetShowCustomInteraction,
          payload: {
            value: {
              day: showCustomInteraction.day,
              week: showCustomInteraction.week,
              month: true,
            },
          },
        });
        if (month) {
          setCalendarStore({
            type: CalendarTypes.SetShowCustomInteraction,
            payload: {
              value: {
                month: false,
                week: true,
                day: false,
              },
            },
          });
          // setCalendarStore({
          //   type: CalendarTypes.SetSelectedWeek,
          //   payload: {
          //     value: { startDate: null, endDate: null },
          //   },
          // });
        }
        if (week) {
          setCalendarStore({
            type: CalendarTypes.SetCustomInteraction,
            payload: {
              value: undefined,
            },
          });
          // setCalendarStore({
          //   type: CalendarTypes.SetSelectedWeek,
          //   payload: {
          //     value: { startDate: null, endDate: null },
          //   },
          // });
          setCalendarStore({
            type: CalendarTypes.SetShowCustomInteraction,
            payload: {
              value: {
                ...showCustomInteraction,
                day: false,
                week: false,
              },
            },
          });
          setCalendarStore({
            type: CalendarTypes.SetCurrentlySelectedDateType,
            payload: {
              value: 'week',
            },
          });
        }
        // setCalendarStore({
        //   type: CalendarTypes.SetShowCalendar,
        //   payload: {
        //     value: false,
        //   },
        // });
        break;

      case 'month':
        if (
          DateTime.fromJSDate(date).month === 1 &&
          DateTime.fromJSDate(date).day === 1
        ) {
          setCalendarStore({
            type: CalendarTypes.SetShowCustomInteraction,
            payload: {
              value: {
                ...showCustomInteraction,
                month: true,
              },
            },
          });
        }
        if (month && DateTime.fromJSDate(date).day === 1) {
          setCalendarStore({
            type: CalendarTypes.SetShowCustomInteraction,
            payload: {
              value: {
                ...showCustomInteraction,
                month: false,
              },
            },
          });
          setCalendarStore({
            type: CalendarTypes.SetCustomInteraction,
            payload: {
              value: undefined,
            },
          });
          setCalendarStore({
            type: CalendarTypes.SetShowCalendar,
            payload: {
              value: false,
            },
          });
          setCalendarStore({
            type: CalendarTypes.SetCurrentlySelectedDateType,
            payload: {
              value: 'month',
            },
          });
        }
        break;
    }
    if (calendarDisplay === 'week' && customInteraction !== 'week') {
      if (currentlySelectedDateType === 'day') {
        setCalendarStore({
          type: CalendarTypes.SetSelectedDate,
          payload: {
            value: date,
          },
        });
      }

      const luxonDate = DateTime.fromJSDate(date);
      const startDate = luxonDate.startOf('week').toJSDate();
      // const endDate = luxonDate.startOf('week').plus({ days: 6 }).toJSDate();
      const endDate = luxonDate.endOf('week').toJSDate();
      //This should not happen when there is a week customInteraction

      setCalendarStore({
        type: CalendarTypes.SetSelectedWeek,
        payload: {
          value: {
            startDate: startDate,
            endDate: endDate,
          },
        },
      });
      setCalendarStore({
        type: CalendarTypes.SetSelectedDate,
        payload: {
          value: luxonDate.startOf('week').toJSDate(),
        },
      });
      setCalendarStore({
        type: CalendarTypes.SetCurrentlySelectedDateType,
        payload: {
          value: 'week',
        },
      });
      setCalendarStore({
        type: CalendarTypes.SetShowCalendar,
        payload: {
          value: false,
        },
      });
    } else if (week) {

      const luxonDate = DateTime.fromJSDate(date[0]);
      const startDate = luxonDate.startOf('week').toJSDate();
      // const endDate = luxonDate.startOf('week').plus({ days: 6 }).toJSDate();
      const endDate = luxonDate.endOf('week').toJSDate();
      //This should not happen when there is a week customInteraction

      setCalendarStore({
        type: CalendarTypes.SetSelectedWeek,
        payload: {
          value: {
            startDate: startDate,
            endDate: endDate,
          },
        },
      });
      setCalendarStore({
        type: CalendarTypes.SetShowCalendar,
        payload: {
          value: false,
        },
      });
    } else if (
      calendarDisplay === 'week' &&
      customInteraction === 'week' &&
      (month || !day)
    ) {
      const luxonDate = DateTime.fromJSDate(date[0]);
      setCalendarStore({
        type: CalendarTypes.SetSelectedDate,
        payload: {
          value: luxonDate.startOf('month').toJSDate(),
        },
      });
    } else {
      setCalendarStore({
        type: CalendarTypes.SetSelectedDate,
        payload: {
          value: date,
        },
      });

      if (prevSelectedDate === selectedDate && !customInteraction) {
        setCalendarStore({
          type: CalendarTypes.SetPrevSelectedDate,
          payload: {
            value: date,
          },
        });
      } else if (
        showCustomInteraction[customInteraction as any] ||
        !customInteraction
      ) {
        
        setCalendarStore({
          type: CalendarTypes.SetPrevSelectedDate,
          payload: {
            value: date,
          },
        });
      }
      if (!customInteraction) {
        setCalendarStore({
          type: CalendarTypes.SetShowCalendar,
          payload: {
            value: false,
          },
        });
        setCalendarStore({
          type: CalendarTypes.SetCurrentlySelectedDateType,
          payload: {
            value: calendarDisplay,
          },
        });
        setCalendarStore({
          type: CalendarTypes.SetShowCalendar,
          payload: {
            value: false,
          },
        });
        setCalendarStore({
          type: CalendarTypes.SetButtonSwitch,
          payload: {
            value: false,
          },
        });
      }
    }
  };

  const updateLocale = useCallback(async () => {

    
    const lang = ENV?.VITE_APP_LANGUAGE || 'en';
    try {
      // lang = i18n.language.split('_')[1];
    } catch (err: any) {
      console.warn('Error getting language from i18n', err);
    }
    setLocale(lang);
  }, []);

  const getShowCalendar = () => {

    
    if (showCalendar) {
      setCalendarStore({
        type: CalendarTypes.SetShowCalendar,
        payload: {
          value: false,
        },
      });

      setCalendarStore({
        type: CalendarTypes.SetCalendarDisplay,
        payload: {
          value: currentlySelectedDateType,
        },
      });
    } else return;
  };

  useOnClickOutside(calendarContainerRef, () => getShowCalendar());

  function getCalendarClassNames() {


    return `Calendar CalendarInput__SelectedStates--${
      previousCalendarDisplay !== calendarDisplay && previousCalendarDisplay
    } CalendarInput__CurrentlySelected--${currentlySelectedDateType} CalendarInput__CalendarDisplay--${calendarDisplay} ${
      (customInteraction === 'day' ||
        customInteraction === 'week' ||
        customInteraction === 'month') &&
      !month &&
      !day &&
      `CalendarInput__CustomInteraction--year`
    } ${month && `CalendarInput__CustomInteraction--month`} ${
      day && `CalendarInput__CustomInteraction--day`
    } ${
      customInteraction === 'week' && `CalendarInput__CustomInteraction--week`
    }`;
    // return true
  }

  useEffect(() => {
    registerLocale('es', es);
    updateLocale();
  }, [updateLocale]);

  const getMinDate = () => {

    //kwick site api needs this:
    //const minDate = siteViewStore.selectedSite[0]?.site_users?.find(
    // (el) => el.changelog_end_date === '2099-12-31'
    // ).changelog_start_date;
    let minDate = new Date().toISOString().slice(0, 10);

    try {
      minDate = dataStore?.data?.selectedSite?.site_users?.find(
        (el) => el.changelog_end_date === '2099-12-31'
      )?.changelog_start_date;
    } catch(err) {
      console.warn('Error getting min date from site users', err);
    }

    return new Date(minDate);
  };

  const getMaxDate = () => {
    
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();
    if (currentHour < 10 && currentMinutes < 30) {
      currentDate.setDate(currentDate.getDate() - 2);
    } else {
      currentDate.setDate(currentDate.getDate() - 1);
    }
    return currentDate;
  };

  const getSelectedDate = () => {
    
    const _minimumDate =
      getMinDate() >= selectedDate ? getMinDate() : selectedDate;
    return _minimumDate;
  };

  //calendar is not updating when switching with the chevrons in the headers
  return (
    <div className={getCalendarClassNames()} ref={calendarContainerRef}>
      <DatePicker
        ref={calendarRef}
        locale={locale}
        onYearChange={(date: Date) => setShownDate(date)}
        onMonthChange={(date: Date) => setShownDate(date)}
        onDayChange={(date: Date) => setShownDate(date)}
        customInput={<CustomInput minDate={getMinDate()} />}
        calendarClassName={`CalendarInput ${calendarDisplay}`}
        selected={getSelectedDate()}
        // {...(
        //   calendarDisplay === 'week' ||
        // currentlySelectedDateType === 'week' ||
        // customInteraction === 'week'
          // ? {
          //     startDate: new Date(selectedWeek.startDate as any),
          //     endDate: new Date(selectedWeek.endDate as any),
          //   }
          // : {})}
        onChange={(selectedDate: Date) => {
          handleDateChange(selectedDate as Date);
        }}
        showMonthYearPicker={calendarDisplay === 'month' || month}
        showYearPicker={
          ((customInteraction === 'day' ||
            customInteraction === 'week' ||
            customInteraction === 'month') &&
            !month &&
            !day &&
            !week) ||
          calendarDisplay === 'year'
        }
        allowSameDay={true}
        open={showCalendar}
        className={`CalendarInput__SelectedStates`}
        showFourColumnMonthYearPicker={month || calendarDisplay === 'month'}
        popperClassName={ ENV.VITE_APP_NAME === 'admin' ? 'CalendarInput__Popper__Web': 'CalendarInput__Popper'}
        calendarContainer={MyContainer}
        showWeekNumbers={false}
        selectsRange={calendarDisplay === 'week'}
        calendarStartDay={1}
        maxDate={getMaxDate()}
        minDate={getMinDate()}
        renderCustomHeader={({
          changeMonth,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          increaseYear,
          decreaseYear,
        }) => (
          <CustomHeader
            shownDate={shownDate}
            setShownDate={setShownDate}
            changeMonth={changeMonth}
            changeYear={changeYear}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            increaseYear={increaseYear}
            decreaseYear={decreaseYear}
            customInteraction={customInteraction}
            selectedDate={getSelectedDate()}
            minDate={getMinDate()}
            getSelectedDate={getSelectedDate()}
          />
        )}
      />
    </div>
  );
}

export default Calendar;
