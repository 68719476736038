
import { v4 as uuidv4 } from 'uuid';

import {
  IonApp,
  IonContent,
  setupIonicReact,
  useIonRouter,
} from '@ionic/react';

import * as Sentry from '@sentry/react';

import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ThemeContext,
  UserContext,
  SiteViewContext,
  AlertContext,
  ModulesTypes,
  ModulesContext,
  CalendarContext,
  DataProvider,
  CalendarProvider,
  checkQueryParams,
  AlertProvider,
  ModalProvider,
  RightDrawerProvider,
  LeftDrawerProvider,
  FiltersProvider,
  MetaDataApiContext,
  KpiDataProvider,
  setParams,
  AlertTypes,
} from '@flexo/providers';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import i18next from 'i18next';
import jwt_decode from 'jwt-decode';
import { UserTypes } from 'libs/providers/src/lib/user-provider/user-provider.interfaces';
import { App as CapApp } from '@capacitor/app';
import { useTranslation } from 'react-i18next';

import { Modules } from '@flexo/templates';

// import * as JAIL from '@swernimont/capacitor-jailbreak-root-detection';

import './app.module.scss';
import '../assets/fonts.scss';
import '../assets/typography.scss';
import '../assets/colors.scss';
// eslint-disable-next-line
import { default as COLORS } from '../assets/colors';
import '@ionic/react/css/core.css';

import { default as MODULES } from './app.modules';
import { LeftDrawer, RightDrawer } from '@flexo/molecules';
import { getMetaResolver, getResolver } from 'libs/templates/src/lib/modules/resolvers.registry';
import MobileModal from 'libs/templates/src/lib/modal/mobile-modal';
import { eventEmitter, FirebaseService } from '@flexo/general';
import { ThemeProvider } from '@flexo/providers';

const firebaseService = new FirebaseService()

const ENV = (import.meta as any).env;

export interface ILoginFlow {
  logInState: 'LOGIN' | 'REGISTER' | 'ACCOUNT_CREATION' | 'FORGOT_PASSWORD';
}

setupIonicReact(); //initiates ionic with react

function App() {

  const { setThemeStore, ThemeStore } = useContext(ThemeContext);
  const { userStore, setUserStore } = useContext(UserContext);
  const { setCalendarStore } = useContext(CalendarContext);
  const { alertStore, setAlertStore } = useContext(AlertContext);
  const { siteViewStore, setSiteViewStore } = useContext(SiteViewContext);

  const { setModulesStore, modulesStore } = useContext(ModulesContext);
  const { setMetaDataApiStore } = useContext(MetaDataApiContext);

  const { user } = userStore;
  const [logInFlow, setLogInFlow] = useState<ILoginFlow['logInState']>('LOGIN');
  const [isBlurred, setIsBlurred] = useState(false);

  const { t } = useTranslation()


  const changeLanguage = useCallback(async () => {
    await i18next.changeLanguage(
      `${ENV.VITE_APP_NAME}_${ENV.VITE_APP_LANGUAGE}`
    );
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseService.onAuthStateChange((user) => {
      if (user) {
        let tokenContent: any = {};
        try {
          tokenContent = jwt_decode(user?.stsTokenManager?.accessToken);
        } catch (error) {
          console.error(error);
        }

        setUserStore({
          type: UserTypes.SetUser,
          payload: {
            value: { ...user, tokenContent },
          },
        });
        // Sentry.setUser({
        //   id: user.uid,
        //   email: user.email,
        // });

        // Sentry.startSession();
      } else {
        // Clear user context in Sentry when user is logged out
        // Sentry.setUser(null);
        // Sentry.endSession();
      }
    });

    // Clean up the listener when the component is unmounted
    return () => unsubscribe();
  }, [logInFlow]);

  useEffect(() => {
    // TO DO: dinamicaly change language based on device language
    i18next.changeLanguage(`${ENV.VITE_APP_NAME}_${ENV.VITE_APP_LANGUAGE}`);

    
  }, [changeLanguage]);

  useEffect(() => {
    CapApp.addListener('appStateChange', async ({ isActive }) => {
      if (!isActive) {
        setIsBlurred(true);
      } else {
        setIsBlurred(false);
      }
    });

    CapApp.addListener('backButton', ({ canGoBack }) => {
      //alert( 'back' )
    });
  }, []);


  // load app's modules

  useEffect(() => {

    if (modulesStore?.modules?.length > 0) return;

    setModulesStore({
      type: ModulesTypes.LoadModules,
      payload: {
        value: MODULES,
        props: {
          userGroup: user?.tokenContent?.user_role || null,
          siteType: siteViewStore.selectedSite[0]?.site_type || null,
          userType: siteViewStore.selectedSite[0]?.user_type || null,
        },
      },

    })


    window.onpopstate = (ev: any) => {
      ev.preventDefault();

      checkQueryParams(setModulesStore, setMetaDataApiStore, setCalendarStore, null, MODULES);
    };

  }, []);


  const Resolvers = React.useMemo(() => {

    const _activeModule = modulesStore?.modules?.find((module: any) => module.active === true && module.visible === true);

    if (_activeModule === null) return null;
    return _activeModule?.apis?.map((resolver: any) => {
      const Resolver = getResolver(resolver);
      return Resolver;
    });

  }, [modulesStore?.modules]);



  const MetaResolvers = React.useMemo(() => {
    const _activeModule = modulesStore?.modules?.find(
      (module: any) => module.active === true && module.visible === true
    );

    if (_activeModule === null) return null;
    return _activeModule?.metaResolvers?.map((resolver: any) => {
      const MetaResolver = getMetaResolver(resolver);
      return MetaResolver;
    });
  }, [modulesStore?.modules]);

  useEffect(() => {

    if (userStore?.user?.logOut === true) {
      setUserStore({
        type: UserTypes.ResetUserStore,
        payload: null as any,
      })

      window.location.href = '/login';
    }
    
  }, [userStore?.user?.logOut])
  
  



  // TETSING BLOCK FOR MODALS
  // useEffect(() => {
  //   setTimeout(() => {
  //     setModalStore({
  //       type: ModalTypes.OpenModal,
  //       payload: {
  //         value: 'SWIPING'
  //       }
  //     })
  //   }, 1000)

  //   setTimeout(() => {
  //     setAlertStore({
  //       type: AlertTypes.SetAlert,
  //       payload: {
  //         value: {
  //           type: 'confirmation',
  //           // customStyles: { marginTop: '300px' },
  //           title: t('alerts.delete_account.title') as any,
  //           message: t('alerts.delete_account.message') as any,
  //           input: {
  //             label: t('alerts.delete_account.input.label'),
  //             name: 'password',
  //             value: '',
  //             placeholder: t('alerts.delete_account.input.placeholder'),
  //             type: 'password',
  //             description: '',
  //             icon: 'hide',
  //           },
  //           footer: {
  //             buttons: [
  //               {
  //                 label: 'sadasda',
  //                 onClick: () => {
  //                   setAlertStore({
  //                     type: AlertTypes.ResetAlert,
  //                     payload: null as any,
  //                   });
  //                 },
  //                 type: 'cancel',
  //               },
  //               {
  //                 label: 'asdasdasdasdsa',
  //                 onClick: () => console.log('asdasdsadasd'),
  //                 type: 'negative',
  //               },
  //             ],
  //           },
  //         },
  //       },
  //     });
  //   }, 3000)

  // }, [])

  return (
    <>
            <AlertProvider>
      
        <FiltersProvider>
          {Resolvers?.map((Resolver: any) => <Resolver key={uuidv4()} />)}
        </FiltersProvider>

        {MetaResolvers?.map((MetaResolver: any) => <MetaResolver key={uuidv4()} />)}
        <IonApp className={`Web__APP ${isBlurred ? 'APP_Blurred' : ''}`}>

          <ThemeProvider>



              <RightDrawerProvider>
                <LeftDrawerProvider>


                  {
                    <IonContent scrollY={false} className='IonScroll'>
                      <ModalProvider>

                            <BrowserRouter>
                              {/* <MobileModal /> */}
                              <LeftDrawer />
                              <Routes>
                                <Route path="*" element={<Modules MODULES={MODULES} COLORS={COLORS} />} />

                              </Routes>
                              <RightDrawer />
                            </BrowserRouter>


                      </ModalProvider>

                    </IonContent>
                  }


                </LeftDrawerProvider>
              </RightDrawerProvider>
          </ThemeProvider>

        </IonApp>
        </AlertProvider>
        
    </>
  );
}

export default Sentry.withProfiler(App);
