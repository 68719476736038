import { IconWrapper } from "@flexo/general";
import { useTranslation } from "react-i18next";

import styles from './../alert.module.scss';


export function AlertNotAllowed() {

  const { t } = useTranslation();
  const alertContent = t('alerts.not_allowed', { returnObjects: true }) as any;

  return (
    <div id="alert-unexpected-error" className={ styles.Alert__Unexpected__Error}>

      <div className={styles.Alert__Title} >
        <IconWrapper iconName="alert" />
        {alertContent.title}
      </div>
      <div className={styles.Alert__Content} >
        {alertContent.message} { }
      </div>

    </div>
  )
}