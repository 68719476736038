import React, { useState, useContext, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { DataContext, UserContext, CalendarContext, AlertContext, AlertTypes, LoadingContext, LoadingTypes, ThemeContext, CalendarTypes, ModulesTypes, DataStoreTypes, globalSetDataStore, FiltersContext, FiltersStoreTypes, MetaDataApiContext, UserTypes } from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';
import { MetaDataApiTypes } from "@flexo/providers";;

const ENV = (import.meta as any).env;

let round = 0;

const firebaseService = new FirebaseService();


export const CommunitiesResolver = React.memo((props: any) => {


  const { t } = useTranslation()
  const { setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { setAlertStore } = useContext(AlertContext);
  const { setUserStore } = useContext(UserContext);

  async function init (){
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      
      setUserStore({
        type: UserTypes.SetUserLogOut,
        payload: {
          value: true,
        }
      })

      return;
    }

    const [communities] = await Promise.all([
      httpService.get({
        url: `${ENV.VITE_APP_BASE_STAGING_URL}/hive/api/catalog/v1_2/organization/${ENV.VITE_APP_ORG_ID}/communities`,
        idToken,
      })
      .then( ( res: any ) => {
        if ( res.status === 200 ){
          return res.data.communities;
        } else {
          console.warn( {
            status: res.status,
            message: res.statusText
          });
          
          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR'
              },
            }
          })

          return null
        }
      } )
      .catch((err: any) => {
        console.warn(err);

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR'
            },
          }
        })

        return null;
      } ),
    ])

    setMetaDataApiStore({
      type: MetaDataApiTypes.SetCommunities,
      payload: {
        value: communities,
      },
    });

  }


  useEffect(() => {

    if (round === 0) {
      init();
      round++;
    }
  }, []);
  
  return <></>

});

export function navigateToCommunities(setModulesStore: any) {
  setModulesStore({ type: ModulesTypes.ActivateCommunitiesModule, })
}