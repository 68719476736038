import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { IconWrapper } from '@flexo/general';
import styles from './textfield.module.scss';
import { TextfieldProps } from '@flexo/atoms';

// Use forwardRef to properly pass ref from parent component to input
export const Textfield = forwardRef<HTMLInputElement, TextfieldProps>(
  (
    {
      placeholder,
      label,
      onChange,
      description,
      descriptionFault = null,
      icon,
      iconPosition,
      onIconClick,
      onDescriptionClick,
      variant = 'primary',
      extraDescription,
      containedLabel = false,
      additionalLabel = null,
      additionalLabelStyles = null,
      border = false,
      error = false,
      errorDescription,
      underline = null,
      ...props
    }: TextfieldProps,
    ref // forward ref from parent
  ) => {
    const { Textfield__Label, Textfield__Input, Textfield__Description } = styles;
    const [isActiveOrHasValue, setActiveOrHasValue] = useState(false);

    useEffect(() => {
      if (props.value) {
        setActiveOrHasValue(true);
      } else {
        setActiveOrHasValue(false);
      }
    }, [props.value]);

    return (
      <div className={styles.Textfield}>
        {label && !containedLabel && (
          <span className={`${Textfield__Label} heading4 `}>{label}</span>
        )}

        {additionalLabel !== null && (
          <div className={styles.Textfield__AdditionalLabel}>
            <div className={additionalLabelStyles}>{additionalLabel}</div>
          </div>
        )}

        <div
          className={`${styles.Textfield__InputContainer} heading5 ${
            styles[variant]
          } ${border && styles.border} ${
            containedLabel && styles.containedLabel
          } ${error && styles.error}`}
        >
          {label && containedLabel && (
            <div
              className={`${Textfield__Label} heading4 ${
                isActiveOrHasValue ? styles.activeLabel : ''
              } ${containedLabel && styles.Textfield__Label__Contained}`}
            >
              {label}
            </div>
          )}
          {/* Make sure the ref is passed here */}
          <input
            key={props.name}
            ref={ref} // Forward the ref properly
            onFocus={() => setActiveOrHasValue(true)}
            onBlur={() => {
              if (!props.value) {
                setActiveOrHasValue(false);
              }
            }}
            className={`${Textfield__Input} ${
              containedLabel && styles.Textfield__Input__Contained
            }`}
            placeholder={!containedLabel ? placeholder : ''}
            onChange={onChange}
            autoCapitalize="none"
            {...props}
          />

          {icon && (
            <div
              className={styles.Textfield__Icon}
              onClick={() => onIconClick && onIconClick(props.name)}
            >
              <IconWrapper iconName={icon} />
            </div>
          )}

          {underline && (
            <div
              className={
                underline === 'visible'
                  ? styles.Textfield__Underline__Active
                  : styles.Textfield__Underline
              }
            />
          )}
        </div>

        <div className={description && 'pt-1'}>
          {descriptionFault && (
            <span className={styles.Textfield__Description__Fault}>
              {descriptionFault}
            </span>
          )}
          {description && (
            <span
              className={`${Textfield__Description} paragraph mt-1 ${
                styles[`Textfield__Description__${variant}`]
              } ${
                onDescriptionClick && styles.Textfield__Description__Clickable
              } ${descriptionFault && styles.Textfield__Description__Error}`}
              onClick={onDescriptionClick}
            >
              {description}
            </span>
          )}
          {errorDescription && (
            <span
              className={`${styles.Textfield__Error__Description} detail mt-1 ${
                styles[`Textfield__Description__${variant}`]
              }`}
              onClick={onDescriptionClick}
            >
              {errorDescription}
            </span>
          )}

          {extraDescription && (
            <span className={`detail ${styles.Textfield__ExtraDescription}`}>
              {extraDescription}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default Textfield;
