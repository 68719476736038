
import styles from './breadcrumbs-dropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { DataContext, ModulesContext } from '@flexo/providers';
import { useContext } from 'react';
import { CommunitiesDropDown, MembersDropDown } from './breadcrumbs-dropdown.utils';

/* eslint-disable-next-line */

function getBreadCrumbComponent(props: any) {

  switch (props?.context) {
    case 'community':
      return <CommunitiesDropDown />;
      break;
    case 'member':
      return <MembersDropDown />;
      break;
    case 'site-energy':
    case 'site-economics':
      return <MembersDropDown />;
      break;
    case 'communities':
      return <></>;
      break;
    default:
      return <></>;
    break;
  }

}

export function BreadCrumbsDropDown() {

  const { t } = useTranslation();
  const { modulesStore } = useContext(ModulesContext);

  return (
    <div className={styles.SearchWrapper}>
      <div className={styles.Search}>
        {getBreadCrumbComponent({
          context: modulesStore?.modules?.find((module: any) => module.visible === true)?.name,
        })}
         
      </div>
      </div>
  );
}

export default BreadCrumbsDropDown;
