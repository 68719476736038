
import React, { useContext, useEffect, useState, } from 'react';

import styles from './header-web.module.scss';
import { DataContext, MetaDataApiContext, ModulesContext } from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import {  ProfileButton, Search, navigateToCommunity, navigateToSite } from '@flexo/atoms';

import './header-web.module.scss';
import { Autocomplete, Select } from '@mui/material';
import { Calendar } from '@flexo/molecules';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation__Light,
  Navigation__BreadCrumb,
  Header__Web__Center
} = styles;

function SiteNavigation(props: any) {
  
  const { metaDataApiStore, setMetaDataApiStore } = useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null, breadCrumbs = null } = props;
  const { t } = useTranslation()

  const communities = (metaDataApiStore?.entities as any)?.communities || [];

  function getSiteCommunity() {
    const SITE_ID = metaDataApiStore?.selectedEntityId || 'missing_id';
    const foundCommunity = communities?.find((community: any) => (community?.site || []).find((site: any) => (site?.id || site?.siteId || site?.communityId) === SITE_ID));
    
    if ( !foundCommunity) {
      return communities?.[0];
    } else {
      return foundCommunity;
    }

  }

  function getSiteCommunityName() {
    const foundCommunity = getSiteCommunity();
    return (foundCommunity?.communityName) || communities?.[0]?.communityName || 'Community name not found';
  }

  function getSiteName(): any {
    let site: any = metaDataApiStore?.selectedEntity || null;

    if (!site) {
      try {
        site = (metaDataApiStore?.entities as any)?.sites?.find((s: any) => s?.siteId === metaDataApiStore?.selectedEntityId);
      } catch (e) {
        console.warn( 'no site found' )
      }
    }

    return site?.pod || site?.name || site?.siteName || 'Site name not found';
  }

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>
        
        <div className={Navigation__Light}>
          <strong className={ `${styles.clickable}`} onClick={() => navigateToCommunity( getSiteCommunity(), setMetaDataApiStore, setModulesStore)} >{ getSiteCommunityName()}</strong>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <strong>{getSiteName()}</strong>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <Select
            className='site-navigation-select'
            id="site-navigation-select"
            MenuProps={{
              className: 'site-navigation-select-menu'
            }}
            // open={true}
            defaultValue={metaDataApiStore?.entityType || null}
            
          >
            <option disabled={ metaDataApiStore?.entityType === 'site-energy' } onClick={() => {
              navigateToSite( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'energy' )
            }} value="site-energy">Energy</option>
            <option disabled={ metaDataApiStore?.entityType === 'site-economics' } onClick={() => {
              navigateToSite( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'economics' )
            }} value="site-economics">Economics</option>
          </Select>
          {/* <span>{ (metaDataApiStore?.selectedEntity as any)?.type || 'undefined type' }</span> */}

          
      {/* {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          } */}
      
    </div>


      </div>

      <div className={Header__Web__Center}>
          <Calendar />
        </div>

      <div className={Header__Web__Right}>
        {
          download && <>
            <IconWrapper iconName={'chevron-encircled'} />
          </>
        }
        {
          searchBar &&
          <Search />
        }
        {
       profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(SiteNavigation);
