import {
  DataContext,
  ModulesContext,
  FiltersStoreTypes,
  globalSetFiltersStore,
  MetaDataApiContext,
  Member,
  Site,
  Community,
  MetaDataApiTypes,
} from '@flexo/providers';
import {
  Autocomplete,
  Box,
  List,
  ListItem,
  Popper,
  TextField,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import styles from './search.module.scss';
import { IconWrapper } from '@flexo/general';
import { navigateToCommunity } from '../resolvers/community-resolver';
import { navigateToMember } from '../resolvers/member-resolver';
import { navigateToSite } from '../meta-resolvers/site-resolver';

let isInputOpen = false;

function highlightedText({
  text,
  searchText,
}: {
  text: string;
  searchText: string;
}) {
  if (!searchText) {
    return <>{text}</>;
  }

  const lowercasedText = text.toLowerCase();
  const lowercasedSearchText = searchText.toLowerCase();

  const startIndex = lowercasedText.indexOf(lowercasedSearchText);

  if (startIndex === -1) {
    return <span>{text}</span>;
  }

  const endIndex = startIndex + searchText.length;

  return (
    <>
      {text.slice(0, startIndex)}
      <label>{text.slice(startIndex, endIndex)}</label>
      {text.slice(endIndex)}
    </>
  );
}

function getMemberName(member: any) {
  return `${member.memberFirstName} ${member.memberLastName}`;
}

function filterData(structuredData: any | null, searchText: string) {
  if (!structuredData) {
    return {
      filteredStructuredData: null,
    };
  }

  const lowercasedSearchText = searchText.toLowerCase();

  const filteredCommunities = structuredData.communities
    .map((community) => {
      const filteredMembers = community.members
        .map((member) => {
          const filteredSites = member.sites.filter(
            (site) =>
              site.siteName.toLowerCase().includes(lowercasedSearchText) ||
              site.pod.toLowerCase().includes(lowercasedSearchText) ||
              site.siteAddress.toLowerCase().includes(lowercasedSearchText) ||
              site.memberId.toLowerCase().includes(lowercasedSearchText)
          );

          if (
            getMemberName(member)
              .toLowerCase()
              .includes(lowercasedSearchText) ||
            member.memberExternalId
              .toLowerCase()
              .includes(lowercasedSearchText) ||
            member.memberId.toLowerCase().includes(lowercasedSearchText) ||
            member.email.toLowerCase().includes(lowercasedSearchText) ||
            filteredSites.length > 0
          ) {
            return { ...member, sites: filteredSites };
          }

          return null;
        })
        .filter((member) => member !== null);

      if (
        community.communityName.toLowerCase().includes(lowercasedSearchText) ||
        community.communityCode.toLowerCase().includes(lowercasedSearchText) ||
        community.communityExternalId
          .toLowerCase()
          .includes(lowercasedSearchText) ||
        filteredMembers.length > 0
      ) {
        return { ...community, members: filteredMembers };
      }

      return null;
    })
    .filter((community) => community !== null);

  const filteredMembers = structuredData.unassignedMembers.filter(
    (member) =>
      getMemberName(member).toLowerCase().includes(lowercasedSearchText) ||
      member.meberExternalId.toLowerCase().includes(lowercasedSearchText) ||
      member.memberId.toLowerCase().includes(lowercasedSearchText) ||
      member.email.toLowerCase().includes(lowercasedSearchText)
  );

  const filteredSites = structuredData.unassignedSites.filter(
    (site) =>
      site.communityId.toLowerCase().includes(lowercasedSearchText) ||
      site.siteType[0].flexoSiteType
        .toLowerCase()
        .includes(lowercasedSearchText) ||
      site.siteName.toLowerCase().includes(lowercasedSearchText) ||
      site.pod.toLowerCase().includes(lowercasedSearchText) ||
      site.siteAddress.toLowerCase().includes(lowercasedSearchText) ||
      site.memberId.toLowerCase().includes(lowercasedSearchText)
  );

  const filteredEVs = structuredData.unassignedEVs.filter((ev) =>
    ev.evId.toLowerCase().includes(lowercasedSearchText)
  );

  const filteredWallboxes = structuredData.unassignedWallboxes.filter(
    (wallbox) => wallbox.wallboxId.toLowerCase().includes(lowercasedSearchText)
  );

  return {
    filteredStructuredData: {
      communities: filteredCommunities,
      unassignedMembers: filteredMembers,
      unassignedSites: filteredSites,
      unassignedEVs: filteredEVs,
      unassignedWallboxes: filteredWallboxes,
    },
  };
}

function filterCommunities(
  structuredData: any | null,
  communitiesData: any[],
  searchText: string
) {
  const lowercasedSearchText = searchText.toLowerCase();

  const _filteredStructeredData = filterData(structuredData, searchText);

  const _filteredCommunities = communitiesData.filter((community: any) =>
    (_filteredStructeredData as any)?.filteredStructuredData?.communities.some(
      (_filterCom: any) => _filterCom.communityId === community.communityId
    )
  );

  return {
    ..._filteredStructeredData,
    filteredCommunities: _filteredCommunities as any,
  };
}

function filterMembers(
  structuredData: any | null,
  membersData: any,
  searchText: string
) {
  const lowercasedSearchText = searchText.toLowerCase();

  const filterTimeMembers = (members: any) => {
    if (!members || members.length === 0 || Object.keys(members).length === 0) {
      return [];
    }

    return members
      .map((member) => {
        const filteredSites = member.sites.filter(
          (site) =>
            site.siteName.toLowerCase().includes(lowercasedSearchText) ||
            site.siteId.toLowerCase().includes(lowercasedSearchText) ||
            site.siteExternalId.toLowerCase().includes(lowercasedSearchText) ||
            site.pod.toLowerCase().includes(lowercasedSearchText) ||
            site.address.toLowerCase().includes(lowercasedSearchText)
        );

        if (
          getMemberName(member).toLowerCase().includes(lowercasedSearchText) ||
          filteredSites.length > 0
        ) {
          return { ...member };
        }

        return null;
      })
      .filter((member) => member !== null) as Member[];
  };

  return {
    ...filterData(structuredData, searchText),
    filteredMembers: {
      current: filterTimeMembers(membersData?.current || {}),
      future: filterTimeMembers(membersData?.future || {}),
      previous: filterTimeMembers(membersData?.previous || {}),
    },
  };
}

function filterSites(
  structuredData: any | null,
  sitesData: any[],
  searchText: string
) {
  const lowercasedSearchText = searchText.toLowerCase();

  const filteredSites = (sitesData || []).filter(
    (site) =>
      (site?.siteName || site?.name || '')
        .toLowerCase()
        .includes(lowercasedSearchText) ||
      site?.pod.toLowerCase().includes(lowercasedSearchText) ||
      site.externalId.toLowerCase().includes(lowercasedSearchText) ||
      (site?.id || site?.flexoSiteId || '')
        .toLowerCase()
        .includes(lowercasedSearchText)
  );

  return {
    ...filterData(structuredData, searchText),
    filteredSites: filteredSites as any,
  };
}

function filterSitesMobile(sitesData: any[], searchText: string) {
  const lowercasedSearchText = searchText.toLowerCase();

  const filteredSites = (sitesData || []).filter((site) =>
    (site?.siteName || site?.name || '')
      .toLowerCase()
      .includes(lowercasedSearchText)
  );

  return {
    filteredSites: filteredSites as any,
  };
}

const debounce = (fn, delay = 100) => {
  const handler = setTimeout(() => {
    fn();
  }, delay);

  return () => {
    clearTimeout(handler);
  };
};

function flattenStructuredData(structuredData: any): any[] {
  if (!structuredData) {
    return [];
  }
  const flatArray: any[] = [];

  flatArray.push(...structuredData.communities);
  flatArray.push(...structuredData.unassignedMembers);
  flatArray.push(...structuredData.unassignedSites);
  flatArray.push(...structuredData.unassignedEVs);
  flatArray.push(...structuredData.unassignedWallboxes);

  return flatArray;
}

export function SiteListItem(option: any, props: any) {
  const { setMetaDataApiStore, metaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);

  const isActive =
    option?.option?.siteId ===
    (metaDataApiStore.selectedEntity as Site)?.siteId;
  const firstItem = option?.firstItem === true;

  return (
    <ListItem
      key={`${uuidv4()}`}
      className={`${styles.Autocomplete__List__Item} ${
        firstItem && styles.Autocomplete__List__Item__First
      }`}
    >
      <span
        key={`${uuidv4()}`}
        style={{
          paddingLeft: option?.padding || 24,
          cursor: 'pointer',
          color: isActive ? 'var(--hive-primary-500)' : 'inherit',
        }}
        onClick={() =>
          navigateToSite(
            option?.option,
            setMetaDataApiStore,
            setModulesStore,
            metaDataApiStore.entityType?.includes('energy')
              ? 'energy'
              : 'economics'
          )
        }
      >
        {highlightedText({
          text: option?.option?.pod || option?.option?.siteName,
          searchText: option.searchText,
        })}
      </span>
    </ListItem>
  );
}

export function MobileSites(option: any, props: any) {
  //pasar como props setSearchTest y la ref para vaciarlo y seleccionar el sitio correcto
  const { setMetaDataApiStore, metaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);

  const isActive =
    option?.option?.siteId ===
    (metaDataApiStore.selectedEntity as Site)?.siteId;
  const firstItem = option?.firstItem === true;

  return (
    <ListItem
      key={`${uuidv4()}`}
      className={`${styles.Autocomplete__List__Item} ${
        firstItem && styles.Autocomplete__List__Item__First
      }`}
    >
      <span
        key={`${uuidv4()}`}
        style={{
          paddingLeft: option?.padding || 24,
          cursor: 'pointer',
          color: isActive ? 'var(--hive-primary-500)' : 'inherit',
        }}
        onClick={() => {
          navigateToSite(option?.option, setMetaDataApiStore, setModulesStore)
        }}
      >
        {highlightedText({
          text: option?.option?.siteName,
          searchText: option.searchText,
        })}
      </span>
    </ListItem>
  );
}

export function MemberListItem(option: any, props: any) {
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);

  const isActive =
    option?.option?.memberId ===
      (metaDataApiStore.selectedEntity as Member)?.memberId ||
    (metaDataApiStore?.entities as any)?.sites?.find(
      (s: any) => s.siteId === metaDataApiStore?.selectedEntityId
    )?.memberId === option?.option?.memberId ||
    false;

  const firstItem = option?.firstItem === true;

  return (
    <ListItem
      key={`${uuidv4()}`}
      className={`${styles.Autocomplete__List__Item} ${
        firstItem && styles.Autocomplete__List__Item__First
      }`}
    >
      <span
        key={`${uuidv4()}`}
        style={{
          paddingLeft: option?.padding || 24,
          cursor: 'pointer',
          color: isActive ? 'var(--hive-primary-500)' : 'inherit',
        }}
        onClick={() =>
          navigateToMember(option?.option, setMetaDataApiStore, setModulesStore)
        }
      >
        {highlightedText({
          text: `${option?.option.memberFirstName} ${option?.option.memberLastName}`,
          searchText: option.searchText,
        })}
      </span>

      {option?.option?.sites && (
        <List key={`${uuidv4()}`} className={`${styles.Autocomplete__List}`}>
          {option?.option?.sites.map((site: any) => (
            <SiteListItem
              key={uuidv4()}
              option={site}
              props={props}
              padding={(option?.padding || 24) + 16}
              searchText={option.searchText}
            />
          ))}
        </List>
      )}
    </ListItem>
  );
}

export function CommunitiesListItem(option: any, props: any) {
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);
  const { setModulesStore } = useContext(ModulesContext);

  const isActive =
    option?.option?.communityId ===
      (metaDataApiStore.selectedEntity as Community)?.communityId ||
    (metaDataApiStore?.entities as any)?.sites?.find(
      (s: any) => s.siteId === metaDataApiStore?.selectedEntityId
    )?.communityId === option?.option?.communityId ||
    false;

  const firstItem = option?.firstItem === true;

  return (
    <ListItem
      key={`${uuidv4()}`}
      className={`${styles.Autocomplete__List__Item} ${
        firstItem && styles.Autocomplete__List__Item__First
      } `}
      {...props}
      value={option.value}
    >
      <span
        key={`${uuidv4()}`}
        style={{
          paddingLeft: option?.padding || 24,
          cursor: 'pointer',
          color: isActive ? 'var(--hive-primary-500)' : 'inherit',
        }}
        onClick={() =>
          navigateToCommunity(
            option?.option?.communityId,
            setMetaDataApiStore,
            setModulesStore
          )
        }
      >
        {highlightedText({
          text: option?.option?.communityName,
          searchText: option.searchText,
        })}
      </span>
      {option?.option?.members && (
        <List key={`${uuidv4()}`} className={`${styles.Autocomplete__List}`}>
          {option?.option?.members.map((member: any) => (
            <MemberListItem
              key={uuidv4()}
              option={member}
              props={props}
              padding={(option?.padding || 24) + 16}
              searchText={option.searchText}
            />
          ))}
        </List>
      )}
    </ListItem>
  );
}

export function AutoCompleteComponent(props: any) {
  const { t } = useTranslation();
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const [showInput, setShowInput] = useState(isInputOpen);
  const magnifierEl = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = useState('');

  function isChildOf(event) {
    const parentDiv = document.getElementsByClassName(
      'MuiAutocomplete-popper'
    )[0];
    let targetElement = event.target; // L'elemento che è stato cliccato

    // Itera attraverso i genitori dell'elemento target fino a raggiungere il body
    while (targetElement !== document.body) {
      if (targetElement === parentDiv) {
        return true; // Il target è figlio del parentDiv
      }
      targetElement = targetElement.parentNode; // Vai al genitore dell'elemento target
    }

    return false; // Il target non è figlio del parentDiv
  }

  //   useOnClickOutside(magnifierEl, (event) => {
  //     if(
  //       (event?.target as any)?.attributes?.[2]?.textContent === 'autocomplete' ||
  //       (event?.target as any).className.includes('MuiAutocomplete') ||
  //       ( isChildOf( event ) )
  //     ) {
  //       return;
  //     } else {
  //     setShowInput(false);
  //   }
  //   }
  // )

  function toggleInput() {
    if (showInput === true) {
      inputRef.current?.blur();
      setSearchText('');

      globalSetFiltersStore({
        type: FiltersStoreTypes.ResetAllFilters,
      });
    } else {
      inputRef.current?.focus();
    }

    setSearchText('');
    setShowInput(!showInput);
    isInputOpen = !showInput;
  }

  return (
    <div
      className={`${styles.Search__Container} ${
        showInput
          ? styles.Search__Container__Active
          : styles.Search__Container__InActive
      }`}
    >
      <div
        className={`${styles.Autocomplete__Wrapper} ${
          showInput && styles.Autocomplete__Wrapper__Active
        }`}
      >
        <Autocomplete
          disablePortal
          id="autocomplete"
          // open={true}
          options={
            flattenStructuredData(metaDataApiStore?.sortedEntities || null) ||
            []
          }
          getOptionLabel={(option: any) =>
            option?.communityName ||
            option?.name ||
            option?.siteName ||
            option?.evId ||
            option?.wallboxId
          }
          filterOptions={(options, { inputValue }) => {
            setSearchText(inputValue);
            return props.handleFilterOptions(options, { inputValue });
          }}
          clearOnBlur={false}
          sx={{ width: 260 }}
          className={`${styles.Search}`}
          PopperComponent={({ children, ...props }) => (
            <Popper
              {...props}
              style={{ width: 315, borderRadius: 8, paddingTop: 16 }}
              placement="bottom-start"
            >
              {children}
            </Popper>
          )}
          noOptionsText={
            <span className={`${styles.Autocomplete__List__Item}`}>
              {t('widgets.search.no_results')}
            </span>
          }
          renderOption={(props, option, { selected }) => {
            let optionType = '';
            if (option?.memberId) {
              optionType = 'member';
            }
            if (option?.communityName) {
              optionType = 'community';
            }
            if (option?.siteName) {
              optionType = 'site';
            }

            switch (optionType) {
              case 'community':
                return (
                  <CommunitiesListItem
                    option={option}
                    props={props}
                    firstItem={true}
                    searchText={searchText}
                  />
                );
                break;
              case 'member':
                return (
                  <MemberListItem
                    option={option}
                    props={props}
                    firstItem={true}
                    searchText={searchText}
                  />
                );
                break;
              case 'site':
                return (
                  <SiteListItem
                    option={option}
                    props={props}
                    firstItem={true}
                    searchText={searchText}
                  />
                );
                break;
              default:
                return (
                  <ListItem {...props} value={option.value}>
                    <span> {option?.name}</span>
                  </ListItem>
                );
            }
          }}
          onChange={(_e, v) =>
            props.handleFilterOptions(props.data, {
              inputValue: (_e.target as any)?.outerText || '',
            })
          }
          renderInput={(params) => (
            <TextField
              inputRef={inputRef}
              {...params}
              id="autocomplete-search-input"
              className={`${styles.Search__Input__Text}`}
              label={t('widgets.search.common_placeholder') as string}
              placeholder={t('widgets.search.common_placeholder') as string}
            ></TextField>
          )}
        />
      </div>
      <div
        className={`${styles.Search__Icon__Wrapper}`}
        onClick={() => {
          toggleInput();
        }}
        ref={magnifierEl}
      >
        <IconWrapper
          iconName={showInput === false ? 'search' : 'cross'}
          className={`${styles.Search__Magnifier}`}
        />
      </div>
    </div>
  );
}
export function AutoCompleteComponentMobile(props: any) {
  const { t } = useTranslation();
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const magnifierEl = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = useState('');




  return (
    <Autocomplete
      id="autocomplete"
      // open={true}
      options={(metaDataApiStore?.entities as any).sites || []}
      getOptionLabel={(option: any) => option?.siteName + 'bla bla bla'}
      filterOptions={(options, { inputValue }) => {
        setSearchText(inputValue);
        return props.handleFilterOptions(options, { inputValue });
      }}
      clearOnBlur={false}
      sx={{ width: 260 }}
      className={`${styles.SearchMobile}`}
      PopperComponent={({ children, ...props }) => (
        <Popper
          {...props}
          style={{ width: 315, borderRadius: 8, paddingTop: 16 }}
          placement="bottom-start"
        >
          {children}
        </Popper>
      )}
      noOptionsText={
        <span className={`${styles.Autocomplete__List__Item}`}>
          {t('widgets.search.no_results')}
        </span>
      }
      renderOption={(props, option, { selected }) => {
        let optionType = '';
        
        if (option?.memberId) {
          optionType = 'member';
        }
        if (option?.communityName) {
          optionType = 'community';
        }
        if (option?.siteName) {
          optionType = 'sites';
        }

        switch (optionType) {
          case 'sites':
            return <MobileSites option={option} searchText={searchText} />;
            break;
          case 'communitites':
            return (
              <MemberListItem
                option={option}
                props={props}
                firstItem={true}
                searchText={searchText}
              />
            );
            break;
          case 'site':
            return (
              <SiteListItem
                option={option}
                props={props}
                firstItem={true}
                searchText={searchText}
              />
            );
            break;
          default:
            return (
              <ListItem {...props} value={option.value}>
                <span> {option?.name}</span>
              </ListItem>
            );
        }
        return <div>{JSON.stringify(option)}</div>;
      }}
      onChange={(_e, v) =>
        props.handleFilterOptions(props.data, {
          inputValue: (_e.target as any)?.outerText || '',
        })
      }
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          id="autocomplete-search-input"
          className={`${styles.Search__Input__Text}`}
          label={
            (metaDataApiStore?.entities as any)?.sites?.[0]?.siteName ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {(metaDataApiStore.selectedEntity as any)?.siteName}
                <IconWrapper iconName="chevron-down" />
              </Box>
            ) : (
              ''
            )
          }
          placeholder={
            (metaDataApiStore.selectedEntity as any)?.siteName ||
            (metaDataApiStore?.entities as any).sites?.[0]?.siteName ||
            ''
          }
          InputProps={{
            ...params.InputProps,
            sx: {
              input: { color: 'green' }, // This styles the input text color to green

              '::placeholder': {
                color: 'green', // Style placeholder text color
                opacity: 1, // Ensure the placeholder has the same opacity as the input text
              },
            },
          }}
          InputLabelProps={{
            sx: {
              color: 'green', // Style label text color
            },
          }}
        ></TextField>
      )}
    />
  );
}

export function CommunitiesAutocomplete(props: any) {
  const { t } = useTranslation();
  const { dataStore } = useContext(DataContext);
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const [loaded, setLoaded] = useState(false);

  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredStructuredData, filteredCommunities } = filterCommunities(
      metaDataApiStore?.sortedEntities,
      (metaDataApiStore as any)?.communities,
      inputValue
    ) as any;

    if (inputValue !== '') {
      debounce(() =>
        globalSetFiltersStore({
          type: FiltersStoreTypes.SetFilteredCommunities,
          payload: {
            value: filteredCommunities,
          },
        })
      );
    } else {
      if (
        (metaDataApiStore as any)?.communities?.length >=
        (filteredCommunities?.length || 0)
      ) {
        debounce(() =>
          globalSetFiltersStore({
            type: FiltersStoreTypes.SetFilteredCommunities,
            payload: {
              value: metaDataApiStore?.communities,
            },
          })
        );
      }
    }

    return flattenStructuredData(filteredStructuredData);
  };

  useEffect(() => {
    return () => {
      if (loaded) {
        globalSetFiltersStore({
          type: FiltersStoreTypes.ResetFilter,
          payload: {
            value: 'filteredCommunities',
          },
        });
      } else {
        setLoaded(true);
      }
    };
  }, []);

  return (
    <AutoCompleteComponent
      handleFilterOptions={handleFilterOptions}
      data={metaDataApiStore?.communities || []}
    />
  );
}

export function SitesAutocomplete(props: any) {
  const { t } = useTranslation();
  const { dataStore } = useContext(DataContext);
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const [loaded, setLoaded] = useState(false);

  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredSites } = filterSitesMobile(
      (metaDataApiStore as any)?.entities.sites,
      inputValue
    ) as any;
    return filteredSites;
  };

  

  // useEffect(() => {
  //   return () => {
  //     if (loaded) {
  //       globalSetFiltersStore({
  //         type: FiltersStoreTypes.ResetFilter,
  //         payload: {
  //           value: 'filteredSites',
  //         },
  //       });
  //     } else {
  //       setLoaded(true);
  //     }
  //   };
  // }, []);

  return (
    <AutoCompleteComponentMobile
      handleFilterOptions={handleFilterOptions}
      data={metaDataApiStore?.entities || []}
    />
  );
}

export function CommunityAutocomplete(props: any) {
  const { dataStore } = useContext(DataContext);
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const [loaded, setLoaded] = useState(false);

  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredStructuredData, filteredMembers } = filterMembers(
      metaDataApiStore?.sortedEntities,
      metaDataApiStore.members,
      inputValue
    ) as any;

    if (inputValue !== '') {
      debounce(() =>
        globalSetFiltersStore({
          type: FiltersStoreTypes.SetFilteredMembers,
          payload: {
            value: filteredMembers,
          },
        })
      );
    } else {
      if (
        Object.values(filterMembers).reduce(
          (acc, val) => acc + val.length,
          0
        ) === 0
      ) {
        debounce(() =>
          globalSetFiltersStore({
            type: FiltersStoreTypes.SetFilteredMembers,
            payload: {
              value: metaDataApiStore.members,
            },
          })
        );
      }
    }

    return flattenStructuredData(filteredStructuredData);
  };

  useEffect(() => {
    return () => {
      if (loaded) {
        globalSetFiltersStore({
          type: FiltersStoreTypes.ResetFilter,
          payload: {
            value: 'filteredMembers',
          },
        });
      } else {
        setLoaded(true);
      }
    };
  }, []);

  return (
    <AutoCompleteComponent
      handleFilterOptions={handleFilterOptions}
      data={(metaDataApiStore?.entities as any)?.members || {}}
    />
  );
}

export function MemberAutocomplete(props: any) {
  const { t } = useTranslation();
  const { dataStore } = useContext(DataContext);
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const [loaded, setLoaded] = useState(false);

  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredStructuredData, filteredMembers } = filterMembers(
      metaDataApiStore?.sortedEntities,
      (metaDataApiStore?.entities as any)?.members,
      inputValue
    ) as any;

    // if (inputValue !== '') {

    //   debounce(() =>
    //     globalSetFiltersStore({
    //       type: FiltersStoreTypes.SetFilteredMembers,
    //       payload: {
    //         value: filteredMembers
    //       }
    //     })
    //   )

    // } else {

    //   if ( Object.values( filterMembers ).reduce((acc, val) => acc + val.length, 0) === 0) {

    //     debounce(() =>
    //       globalSetFiltersStore({
    //         type: FiltersStoreTypes.SetFilteredMembers,
    //         payload: {
    //           value: dataStore?.data?.members
    //         }
    //       })
    //     )

    //   }
    // }

    return flattenStructuredData(filteredStructuredData);
  };

  useEffect(() => {
    return () => {
      if (loaded) {
        globalSetFiltersStore({
          type: FiltersStoreTypes.ResetFilter,
          payload: {
            value: 'filteredPayments',
          },
        });
      } else {
        setLoaded(true);
      }
    };
  }, []);

  return (
    <AutoCompleteComponent
      handleFilterOptions={handleFilterOptions}
      data={dataStore?.data?.members || {}}
    />
  );
}

export function SiteAutocomplete(props: any) {
  const { t } = useTranslation();
  const { dataStore } = useContext(DataContext);
  const { metaDataApiStore } = useContext(MetaDataApiContext);
  const [loaded, setLoaded] = useState(false);

  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredStructuredData, filteredSites } = filterSites(
      metaDataApiStore?.sortedEntities,
      (metaDataApiStore?.entities as any)?.site,
      inputValue
    ) as any;

    // if (inputValue !== '') {

    //   debounce(() =>
    //     globalSetFiltersStore({
    //       type: FiltersStoreTypes.SetFilteredMembers,
    //       payload: {
    //         value: filteredMembers
    //       }
    //     })
    //   )

    // } else {

    //   if ( Object.values( filterMembers ).reduce((acc, val) => acc + val.length, 0) === 0) {

    //     debounce(() =>
    //       globalSetFiltersStore({
    //         type: FiltersStoreTypes.SetFilteredMembers,
    //         payload: {
    //           value: dataStore?.data?.members
    //         }
    //       })
    //     )

    //   }
    // }

    return flattenStructuredData(filteredStructuredData);
  };

  useEffect(() => {
    return () => {
      if (loaded) {
        globalSetFiltersStore({
          type: FiltersStoreTypes.ResetFilter,
          payload: {
            value: 'filteredPayments',
          },
        });
      } else {
        setLoaded(true);
      }
    };
  }, []);

  return (
    <AutoCompleteComponent
      handleFilterOptions={handleFilterOptions}
      data={dataStore?.data?.members || {}}
    />
  );
}
