import { useContext, useEffect, useState } from 'react';
import { IconWrapper } from '@flexo/general';
import { FooterProps } from '@flexo/molecules';
import { useLocation, useNavigate } from 'react-router-dom'; // useNavigate instead of useHistory
import styles from './footer.module.scss';
import { useTranslation } from 'react-i18next';
import {
  LoadingContext,
  ThemeContext,
  ModulesContext,
  ModulesTypes,
  MetaDataApiContext,
} from '@flexo/providers';
import BarLoader from 'react-spinners/BarLoader';
import {
  navigateToCommunity,
  navigateToHousehold,
  navigateToSite,
} from '@flexo/atoms';

export function Footer({
  sections,
  showLoadingBar,
  shadow,
  roundCorners,
}: FooterProps) {
  const { Footer__Label, Footer__Label__Active } = styles;
  const location = useLocation();
  const navigate = useNavigate(); // Use navigate instead of useHistory
  const { t } = useTranslation();
  const { loadingStore } = useContext(LoadingContext);
  const { ThemeStore } = useContext(ThemeContext);
  const { setModulesStore } = useContext(ModulesContext);
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);

  // State to track the active path
  const [activePath, setActivePath] = useState(location.pathname);

  // Log current pathname for debugging

  // Update active path whenever location changes
  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  // Function to check if a section is active based on the current path
  const isActiveSection = (section: string) => {
    const sectionPathMap: { [key: string]: string } = {
      community: '/community',
      household: '/household',
    };

    const isActive = activePath.startsWith(sectionPathMap[section]);
    return isActive;
  };

  // Function to handle section change
  const handleSectionChange = (section: string) => {
    // Trigger a route change (this will cause useLocation to update)
    navigate(`/${section}`); // Use navigate instead of history.push

    // Dispatch the section change to the store

    if (section === 'community') {
      navigateToCommunity(
        metaDataApiStore.selectedEntity?.communityId ||
          (metaDataApiStore.entities as any).communities[0].communityId,
        setMetaDataApiStore,
        setModulesStore
      );
    } else {
      navigateToSite(
        (metaDataApiStore.entities as any).sites[0].siteId,
        setMetaDataApiStore,
        setModulesStore
      );
    }
  };

  return (
    <div
      className={`${styles.Footer} sfPro ${shadow ? styles.shadow : ''} 
      ${roundCorners ? styles.roundCorners : ''}`}
    >
      {/* Display loading bar if necessary */}
      {loadingStore.loading.visible && showLoadingBar && (
        <BarLoader
          color={`${ThemeStore.colors.primary}80`}
          style={{ left: 0, width: '100%', position: 'absolute', top: 0 }}
        />
      )}

      {/* Map through sections and display icons */}
      {sections?.map((section) => {
        const isActive = isActiveSection(section); // Determine if this section is active
        return (
          <div
            key={section}
            className={`${Footer__Label} ${
              isActive ? Footer__Label__Active : ''
            }`}
            onClick={() => handleSectionChange(section)}
          >
            <IconWrapper iconName={`${section}${isActive ? '_active' : ''}`} />
            <div className={isActive ? 'color-primary-500' : ''}>
              {t(`widgets.footer.sections.${section}`)}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Footer;
