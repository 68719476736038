import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { v4 as uuidv4 } from 'uuid';

import {
  ModulesContext,
  CalendarContext,
  ModulesTypes,
  checkQueryParams,
  DataStoreTypes,
  UserContext,
  DataContext,
  ThemeTypes,
  ThemeContext,
  AlertContext,
  AlertTypes,
  MetaDataApiContext,
  doesModuleNeedAuth,
  setParams,
} from '@flexo/providers';

import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';

import styles from './modules.module.scss';
import { Factory } from '../factory';
import { FirebaseService, IconWrapper } from '@flexo/general';
import { Alert } from '@flexo/atoms';
import { MetaDataApiTypes } from '@flexo/providers';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const ENV = (import.meta as any).env;
const firebaseService = new FirebaseService();

export const Modules = (props: any) => {
  const { t } = useTranslation();
  const { userStore } = useContext(UserContext);
  const { setModulesStore, modulesStore } = useContext(ModulesContext);
  const { setThemeStore, ThemeStore } = useContext(ThemeContext);
  const { setCalendarStore } = useContext(CalendarContext);
  const { setAlertStore, alertStore } = useContext(AlertContext);
  const { user } = userStore;
  const [activeModule, setActiveModule] = useState<any | null>(null);
  const [stateModules, setStateModules] = useState<any[]>([]);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);

  const [isBlurred, setIsBlurred] = useState(false);

  function updateBreakPoints(breakpoint: string) {
    if (breakpoint !== currentBreakpoint) {
      setCurrentBreakpoint(breakpoint);
      setThemeStore({
        type: ThemeTypes.SetBreakPoint,
        payload: {
          value: breakpoint,
        },
      });

      if (ENV.VITE_APP_DISABLE_SCREEN_SIZE_ALERT === 'true') {
        return;
      }

      if (['xl', 'lg', 'md'].includes(breakpoint)) {
        if (!isBlurred) return;

        setIsBlurred(false);

        if (alertStore.alert.type === 'ALERT_SCREEN_SIZE') {
          setAlertStore({
            type: AlertTypes.ResetAlert,
            payload: null as any,
          });
        }
      } else {
        if (isBlurred) return;

        setIsBlurred(true);

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_SCREEN_SIZE',
            },
          },
        });
      }
    }
  }

  function getFilteredLayouts(data: any) {
    const widgetIds = data.widgets.map((widget) => widget.id);
    return {
      ...data.layouts,
      [`${currentBreakpoint || 'lg'}`]: (
        data?.layouts?.[currentBreakpoint || 'lg'] ||
        data?.layouts?.lg ||
        []
      ).filter((layout) => widgetIds.includes(layout.i)),
    };
  }

  function getFilteredWidgets(data: any) {
    const layoutIds = (
      data?.layouts?.[currentBreakpoint || 'lg'] ||
      data?.layouts?.lg ||
      []
    ).map((layout) => layout.i);
    return data.widgets.filter((widget) => layoutIds.includes(widget.id));
  }

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      // TODO: Z - There is basically almost never a good reason to have a timeout, it spawns a new thread and is not reliable

      // This is just to test the pull to refresh functionality, if it closes after 2 seconds or not.
      // Not the actual code XD

      // Any calls to load data go here
      event.detail.complete();
    }, 2000);
  }

  useEffect(() => {
    const savedModules = stateModules.find((module: any) =>
      module.active && module.visible ? module.name : null
    );
    const updatedModules = modulesStore?.modules.find((module: any) =>
      module.active && module.visible ? module.name : null
    );

    if (savedModules !== updatedModules) {
      setActiveModule(updatedModules);
      setStateModules(modulesStore.modules);
    }
  }, [modulesStore?.modules]);

  useEffect(() => {
    window.addEventListener('reload', (event) => {
      // alert('reload event ' + JSON.stringify(event))
    });

    window.onpopstate = (ev: any) => {
      ev.preventDefault();

      checkQueryParams(
        setModulesStore,
        setMetaDataApiStore,
        setCalendarStore,
        null,
        props.MODULES
      );
    };
  }, []);

  useEffect(() => {
    setShowSplashScreen(true);

    setTimeout(async () => {
      const tokenID = await firebaseService.getIdToken().catch((err: any) => {
        console.warn(err);
        return null;
      });

      if (!tokenID && doesModuleNeedAuth(props.MODULES) === true) {
        setModulesStore({ type: ModulesTypes.ActivateLoginModule });
      } else {
        checkQueryParams(
          setModulesStore,
          setMetaDataApiStore,
          setCalendarStore,
          () => {
            setModulesStore({
              type: ModulesTypes.ActivateModuleByName,
              payload: {
                value: ENV.VITE_APP_FIRST_PAGE,
              },
            });

            setMetaDataApiStore({
              type: MetaDataApiTypes.SetEntityType,
              payload: {
                value: ENV.VITE_APP_FIRST_PAGE,
              },
            });
          },
          props.MODULES
        );
      }

      setShowSplashScreen(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!modulesStore?.modules || !metaDataApiStore.selectedEntityId) return;

    let siteType =
      (metaDataApiStore.selectedEntity as any)?.siteType?.[0]?.siteType ||
      (metaDataApiStore.selectedEntity as any)?.type ||
      null;

    if (
      siteType === null &&
      (metaDataApiStore.entityType?.includes('site') ||
        metaDataApiStore.entityType === 'household') &&
      (metaDataApiStore?.entities as any)?.sites?.length > 0
    ) {
      siteType =
        (metaDataApiStore?.entities as any)?.sites?.[0]?.siteType?.[0]
          ?.siteType ||
        (metaDataApiStore?.entities as any)?.sites?.[0]?.type ||
        null;
    }

    // siteType = 'prosumage';
    siteType = siteType || 'default';

    setModulesStore({
      type: ModulesTypes.LoadModules,
      payload: {
        value: props.MODULES,
        props: {
          userGroup: user?.tokenContent?.user_role || 'administrator',
          siteType: siteType,
        },
      },
    });
  }, [
    metaDataApiStore.entities,
    metaDataApiStore.selectedEntityId,
    metaDataApiStore?.entityType,
  ]);

  useEffect(() => {
    if (Object.keys(ThemeStore?.colors)?.length > 0) return;
    setThemeStore({
      type: ThemeTypes.SetColors,
      payload: {
        value: props.COLORS,
      },
    });
  }, []);

  return (
    <>
      {showSplashScreen ? (
        <div
          className="flex flex-align-center flex-justify-center flex-column"
          style={{ height: '100vh', width: '100vw' }}
        >
          <IconWrapper
            iconName={ENV.VITE_APP_NAME === 'admin' ? 'logo_wide' : 'logo'}
          />
        </div>
      ) : activeModule ? (
        <IonContent className={`IonScroll ${isBlurred ? 'APP_Blurred' : ''}`}>
          <div key={activeModule.name}>
            {activeModule?.pullToRefresh === true && (
              <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                <IonRefresherContent className={styles.Refresher__Content}>
                  {/* <IonSpinner /> */}
                </IonRefresherContent>
              </IonRefresher>
            )}

            <ResponsiveReactGridLayout
              className={`${styles.Modules} ${activeModule.name}`}
              key={`grit_item_${activeModule.name}`}
              cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
              breakpoints={{
                lg: 1200,
                md: 996,
                sm: 768,
                xs: 480,
                xxs: 0,
              }}
              margin={[0, 0]}
              rowHeight={8}
              autoSize={true}
              layouts={getFilteredLayouts(activeModule)}
              onBreakpointChange={(breakpoint, cols) =>
                updateBreakPoints(breakpoint)
              }
            >
              {getFilteredWidgets(activeModule).length > 0 &&
                getFilteredWidgets(activeModule).map(
                  (widget: any, widget_i: number) => {
                    const additionalStyles =
                      (
                        getFilteredLayouts(activeModule)?.[currentBreakpoint] ||
                        []
                      ).find((item: any) => item?.i === widget?.id)?.style ||
                      {};

                    return Factory(
                      `${widget.id}_${uuidv4()}`,
                      'lg',
                      widget,
                      additionalStyles
                    );
                  }
                )}
            </ResponsiveReactGridLayout>
          </div>
        </IonContent>
      ) : (
        <></>
      )}

      {alertStore.alert.visible && <Alert />}
    </>
  );
};

export default React.memo(Modules);
