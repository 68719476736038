/* eslint-disable no-case-declarations */
import { Dispatch } from 'react';
import { ModulesStore, StorageActions } from './modules-provider.interfaces';

import { moduleFilter } from './utils';
import { setParams, setUrl } from '../utils';
import { set } from 'date-fns';

export const reducer = (state: ModulesStore, action: StorageActions) => {
  switch (action.type) {
    case 'ACTIVATE_MODULE_BY_NAME':
      console.log('ACTIVATE_MODULE_BY_NAME');

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === action.payload.value,
        })),
      };
    case 'SET_ACTIVE_MODULE':
      console.log('SET_ACTIVE_MODULE', action.payload.value);

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === action.payload.value.name,
        })),
      };

    case 'NAVIGATE_MODULE_LOGIN':
      console.log('NAVIGATE_MODULE_LOGIN');
      setUrl('/login');

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'login',
        })),
      };
      break;
    case 'NAVIGATE_MODULE_FORGOT_PASSWORD':
      console.log('NAVIGATE_MODULE_FORGOT_PASSWORD');
      setUrl('/forgot-password');

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'forgot-password',
        })),
      };
      break;
    case 'NAVIGATE_MODULE_RESET_PASSWORD':
      console.log('NAVIGATE_MODULE_RESET_PASSWORD');

      setUrl(
        `/password-reset?email=${action.payload.value.email}&recovery_code=${action.payload.value.recovery_code}`
      );

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'password-reset',
        })),
      };
    case 'NAVIGATE_MODULE_REGISTER':
      console.log('NAVIGATE_MODULE_REGISTER');
      setUrl('/register');

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'register',
        })),
      };
    case 'NAVIGATE_MODULE_ACCOUNT_CREATION':
      console.log('NAVIGATE_MODULE_ACCOUNT_CREATION');
      setUrl('accoumt-creation');

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'accountCreation',
        })),
      };
    case 'NAVIGATE_MODULE_HOUSEHOLD':
      console.log('NAVIGATE_MODULE_HOUSEHOLD');
      setUrl('household'); // TODO ADD ID of selectedSite

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'household',
        })),
      };

    case 'ACTIVATE_MODULE_HOUSEHOLD_ID':
      setUrl(`household/${action.payload.value}`);
      return {
        ...state, 
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'household',
        })),
      }
    case 'NAVIGATE_MODULE_COMMUNITIES':
      console.log('NAVIGATE_MODULE_COMMUNITIES');
      setUrl('communities');

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'communities',
        })),
      };
    case 'NAVIGATE_MODULE_MEMBERS':
      console.log('NAVIGATE_MODULE_MEMBERS');
      setUrl('members');

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'members',
        })),
      };
    case 'NAVIGATE_MODULE_COMMUNITY':
      console.log('NAVIGATE_MODULE_COMMUNITY');
      setUrl(`community/${action.payload.value}`);

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'community',
        })),
      };
    case 'NAVIGATE_MODULE_MEMBER':
      console.log('NAVIGATE_MODULE_MEMBER');
      setUrl(`member/${action.payload.value}`);

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === 'member',
        })),
      };
    case 'NAVIGATE_TO_SITE_CONTEXT':
      console.log('NAVIGATE_TO_SITE_CONTEXT');

      setUrl(`${action.payload.value.entity}/${action.payload.value.id}`);

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === action.payload.value.entity,
        })),
      };
    case 'NAVIGATE_TO_COMMUNITY_CONTEXT':
      console.log('NAVIGATE_TO_COMMUNITY_CONTEXT');

      setUrl(`${action.payload.value.entity}/${action.payload.value.id}`);

      return {
        startState: state.startState,
        oldState: state.modules,
        breakpoint: state.breakpoint,
        modules: state.modules.map((module) => ({
          ...module,
          visible: module.name === action.payload.value.entity,
        })),
      };
    case 'SET_BREAK_POINT':
      console.log('SET_BREAK_POINT');

      return {
        ...state,
        breakpoint: action.payload.value,
      };

    case 'RESET_MODULES':
      console.log('RESET_MODULES');
      return {
        startState: state.startState,
        modules: state.startState,
        breakpoint: state.breakpoint,
      };
    case 'LOAD_MODULES':
      console.log('LOAD_MODULES');

      let filteredModules = moduleFilter(
        action.payload.value,
        action?.payload?.props?.userGroup || null,
        action?.payload?.props?.siteType || null,
        action?.payload?.props?.userType || null
      );

      const previousModule = state.modules.find(
        (module) => module.active === true && module.visible === true
      );

      if (previousModule) {
        filteredModules = filteredModules.map((module) => ({
          ...module,
          visible: module.name === previousModule.name,
        }));
      }

      return {
        startState: filteredModules,
        oldState: filteredModules,
        modules: filteredModules,
        breakpoint: state.breakpoint,
      };
    default:
      return state;
  }
};
