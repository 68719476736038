


import { useTranslation } from "react-i18next"
import styles from './../alert.module.scss'
import { IconWrapper } from "@flexo/general";
import { useContext } from "react";
import { AlertContext, AlertTypes, ModulesContext, ModulesTypes } from "@flexo/providers";

export default function AlertWrongParams() {

  const { setAlertStore } = useContext( AlertContext)
  const { setModulesStore } = useContext( ModulesContext)
  const { t } = useTranslation();
  const alertContent = t('alerts.wrong_params', { returnObjects: true }) as any;

  function navigateToPAsswordReset() {

    setAlertStore({ type: AlertTypes.ResetAlert })

    setModulesStore({
      type: ModulesTypes.ActivateForgotPasswordModule,
    })
  }

  return (
    <div id="alert-wrong-params" className={ styles.Alert__Wrong__Params}>
      <div className={styles.Alert__Title} >
        <IconWrapper iconName="alert" />
        { alertContent.title }
      </div>
      <div className={styles.Alert__Content} >
        {alertContent.description} 
      </div>
    </div>
  )
}