import React, { useContext, useMemo, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Cell,
  CartesianGrid,
} from 'recharts';
import { formatDate } from '../helpers';
import { CalendarContext, ThemeContext } from '@flexo/providers';
import { useTranslation } from 'react-i18next';

import styles from './stackbar-graph.module.scss';

const ENV = (import.meta as any).env;

export const Stackbar = ({ _data, keys, combinedKeys, colors, title }) => {
  const [activeIndex, setActiveIndex] = useState(null); // Active bar index on hover
  const [selectedIndex, setSelectedIndex] = useState(null); // Selected bar index on click
  const [selectedData, setSelectedData] = useState(null); // Store selected bar data
  const { calendarStore } = useContext(CalendarContext);
  const { ThemeStore } = useContext(ThemeContext);
  const [visibleKeys, setVisibleKeys] = useState(
    keys.reduce((acc, key) => ({ ...acc, [key]: true }), {})
  );
  const { t } = useTranslation();

  const preprocessedData = useMemo(() => {
    return _data?.map((entry) => ({
      ...entry,
      ...(
        !combinedKeys
          ? {}
          : {
              [combinedKeys[0]]: entry[combinedKeys[0]]
                ? entry[combinedKeys[0]] - (entry[combinedKeys[1]] || 0)
                : 0,
            }
      ),
      
    }));
  }, [_data, combinedKeys]);

  const filteredKeys = useMemo(() => {
    return keys.filter((key) => !(combinedKeys || []).includes(key));
  }, [keys, combinedKeys]);

  // Handle mouse hover to set active bar
  const handleMouseMove = (index) => {
    if (selectedIndex === null) {
      setActiveIndex(index); // Only set active index if no bar is selected
    }
  };

  const handleMouseLeave = () => {
    setActiveIndex(null); // Reset active index on mouse leave
  };

  // Click handler to set the selected bar
  const handleBarClick = (index) => {
    if (index !== selectedIndex) {
      setSelectedIndex(index); // Only update if a new bar is selected
      setSelectedData(preprocessedData[index]); // Update the selected bar data
    } else {
      // If clicking the same bar again, reset the selection
      setSelectedIndex(null);
      setSelectedData(null);
    }
  };

  // Toggle visibility of bars when buttons are clicked
  const toggleKeyVisibility = (key) => {
    const visibleKeysCount = Object.values(visibleKeys).filter(Boolean).length;

    if (visibleKeys[key] && visibleKeysCount === 1) {
      // Prevent hiding the last visible key
      return;
    }

    setVisibleKeys((prev) => ({
      ...prev,
      [key]: !prev[key], // Toggle visibility
    }));
  };

  const getStyling = (key, index) => {
    const activeValue = selectedData ? selectedData[key] : null;

    if (visibleKeys[key] === false) {
      return {
        color: ThemeStore.colors.greyscale,
        borderColor: ThemeStore.colors.greyscale + 50,
      };
    } else {
      return {
        borderBottom: `2px solid ${colors[index]}`,
        borderColor: colors[index],
        color: selectedIndex !== null ? colors[index] : 'black',
      };
    }
  };

  const getFillColor = (index, defaultColor) => {
    // If a bar is selected, keep that bar colored and others grey
    if (selectedIndex !== null) {
      return index === selectedIndex ? defaultColor : '#ccc';
    }
    // If no bar is selected and one is hovered, color that bar and grey others
    if (activeIndex !== null) {
      return index === activeIndex ? defaultColor : '#ccc';
    }
    // If no selection or hover, return the original color
    return defaultColor;
  };

  return (
    <div className={(ENV.VITE_APP_NAME === 'admin' ? styles.StackBar__Wrapper : {}) as any }>
      <div className={styles.Stackbar__Header}>
        {title && (
          <div className={styles.GraphHeader__TitleContainer}>
            <div className={`${styles.GraphHeader__Title} heading4`}>
              {t(title)}{' '}
            </div>
            {/* <div className="heading4L">
              {formatDate(
                selectedData ? (selectedData as any)?.date : null,
                calendarStore.currentlySelectedDateType
              )}
            </div> */}
          </div>
        )}
        <div>
          {keys.map((key, index) => {
            const activeValue = selectedData ? selectedData[key] : null;
            return (
              <button
                key={key}
                className={`detail ${styles.Stackbar__Key}`}
                style={getStyling(key, index)}
                onClick={() => toggleKeyVisibility(key)} // Toggle visibility on button click
              >
                {`${t(key)} `}
                {activeValue !== undefined && activeValue !== null
                  ? `| ${(activeValue as any).toFixed(2)} kWh`
                  : ''}
              </button>
            );
          })}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={preprocessedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barCategoryGap="3%"
          onMouseLeave={handleMouseLeave} // Reset hover effect on mouse leave
          
        >
          <CartesianGrid horizontal={false} vertical={false} />
          <XAxis
            dataKey="date"
            tickFormatter={(tick) =>
              formatDate(tick, calendarStore.currentlySelectedDateType)
            }
            axisLine={false}
            tickLine={false}
          />
          <YAxis
          axisLine={false}
          tickLine={false}
          />

          {keys
            .filter((key) => visibleKeys[key]) // Only render visible keys
            .map((key, index) => (
              <Bar
                key={key}
                dataKey={key}
                fill={colors[index]}
                stackId="a"
                radius={[5, 5, 5, 5]}
                onClick={(_, i) => handleBarClick(i)} // Click handler for bar
                onMouseMove={(_, i) => handleMouseMove(i)} // Handle mouse hover
              >
                {(preprocessedData || []).map((entry, i) => (
                  <Cell
                    key={`cell-${i}`}
                    fill={getFillColor(i, colors[index])} // Get dynamic fill color
                  />
                ))}
              </Bar>
            ))}

          {(combinedKeys || [])
            .filter((key) => visibleKeys[key]) // Only render visible combinedKeys
            .reverse()
            .map((key, index) => (
              <Bar
                key={key}
                dataKey={key}
                fill={colors[index + 1]}
                stackId="a"
                radius={[5, 5, 0, 0]}
                onClick={(_, i) => handleBarClick(i)} // Click handler for combined keys
                onMouseMove={(_, i) => handleMouseMove(i)} // Handle mouse hover
              >
                {(preprocessedData || []).map((entry, i) => (
                  <Cell
                    key={`cell-${i}`}
                    fill={getFillColor(i, colors[index + 1])} // Get dynamic fill color
                  />
                ))}
              </Bar>
            ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
    
  );
};

export default Stackbar;
