import { KpiDataProvider } from '@flexo/providers';
import { Dispatch } from 'react';

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface IKpiDataProvider {
  site: {
    '1day': IKpiDataDetail[];
  };
  community: { '1day': IKpiDataDetail[] };
  custom: { '1day': IKpiDataDetail[] };
  communityRanking?: any;
}
export interface ISelectedEntity {
  id: string;
  entitytype: 'site' | 'community' | 'wallbox' | 'ev'; //add other types of entities if needed
  entity: {
    status: string;
    siteExternalId: string;
    pod: string;
    siteAddress: string;
    communityId: string;
    joinDate: string; //"2024-07-14"
    leaveDate: string; //"2024-07-14"
    siteLatitude: number;
    siteLongitude: number;
    siteName: string;
    siteType: [
      {
        status: string;
        flexoSiteType: 'producer' | 'consumer' | 'prosumer' | 'prosumage'; //add other types  if needed
        startDate: string; //"2024-07-14"
        endDate: string; //"2024-07-14"
      }
    ];
  };
}

export interface IKpiDataDetail {
  id: string;
  kpi: IKpiData[];
}
export interface IKpiDataOverview {
  id: string;
  kpi: IKpiData[];
}
export interface IKpiData {
  id: string;
  name: string;
  value: number;
  unit: string;
  type: 'energy' | 'benefit' | 'rate' | 'weight' | 'quantity'; //add other types  if needed
  timeStamp?: string; //"2024-07-14T00:00:00Z"
}

export type kpiApiStore = IKpiDataProvider;

export enum KpiDataTypes {
  SetKpiDataSite = 'SET_KPI_DATA_SITE',
  SetKpiDataCommunity = 'SET_KPI_DATA_COMMUNITY',
  SetKpiDataCommunityRanking = 'SET_KPI_DATA_COMMUNITY_RANKING',
  SetKpiDataCustom = 'SET_KPI_DATA_CUSTOM',
  ResetKpiApiStore = 'RESET_KPI_API_STORE',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [KpiDataTypes.SetKpiDataSite]: {
    value: any;
  };
  [KpiDataTypes.SetKpiDataCommunity]: {
    value: any;
  };

  [KpiDataTypes.SetKpiDataCustom]: {
    value: any;
  };

  [KpiDataTypes.SetKpiDataCommunityRanking]: {
    value: any;
  };

  [KpiDataTypes.ResetKpiApiStore]: { value: null };
};

export interface ContextValues {
  kpiApiStore: kpiApiStore;
  setKpiApiStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
