


import { useTranslation } from "react-i18next"
import styles from './../alert.module.scss'
import { IconWrapper } from "@flexo/general";

export default function AlertWrongRequestData() {

  const { t } = useTranslation();
  const alertContent = t('alerts.wrong_request_data', { returnObjects: true }) as any;

  return (
    <div id="alert-wrong-request-data" className={ styles.Alert__Wrong__Request__Data}>
      <div className={styles.Alert__Title} >
        <IconWrapper iconName="alert" />
        { alertContent.title }
      </div>
      <div className={styles.Alert__Content} >
        {alertContent.description} 
      </div>
    </div>
  )
}