import { useContext, useEffect } from 'react';

import {
  ModulesTypes,
  DataStoreTypes,
  globalSetDataStore,
  MetaDataApiContext,
  ModulesContext,
  AlertTypes,
  AlertContext,
  UserContext,
  UserTypes,
} from '@flexo/providers';

import { FirebaseService, httpService } from '@flexo/general';
import { Community, EntitiesData, Member, MetaDataApiTypes, StructuredData } from "@flexo/providers";;

const ENV = (import.meta as any).env;


// TODO: check why structuredData is not ok
export function structureData(data: EntitiesData): any {
  const communityMap = new Map<string, Community|any>();
  const memberMap = new Map<string, Member|any>();
  const assignedMemberIds = new Set<string>();
  const assignedSiteIds = new Set<string>();
  const assignedEvIds = new Set<string>();
  const assignedWallboxIds = new Set<string>();

  // Create community map
  data.communities.forEach((community) => {
    communityMap.set(community.communityId, { ...community, members: [] as any });
  });

  // Create member map
  data.members.forEach((member) => {
    memberMap.set(member.memberId, { ...member, sites: [], evs: [], wallboxes: [] });

    // Associate members with communities directly if they have a communityId
    if (member.communityId && communityMap.has(member.communityId)) {
      const community = communityMap.get(member.communityId);
      community.members.push(memberMap.get(member.memberId));
      assignedMemberIds.add(member.memberId);
    }
  });

  // Associate sites with members and track assigned site IDs
  data.sites.forEach((site: any) => {
    if (memberMap.has(site.memberId)) {
      const member = memberMap.get(site.memberId);
      member.sites.push(site);
      assignedSiteIds.add(site.siteExternalId);

      // Associate members with communities via sites if not already associated
      if (!member.communityId && communityMap.has(site.communityId)) {
        const community = communityMap.get(site.communityId);
        if (!community.members.some((m) => m.memberId === member.memberId)) {
          community.members.push(member);
          assignedMemberIds.add(member.memberId);
        }
      }
    }
  });

  // Associate EVs with members and track assigned EV IDs
  data.ev.forEach((ev) => {
    if (memberMap.has(ev.memberId)) {
      const member = memberMap.get(ev.memberId);
      member.evs.push(ev);
      assignedEvIds.add(ev.evId);

      // Associate members with communities via EVs if not already associated
      if (!member.communityId && communityMap.has(ev.communityId)) {
        const community = communityMap.get(ev.communityId);
        if (!community.members.some((m) => m.memberId === member.memberId)) {
          community.members.push(member);
          assignedMemberIds.add(member.memberId);
        }
      }
    }
  });

  // Associate Wallboxes with members and track assigned Wallbox IDs
  data.wallbox.forEach((wallbox) => {
    if (memberMap.has(wallbox.memberId)) {
      const member = memberMap.get(wallbox.memberId);
      member.wallboxes.push(wallbox);
      assignedWallboxIds.add(wallbox.wallboxId);

      // Associate members with communities via Wallboxes if not already associated
      if (!member.communityId && communityMap.has(wallbox.communityId)) {
        const community = communityMap.get(wallbox.communityId);
        if (!community.members.some((m) => m.memberId === member.memberId)) {
          community.members.push(member);
          assignedMemberIds.add(member.memberId);
        }
      }
    }
  });

  // Structure the data
  const structuredData: StructuredData = {
    communities: [],
    unassignedMembers: [],
    unassignedSites: [],
    unassignedEVs: [],
    unassignedWallboxes: [],
  };

  communityMap.forEach((community) => {
    structuredData.communities.push(community);
  });

  memberMap.forEach((member) => {
    if (!assignedMemberIds.has(member.memberId)) {
      structuredData.unassignedMembers.push(member);
    }
  });

  data.sites.forEach((site) => {
    if (!assignedSiteIds.has(site.siteExternalId)) {
      structuredData.unassignedSites.push(site);
    }
  });

  data.ev.forEach((ev) => {
    if (!assignedEvIds.has(ev.evId)) {
      structuredData.unassignedEVs.push(ev);
    }
  });

  data.wallbox.forEach((wallbox) => {
    if (!assignedWallboxIds.has(wallbox.wallboxId)) {
      structuredData.unassignedWallboxes.push(wallbox);
    }
  });

  return structuredData;
}

const firebaseService = new FirebaseService();

let round = 0;

export const EntitiesResolver = () => {
  const { setMetaDataApiStore, metaDataApiStore } = useContext(MetaDataApiContext);
  const { setAlertStore } = useContext(AlertContext);
  const { setUserStore } = useContext(UserContext);

  async function init() {
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      setUserStore({
        type: UserTypes.SetUserLogOut,
        payload: {
          value: true,
        }
      })

      return;
    }

    const [entities] = await Promise.all([
      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_STAGING_URL}/hive/api/catalog/v1_2/entities`,
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            console.warn({
              status: res.status,
              message: res.statusText,
            });

            setAlertStore({
              type: AlertTypes.SetAlert,
              payload: {
                value: {
                  type: 'ALERT_SESSION_EXPIRED'
                },
              },
            })

            return null;
          }
        })
        .catch((err: any) => {
          console.warn(err);
          console.warn('zeno');
          
          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR'
              },
            }
          })

          console.warn('zeno 2');

          return null;
        }),
    ]);
    setMetaDataApiStore({
      type: MetaDataApiTypes.SetEntities,
      payload: {
        value: entities,
      },
    });

    setMetaDataApiStore({
      type: MetaDataApiTypes.SetSortedEntities,
      payload: {
        value: structureData(entities),
      },
    });

  }


  useEffect(() => {
    if (round === 0) {
      init();
      round++;
    }
  }, [ metaDataApiStore?.selectedEntityId ]);

  return <></>;
};

export function navigateToHousehold(setDataStore: any, setModulesStore: any) {
  round = 0;

  globalSetDataStore({
    type: DataStoreTypes.setCommonNavigationId,
    payload: {
      value: {
        module: 'household',
      },
    },
  });

  //TODO add ID of selectedSite in the common store, replicate community resolver

  setModulesStore({ type: ModulesTypes.ActivateHouseholdModule });
}
