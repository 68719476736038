import { ReactSVG } from 'react-svg';
import { IconWrapperProps } from '../libs.interfaces';



export function IconWrapper(props: IconWrapperProps) {

  const { iconName, className = '', style = {} } = props;

  return <ReactSVG src={`icons/${iconName}.svg`} className={`${className}`} style={ style } />;
}

export default IconWrapper;
