import React, { Dispatch } from 'react';

export interface IModule {
  name: string;
  footer?: boolean | null;
  header?: boolean | null;
  pullToRefresh?: boolean | null;
  layouts: any;
  widgets: any;
  apis?: any;
  metaResolvers?: any;
  workers?: Array<string>;
  datePicker?: boolean | null;
  visible: boolean;
  active: boolean;
}

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export interface ModulesProvider {
  startState: IModule[];
  modules: IModule[];
  breakpoint: string;
}

export type ModulesStore = ModulesProvider;

export enum ModulesTypes {
  SetActiveModule = 'SET_ACTIVE_MODULE',
  ResetModules = 'RESET_MODULES',
  LoadModules = 'LOAD_MODULES',
  ActivateLoginModule = 'NAVIGATE_MODULE_LOGIN',
  ActivateForgotPasswordModule = 'NAVIGATE_MODULE_FORGOT_PASSWORD',
  ActivateResetPasswordModule = 'NAVIGATE_MODULE_RESET_PASSWORD',
  ActivateRegisterModules = 'NAVIGATE_MODULE_REGISTER',
  ActivateAccountCreationModules = 'NAVIGATE_MODULE_ACCOUNT_CREATION',
  ActivateHouseholdModule = 'NAVIGATE_MODULE_HOUSEHOLD',
  ActivateCommunitiesModule = 'NAVIGATE_MODULE_COMMUNITIES',
  ActivateCommunityModule = 'NAVIGATE_MODULE_COMMUNITY',
  ActivateMemberModule = 'NAVIGATE_MODULE_MEMBER',
  NavigateToSiteContext = 'NAVIGATE_TO_SITE_CONTEXT',
  NavigateToCommunityContext = 'NAVIGATE_TO_COMMUNITY_CONTEXT',
  ActivateMembersModule = 'NAVIGATE_MODULE_MEMBERS',
  SetBreakPoint = 'SET_BREAK_POINT',
  ActivateModuleByName = 'ACTIVATE_MODULE_BY_NAME',
  ActivateHouseholdIdModule = 'ACTIVATE_MODULE_HOUSEHOLD_ID',
}

export interface Action {
  type: string;
  payload?: StoragePayload;
}

export type StoragePayload = {
  [ModulesTypes.SetActiveModule]: {
    value: IModule;
  };
  [ModulesTypes.ResetModules];
  [ModulesTypes.LoadModules]: {
    value: any;
    props?: any;
  };
  [ModulesTypes.ActivateLoginModule];
  [ModulesTypes.ActivateForgotPasswordModule];
  [ModulesTypes.ActivateRegisterModules];
  [ModulesTypes.ActivateAccountCreationModules];
  [ModulesTypes.ActivateHouseholdModule];
  [ModulesTypes.ActivateHouseholdIdModule]: {
    value: any;
  };

  [ModulesTypes.ActivateCommunitiesModule];
  [ModulesTypes.ActivateCommunityModule]: {
    value: any;
  };
  [ModulesTypes.ActivateMemberModule]: {
    value: any;
  };
  [ModulesTypes.SetBreakPoint]: {
    value: string;
  };
  [ModulesTypes.ActivateModuleByName]: {
    value: string;
  };
  [ModulesTypes.ActivateMembersModule];
  [ModulesTypes.NavigateToSiteContext]: {
    value: {
      entity: string;
      id: string;
    };
  };
  [ModulesTypes.NavigateToCommunityContext]: {
    value: {
      entity: string;
      id: string;
    };
  };
  [ModulesTypes.ActivateResetPasswordModule]: {
    value: {
      email: string;
      recovery_code: string;
    };
  };
};

export interface ContextValues {
  modulesStore: ModulesStore;
  setModulesStore: Dispatch<StorageActions>;
}

export interface Props {
  children: React.ReactNode;
}

export type StorageActions =
  ActionMap<StoragePayload>[keyof ActionMap<StoragePayload>];
