import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate } from '../helpers';
import { useContext, useState, useCallback } from 'react';
import { CalendarContext, ThemeContext } from '@flexo/providers';
import styles from './area-chart.module.scss';
import { useTranslation } from 'react-i18next';

const ENV = (import.meta as any).env;

export const NewAreaChart = ({ colors, data, keys, title }) => {
  const { calendarStore } = useContext(CalendarContext);
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);
  const [tooltipData, setTooltipData] = useState(null); // Track active tooltip data
  const { ThemeStore } = useContext(ThemeContext);

  // Track visibility of keys (true = visible, false = hidden)
  const [visibleKeys, setVisibleKeys] = useState(
    keys.reduce((acc, key) => ({ ...acc, [key]: true }), {})
  );

  // Handle hover data without rerenders
  const handleMouseMove = useCallback((e) => {
    if (e && e.activePayload) {
      setTooltipData(e.activePayload); // Update tooltip data
    } else {
      setTooltipData(null); // Clear tooltip data when not hovering
    }
  }, []);

  const handleClick = (e) => {
    if (e && e.activeLabel) {
      setSelected(e.activeLabel); // Set the selected time (e.activeLabel is the clicked date)
    }
  };

  const toggleKeyVisibility = (key) => {
    const visibleKeysCount = Object.values(visibleKeys).filter(Boolean).length;

    // Prevent toggling if there is only one visible key
    if (visibleKeys[key] && visibleKeysCount === 1) {
      return;
    }

    // Toggle visibility
    setVisibleKeys((prev) => ({
      ...prev,
      [key]: !prev[key], // Toggle visibility
    }));
  };

  const getTitleDetail = () => {
    if (!selected) return;
    return formatDate(selected, calendarStore.currentlySelectedDateType);
  };
  const getStyling = (key, index) => {
    if (visibleKeys[key] === false) {
      return {
        color: ThemeStore.colors.greyscale,
        borderColor: ThemeStore.colors.greyscale + 50,
      };
    } else {
      return {
        borderBottom: `2px solid ${colors[index]}`,
        borderColor: colors[index],
      };
    }
  };

  return (
    <div className={ ENV.VITE_APP_NAME === 'admin' ? styles.AreaChartWrapper__Web : styles.AreaChartWrapper__APP } >
      <div className={styles.Areachart__Header}>
        {title && (
          <div className={styles.GraphHeader__TitleContainer}>
            <div className={`${styles.GraphHeader__Title} heading4`}>
              {t(title)}{' '}
            </div>
            {/* <div className="heading4L">{getTitleDetail()}</div> */}
          </div>
        )}
        <div>
          {keys.map((key, index) => {
            // Find the corresponding value from the tooltip payload (if available)
            const activeValue = (tooltipData as any)?.find(
              (item) => item.dataKey === key
            )?.value;
            return (
              <button
                key={key}
                className={`detail ${styles.Areachart__Key}`}
                style={getStyling(key, index)}
                onClick={() => toggleKeyVisibility(key)}
              >
                {`${t(key)} `}{' '}
                {activeValue !== undefined ? '| ' + activeValue : ''}{' '}
              </button>
            );
          })}
        </div>
      </div>
      
      <ResponsiveContainer className={ ENV.VITE_APP_NAME === 'admin' ? styles.AreaChartContaniner__Web : styles.AreaChartContaneiner__APP } width="100%" height={ ENV.VITE_APP_NAME === 'admin' ? 236 : 400}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          onMouseMove={handleMouseMove} // Track mouse movement
          onMouseDown={handleClick} // Handle click event
        >
          <XAxis
            dataKey="date"
            tickFormatter={(tick) =>
              formatDate(tick, calendarStore.currentlySelectedDateType)
            }
            tick={{ className: 'detail' }}
            axisLine={false}
            tickLine={false}
            
          />
          <YAxis
            tick={{ className: 'detail' }}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip content={<></>} />
          {keys?.map(
            (key, index) =>
              visibleKeys[key] && ( // Conditionally render Area based on visibility
                <Area
                  key={index}
                  type="monotone"
                  dataKey={key}
                  stroke={colors[index]}
                  fill={colors[index]}
                  activeDot={{ r: 8 }} // Ensure active dot is displayed
                />
              )
          )}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default NewAreaChart;
