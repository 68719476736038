import { useContext, useEffect } from 'react';

import { Calendar } from '@flexo/molecules';
import styles from './header.module.scss';
import {
  DataContext,
  MetaDataApiContext,
  ModalContext,
  ModalTypes,
  UserContext,
} from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import React from 'react';
import { Dropdown, Search } from '@flexo/atoms';

function Header(props) {
  const {
    Header__Title,
    Header__Actions,
    Header__Actions__Button,
    Header__Title__Text,
    Header__Heading,
    Header__Calendar,
  } = styles;
  const [loading, setLoading] = React.useState(true);

  const { setModalStore } = useContext(ModalContext);
  const { dataStore } = useContext(DataContext);
  const { userStore } = useContext(UserContext);

  const { metaDataApiStore, setMetaDataApiStore } =
    useContext(MetaDataApiContext);

  const onClickProfile = () => {
    setModalStore({
      type: ModalTypes.OpenModal,
      payload: {
        value: {
          type: 'PROFILE',
          visible: true,
          show: true,
          props: { ...props },
          content: 'PROFILE',
        } as any,
      },
    });
  };

  useEffect(() => {
    if (metaDataApiStore?.entities) {
      setLoading(false);
    }
  }, [metaDataApiStore?.entities]);

  return (
    <div
      className={`${styles.Header} ${
        dataStore?.navigation?.module !== 'household'
          ? styles.Header__Community
          : ''
      } 
     `}
    >
      <div className={Header__Heading}>
        {/* {JSON.stringify(metaDataApiStore.selectedEntity)}
        {JSON.stringify(userStore.user)} */}

        <div className={Header__Title}>
          <Search />
        </div>

        <div className={Header__Actions}>
          {/* <button
            className={Header__Actions__Button}
            onClick={onClickNotifications}
          >
            <IconWrapper iconName="notifications" />
          </button> */}
          <button
            className={Header__Actions__Button}
            onClick={() => onClickProfile()}
          >
            <IconWrapper iconName="user_solid" />
          </button>
        </div>
      </div>
      <div className={Header__Calendar}>
        <Calendar />
      </div>
    </div>
  );
}

export default React.memo(Header);
